import React from "react";
import PropTypes from "prop-types";
import Divider from "../../components/divider";
import Modal from "../Modal";
import { parseDateTime, getApiPrefix } from "../../utils";
import axios from "axios";
import "./styles.scss";
import I18n from "../../i18n";
import Loader from "../Loader";

export default class AnswerQuestionBlock extends React.Component {
  constructor(props) {
    super(props);
    this.projectId = props.projectId;

    this.state = {
      answer: props.question.answer === null ? "" : props.question.answer,
      categories: [],
      question: props.question,
      categoryId: "",
      loading: false,
      showPopup: false,
      showPopupCreateCategory: false,
      categoryError: "",
    };
  }

  componentDidMount() {
    this.handleChangeAnswer = this.handleChangeAnswer.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.saveAnswer = this.saveAnswer.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.getCategoryById = this.getCategoryById.bind(this);

    this.config = {};

    if (localStorage.getItem("token")) {
      this.config.headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
    }

    axios
      .get(
        getApiPrefix() + "/projects/" + this.projectId + "?_locale=nl-NL",
        this.config
      )
      .then((response) => {
        this.setState({
          categories: response.data.categories,
        });

        let categoryId = null;
        if (this.state.question.category?.id) {
          categoryId = this.state.question.category?.id;
        } else {
          categoryId = response.data.categories[0]?.id;
        }

        this.setState({ categoryId: categoryId });
      })
      .catch(() => {});
  }

  handleChangeAnswer(event) {
    this.setState({ answer: event.target.value });
  }

  handleChangeCategory(event) {
    this.setState({ categoryId: event.target.value });
  }

  getCategoryById(id) {
    for (let i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i].id === id) {
        return this.state.categories[i];
      }
    }
  }

  publishNumber() {
    if (!this.state.question.category) {
      return (
        <em>
          <I18n t="qna.answerForm.afterPublication" />
        </em>
      );
    }

    let categoryCode = this.state.question.category.categoryCode;
    let questionCode = this.state.question.questionCode;

    if (!categoryCode || !questionCode) {
      return (
        <em>
          <I18n t="qna.answerForm.afterPublication" />
        </em>
      );
    }

    return categoryCode + "." + questionCode;
  }

  createCategory(event) {
    event.preventDefault();

    const { label } = event.target.elements;

    if (label.value.trim() === "") {
      this.setState({
        categoryError: I18n.getTranslation(
          this.props.location,
          "qna.answerForm.noNameError"
        ),
      });

      return;
    }

    axios
      .post(
        getApiPrefix() + "/categories",
        {
          question: this.state.question.id,
          project: this.projectId,
          label: label.value,
        },
        this.config
      )
      .then((response) => {
        this.setState({
          categories: [
            ...this.state.categories,
            {
              label: response.data.label,
              id: response.data.id,
            },
          ],
        });

        this.setState({
          categoryId: response.data.id,
          showPopupCreateCategory: false,
        });
        document.getElementById("categoryField").value = "";
      })
      .catch(() => {});
  }

  saveAnswer(publishAnswer) {
    this.setState({ loading: true });
    let newQuestion = this.state.question;
    newQuestion.answer = this.state.answer;
    newQuestion.category = this.state.categoryId;

    if (publishAnswer) {
      newQuestion.status = "published";
      newQuestion.name = "";
      newQuestion.email = "";
    }

    if (!newQuestion.project?.meta_description) {
      newQuestion.project.meta_description = undefined;
    }

    axios
      .put(
        getApiPrefix() + `/questions/${this.state.question.id}`,
        newQuestion,
        this.config
      )
      .then(() => {
        this.setState({ question: newQuestion });
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
        this.setState({ showPopup: false });
      });
  }

  publishAnswer() {
    if (
      this.state.categoryId === null ||
      this.state.categoryId === undefined ||
      this.state.categoryId === ""
    ) {
      alert(
        I18n.getTranslation(
          this.props.location,
          "qna.answerForm.noCategoryError"
        )
      );

      return;
    }

    this.setState({ showPopup: true });
  }

  deleteQuestion() {
    this.setState({ loading: true });
    let newQuestion = this.state.question;
    newQuestion.answer = this.state.answer;
    newQuestion.category = this.state.categoryId;
    newQuestion.status = "deleted";

    axios
      .put(
        getApiPrefix() + `/questions/${this.state.question.id}`,
        newQuestion,
        this.config
      )
      .then(() => {
        this.setState({ question: newQuestion });
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
        this.setState({ showPopupDelete: false });
      });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className="container question-container">
            <div className="question-header">
              <div style={{ display: "flex" }}>
                <div
                  className={
                    this.state.question.status === "draft"
                      ? "question-not-selected"
                      : "question-not-selected question-selected"
                  }
                >
                  <img src="/images/check.svg" alt="vinkje" />
                </div>
                <h2>
                  <I18n t="qna.question" /> {this.state.question.id}
                </h2>
              </div>
            </div>

            {this.state.question.status === "deleted" && (
              <h1>
                <I18n t="qna.questionDeleted" />
              </h1>
            )}

            <div
              className={
                this.state.question.status === "deleted"
                  ? "question-specs-wrapper question-specs-wrapperdel"
                  : " question-specs-wrapper"
              }
            >
              <div className="half-width-container question-specs">
                <div className="half-width-content">
                  <h4>
                    <I18n t="qna.answerForm.who" />
                  </h4>
                  <ul>
                    <li>
                      <I18n t="qna.answerForm.name" />:
                    </li>
                    <li>
                      <I18n t="qna.answerForm.email" />:
                    </li>
                    <li>
                      <I18n t="qna.answerForm.org" />:
                    </li>
                    <li>
                      <I18n t="qna.answerForm.submitTime" />:
                    </li>
                  </ul>
                </div>
                <div className="half-width-content ">
                  <ul>
                    <li>
                      {this.state.question.name === "" ? (
                        <em>
                          <I18n t="qna.answerForm.removed" />
                        </em>
                      ) : (
                        this.state.question.name
                      )}
                    </li>

                    <li>
                      {this.state.question.email === "" ? (
                        <em>
                          <I18n t="qna.answerForm.removed" />
                        </em>
                      ) : (
                        this.state.question.email
                      )}
                    </li>

                    <li>{this.state.question.organization}</li>
                    <li>{parseDateTime(this.state.question.created_at)}</li>
                  </ul>
                </div>
              </div>

              <div className="half-width-container question-specs">
                <div className="half-width-content ">
                  <ul>
                    <li>
                      <I18n t="qna.answerForm.questionNumber" />:
                    </li>
                    <li>
                      <I18n t="qna.answerForm.publicationNumber" />:
                    </li>
                    <li>
                      <I18n t="qna.answerForm.publicationTime" />:
                    </li>
                  </ul>
                </div>
                <div className="half-width-content ">
                  <ul>
                    <li>{this.state.question.id}</li>
                    <li>{this.publishNumber()}</li>
                    <li>
                      {parseDateTime(this.state.question.published_date) !==
                      "" ? (
                        parseDateTime(this.state.question.published_date)
                      ) : (
                        <em>
                          <I18n t="qna.answerForm.afterPublication" />
                        </em>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="question-content-wrapper">
              <div className="half-width-container question-specs">
                <h4>
                  <I18n t="qna.question" />
                </h4>
                <p>{this.state.question.question}</p>
              </div>

              <div className="half-width-container question-specs">
                <h4>
                  <I18n t="qna.answer" />
                </h4>

                {this.state.question.status === "draft" ? (
                  <textarea
                    rows="8"
                    value={this.state.answer}
                    onChange={this.handleChangeAnswer}
                  ></textarea>
                ) : (
                  <p>{this.state.answer}</p>
                )}

                <h4>
                  <I18n t="qna.answerForm.category" />
                  {this.state.question.status === "draft" && (
                    <button
                      className="button-qna"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        this.setState({ showPopupCreateCategory: true })
                      }
                    >
                      <I18n t="qna.answerForm.categoryNew" />
                    </button>
                  )}
                </h4>

                <div>
                  <select
                    className={
                      "qa-select" +
                      (this.state.question.status !== "draft"
                        ? " select-disabled"
                        : " select-enabled")
                    }
                    value={this.state.categoryId}
                    onChange={this.handleChangeCategory}
                    disabled={this.state.question.status !== "draft"}
                  >
                    {this.state.categories.map((category) => {
                      return (
                        <option value={category.id} key={category.id}>
                          {category.label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {this.state.question.status === "draft" && (
                  <div className="button-container">
                    <button
                      type="button"
                      className="button save-publish-button"
                      onClick={() => this.publishAnswer()}
                    >
                      <I18n t="qna.answerForm.publish" />
                    </button>
                    <button
                      type="button"
                      className="button save-publish-button"
                      onClick={() => this.saveAnswer(false)}
                    >
                      <I18n t="qna.answerForm.save" />
                    </button>
                  </div>
                )}

                {this.state.question.status === "published" && (
                  <div className="button-container">
                    <button
                      type="button"
                      className="button save-publish-button"
                      onClick={() => this.setState({ showPopupDelete: true })}
                    >
                      <I18n t="qna.answerForm.remove" />
                    </button>
                  </div>
                )}
              </div>
            </div>

            <Divider></Divider>

            {this.state.showPopup && (
              <Modal open onClose={() => this.setState({ showPopup: false })}>
                <h1>
                  <I18n t="qna.answerForm.publishWarning" />
                </h1>
                <button onClick={() => this.saveAnswer(true)}>
                  <I18n t="qna.answerForm.publishConfirm" />
                </button>
              </Modal>
            )}

            {this.state.showPopupDelete && (
              <Modal
                open
                onClose={() => this.setState({ showPopupDelete: false })}
              >
                <h1>
                  <I18n t="qna.answerForm.removeWarning" />
                </h1>
                <button onClick={() => this.deleteQuestion()}>
                  <I18n t="qna.answerForm.confirm" />
                </button>
              </Modal>
            )}

            {this.state.showPopupCreateCategory && (
              <Modal
                open
                onClose={() =>
                  this.setState({ showPopupCreateCategory: false })
                }
              >
                {this.state.question.status === "draft" && (
                  <form onSubmit={this.createCategory}>
                    <div className={`text-input`}>
                      <label>
                        <I18n t="qna.answerForm.categoryNewLabel" />
                        <input
                          type="text"
                          name="label"
                          id="categoryField"
                        ></input>
                      </label>
                      {this.state.categoryError !== "" && (
                        <span className="form-error type-text">
                          {this.state.categoryError}
                        </span>
                      )}
                    </div>
                    <button type="submit">
                      <I18n t="qna.answerForm.categoryNewSave" />
                    </button>
                  </form>
                )}
              </Modal>
            )}
          </div>
        )}
      </>
    );
  }
}

AnswerQuestionBlock.propTypes = {
  question: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
};
