import React, { useEffect } from "react";
import PropTypes from "prop-types";

import parse from "html-react-parser";
import ReactMarkdown from "react-markdown";
import CollapseBox from "../collapsebox";
import IconList from "../iconlist";

import TimeLine from "../timeline";
import FinanceBox from "../financebox";
import DeadlineBox from "../deadlinebox";
import Partners from "../Partners";
import ImageBox from "../imagebox";
import AllPartners from "../allpartners";
import ProcedureBox from "../procedurebox";
import PianooBox from "../pianoobox";
import BlogCard from "../BlogCard";
import ContactForm from "../ContactForm";

import { getApiPrefix, stringToSlug } from "../../utils";

import "./styles.scss";
import Divider from "../divider";
import Testimonials from "../Testimonials";
import newsletterBoxImage from "../../assets/images/newsletter_box_corner.png";
import NewsletterForm from "../NewsletterForm";

import I18n from "../../i18n";
import { NavLink, useLocation } from "react-router-dom";
import ProjectList from "../projectlist";
import ProcedureBlocks from "../procedureblocks";
import Glossary from "../glossary";
import ExternalLink from "../externallink";

const ContentItem = ({
  projectInfo = {},
  content = {},
  events = [],
  pageType,
  underConstructionText,
}) => {
  const location = useLocation();

  useEffect(() => {
    const col = document.querySelector(".project_container > .col2");
    if (col == null) return;

    // Set external link icon
    const links = col.querySelectorAll("a");
    for (let i = 0; i < links.length; i += 1) {
      if (!links[i].href.startsWith("/")) {
        if (links[i].classList.contains("nav__link")) continue;
        if (links[i].classList.contains("file")) continue;
        if (links[i].classList.contains("download__bar")) continue;
        if (links[i].classList.contains("border__item")) continue;
        if (links[i].classList.contains("logo-item")) continue;
        if (links[i].classList.contains("partner-link")) continue;
        if (links[i].href.startsWith("tel:")) continue;
        if (links[i].href.startsWith("https://innovatiepartners")) continue;
        if (links[i].href.startsWith("mailto:")) continue;
        links[i].classList.add("external__link");
        links[i].setAttribute("target", "_blank");
      }
    }
  }, []);

  // eslint-disable-next-line no-underscore-dangle
  switch (content.__component) {
    case "list":
      return (
        <IconList color="yellow" key={`${stringToSlug(content.key)}-list`}>
          {content.listcontent.map((listItem) => {
            return (
              <li type="pointer" key={listItem}>
                {parse(listItem)}
              </li>
            );
          })}
        </IconList>
      );

    case "checklist":
      return (
        <IconList color="green" key={`${stringToSlug(content.key)}-checklist`}>
          {content.listcontent.map((listItem) => {
            return (
              <li type="check" key={listItem}>
                {parse(listItem)}
              </li>
            );
          })}
        </IconList>
      );

    case "contentblocks.glossary":
      return <Glossary />;

    case "contentblocks.image-list":
      return (
        <div className="content__image__list">
          <div
            className={
              pageType === "home"
                ? "container__small small-width content__basic"
                : "content__basic"
            }
          >
            {content.title && <h3>{content.title}</h3>}
            {content.introduction && (
              <ReactMarkdown>{content.introduction}</ReactMarkdown>
            )}
          </div>
          <div className="items">
            {content.images?.map((item, index) => {
              return (
                <div
                  className={
                    content.full_image !== null && content.full_image === true
                      ? "item item-full"
                      : "item"
                  }
                  key={index}
                >
                  {item?.icon && (
                    <div className="image">
                      <img
                        src={`${getApiPrefix()}${item.icon.url}`}
                        alt={item.icon.alt}
                      />
                    </div>
                  )}
                  {item.title && <strong>{item.title}</strong>}
                  <ReactMarkdown>{item.text}</ReactMarkdown>
                </div>
              );
            })}
          </div>
        </div>
      );

    case "contentblocks.list":
      return (
        <div className="content__list">
          <div className="container__small">
            {content.title && <h3>{content.title}</h3>}
            {content.summary && (
              <ReactMarkdown>{content.summary}</ReactMarkdown>
            )}
          </div>
          <div className="content__list__items cards__grid">
            {content.item?.map((item, index) => {
              return (
                <div className="item container__small" key={index}>
                  {item?.icon && (
                    <div className="icon">
                      <img
                        src={`${getApiPrefix()}${item.icon.url}`}
                        alt={item.icon.alt}
                      />
                    </div>
                  )}
                  {<ReactMarkdown>{item.text}</ReactMarkdown>}
                </div>
              );
            })}
          </div>
          {!!content?.moreContentLink && !!content?.moreContentButton && (
            <NavLink to={content.moreContentLink}>
              {content.moreContentButton}
            </NavLink>
          )}
        </div>
      );

    case "contentblocks.expander":
      return (
        <CollapseBox
          key={`${stringToSlug(content.title_nl)}-collapse`}
          title={content.title_nl}
        >
          <div className="content__basic">
            <ReactMarkdown source={content.content_nl} />
          </div>

          {content.downloads_nl.map((file, key) => (
            <a
              href={`${getApiPrefix()}${file.url}`}
              className="download__bar file"
              key={key}
            >
              <div className="text__part">
                <img
                  src={`/icons/${
                    file.name.split(".").pop() !== "pdf" ? "file" : "pdf"
                  }.svg`}
                  alt="download icon"
                />
                <p>{file.name}</p>
              </div>
              <img src="/icons/download-arrow-blue.svg" alt="download arrow" />
            </a>
          ))}
        </CollapseBox>
      );

    case "contentblocks.scheidslijn":
      return <Divider key={`${stringToSlug(content.key)}-list`} />;

    case "contentblocks.vimeo-video":
      return (
        <>
          <h3>{content.kop_nl}</h3>
          <ReactMarkdown>{content.introductie_nl}</ReactMarkdown>
          <div className="embed__responsive__video">
            <iframe
              className="responsive-iframe"
              title={content.title_nl}
              src={`https://player.vimeo.com/video/${content.vimeo_code}`}
              width="100%"
              height="480"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>
        </>
      );

    case "contentblocks.logos":
      return (
        <div className="content__partners">
          {projectInfo.partners == null ? (
            <AllPartners horizontal={content.horizontal} />
          ) : (
            <>
              <h3>
                <I18n t="partners" />
              </h3>
              <Partners
                partners={projectInfo.partners}
                horizontal={content.horizontal}
              />
            </>
          )}
          {!!content?.moreContentLink && !!content?.moreContentButton && (
            <NavLink to={content.moreContentLink}>
              {content.moreContentButton}
            </NavLink>
          )}
        </div>
      );

    case "contentblocks.budget":
      return (
        <>
          <FinanceBox
            key={`${stringToSlug(content.title_nl)}-collapse`}
            budget={content.amount}
            text={content.body_nl}
            title={content.label_nl}
          />
        </>
      );

    case "contentblocks.basic-content":
      return (
        <div key={`${content.id}-content`}>
          <div className={`content__basic list__${content.List}`}>
            <ReactMarkdown source={content.body_nl} />
          </div>
        </div>
      );

    case "contentblocks.action-button":
      const Link = content.external ? ExternalLink : NavLink;

      return (
        <div
          className={`content__action__button
            ${content.centered ? " center" : ""}`}
        >
          <Link
            to={content.buttonLink}
            className={`${content.style} ${
              content.external ? "" : " nav__link"
            }`}
          >
            {content.buttonText}
          </Link>
        </div>
      );

    case "contentblocks.procedure-blocks":
      return (
        <div className="large-width">
          <ProcedureBlocks blocks={content.procedure_blocks} />
        </div>
      );

    case "contentblocks.procedure":
      return <ProcedureBox procedure={content.procedure} inline={true} />;

    case "contentblocks.image":
      return (
        <ImageBox
          url={`${getApiPrefix()}${content.image.url}`}
          key={`${content.id}-list`}
        />
      );

    case "contentblocks.testimonials":
      return (
        <Testimonials
          testimonials={
            projectInfo?.testimonials ?? content?.testimonials ?? []
          }
        />
      );

    case "contentblocks.blogs":
      const blogsPath = I18n.getTranslation(location, "blogs.path");
      return (
        <div className="content__blogs">
          <div className="container__small">
            <ReactMarkdown>{content.introduction}</ReactMarkdown>
          </div>
          <div className="cards__grid">
            {content.blogs?.map((blog) => (
              <BlogCard key={blog.id} blogsPath={blogsPath} blog={blog} />
            ))}
          </div>
          <NavLink to={blogsPath}>{content.allBlogsText}</NavLink>
        </div>
      );

    case "contentblocks.projects":
      return (
        <div className="content__projects">
          <div className="container__small">
            <ReactMarkdown>{content.introduction}</ReactMarkdown>
          </div>
          <div id="projects" />
          <ProjectList
            horizontal
            noProjectsText={content.noProjectsText}
            underConstructionText={underConstructionText}
            // only add as props when explicitly present (else use default calls)
            lokets={!!content.lokets?.length ? content.lokets : null}
            projects={!!content.projects?.length ? content.projects : null}
            wrapperClass={(length) => `cards__grid max_${length}`}
          />
          {content.allProjectsLink && content.allProjectsText && (
            <NavLink to={content.allProjectsLink}>
              {content.allProjectsText}
            </NavLink>
          )}
        </div>
      );

    case "contentblocks.tijdlijn":
      return <TimeLine {...content} location={location} />;

    case "contentblocks.deadline":
      return <DeadlineBox events={events} project={projectInfo} />;

    case "contentblocks.download":
      return content.file_nl === null ? (
        <h1>
          <I18n t="downloadNoFile" />
        </h1>
      ) : (
        <a
          href={`${getApiPrefix()}${content.file_nl.url}`}
          target="_blank"
          className="download__bar"
          rel="noreferrer"
        >
          <div className="text__part">
            <img src="/icons/file.svg" alt="download icon" />
            <p>{content.name_nl}</p>
          </div>
          <img src="/icons/download-arrow-blue.svg" alt="download arrow" />
        </a>
      );

    case "contentblocks.link":
      return (
        <a
          href={content.url_nl}
          target="_blank"
          rel="noopener noreferrer"
          className="download__bar"
        >
          <div className="text__part">
            <img src="/icons/info.svg" alt="download icon" />
            <p>{content.title_nl}</p>
          </div>
          <img src="/icons/download-arrow-blue.svg" alt="download arrow" />
        </a>
      );

    case "contentblocks.pianoo":
      return <PianooBox text={content.text_nl} />;

    case "contentblocks.blok-met-plaatje-rechts":
      return (
        <div className="content__with__image">
          <div className="text__content">
            <h5>{content.title_nl}</h5>
            <ReactMarkdown source={content.text_nl} />
          </div>
          <img src={`${getApiPrefix()}${content.image.url}`} alt="" />
        </div>
      );

    case "contentblocks.contact":
      return (
        <div id="contact" className="content__contact">
          <div className="container__small">
            <ContactForm {...content} />
          </div>
        </div>
      );

    case "contentblocks.newsletter-form":
      return (
        <div className="newsletter__box">
          <img
            className="newsletter__box_image"
            src={newsletterBoxImage}
            alt=""
          />
          <div className="newsletter__content">
            <h2>{content.header}</h2>
            <NewsletterForm lijstId={content.lijstId} />
            <ReactMarkdown className="introduction">
              {content.introduction}
            </ReactMarkdown>
          </div>
        </div>
      );

    default:
      // eslint-disable-next-line no-underscore-dangle
      return (
        <h3>
          {content.__component}: <I18n t="noContent" />
        </h3>
      );
  }
};

ContentItem.propTypes = {
  partners: PropTypes.array,
  content: PropTypes.object,
  events: PropTypes.array,
};

export default ContentItem;
