import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { makeSiteBossURL } from "../../utils";
import I18n from "../../i18n";

export default function UpdatePassword(props) {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setInvalidCredentials(false);
    axios.defaults.headers.put[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("siteboss_client_token")}`;

    axios
      .put(makeSiteBossURL(`/account/update/password/`), data)
      .then((response) => {
        // if (response.data.status === "ok") {
        //   setInvalidCredentials(response.data.message);
        // } else {
        setInvalidCredentials(response.data.message);
        //}
      })
      .catch((error) => {
        setInvalidCredentials(
          I18n.getTranslation(window.location, "somethingWrong")
        );
      });
  };

  return (
    <div className="header-login">
      <div>
        <h3>
          <I18n t="account.edit.editPassword" />
        </h3>
        <p>
          <I18n t="account.edit.editPasswordText" />
        </p>
        <div className="invalidCredentials">{invalidCredentials}</div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="currentPassword">
              <input
                type="password"
                placeholder={I18n.getTranslation(
                  window.location,
                  "account.edit.currentPassword"
                )}
                className="form-control"
                id="currentPassword"
                autoComplete="current-password"
                {...register("password", {
                  required: true,
                  minLength: 8,
                  maxLength: 50,
                })}
              />
            </label>

            {errors.password && (
              <span className="invalidCredentials">
                <I18n t="account.edit.currentPasswordError" />
              </span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="newPassword">
              <input
                type="password"
                placeholder={I18n.getTranslation(
                  window.location,
                  "account.edit.editPassword"
                )}
                className="form-control"
                id="newPassword"
                {...register("newpassword", {
                  required: true,
                  minLength: 10,
                  maxLength: 50,
                })}
              />

              {errors.newpassword && (
                <span className="invalidCredentials">
                  <I18n t="account.edit.passwordCharacters" />
                </span>
              )}
            </label>
          </div>

          <p>
            <I18n t="passwordComplexityHint" />
          </p>

          <button type="submit" className="login-button">
            <I18n t="account.edit.editPassword" />
          </button>
        </form>
      </div>
    </div>
  );
}
