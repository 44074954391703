import React from "react";
import I18n from "../../i18n";

export default function PdfGenerator(props) {
  const projectId = props.projectId;

  // TODO: Make this button work for 'loket'

  return (
    <>
      <div className="item__title">
        <h4>
          <I18n t="qna.downloadPdf" />
        </h4>
        <p>
          <I18n t="qna.downloadPdfText" />
        </p>
      </div>

      <a href={`/siteboss/api/public/nl/pdf/${projectId}`}>
        <span className="download__bar">
          <div className="text__part">
            <img src="/icons/pdf.svg" alt="download icon" />
            <p>
              <b>
                <I18n t="qna.all" />
              </b>
            </p>
          </div>
          <img src="/icons/download-arrow-white.svg" alt="download arrow" />
        </span>
      </a>
    </>
  );
}
