import React from "react";
import "./styles.scss";

const CollapseBox = ({
  children,
  title,
  expanded,
  customColor,
  mode = "default",
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  let buttonClass = "collapsebox__button";
  let contentClass = "collapsebox__content";

  const isOpen = expanded ? expanded : open;

  if (isOpen) {
    buttonClass = `${buttonClass} open`;
    contentClass = `${contentClass} open`;
  }

  const checkBg = () => {
    if (customColor) {
      return { backgroundColor: customColor, borderRadius: "0.5rem" };
    }

    return null;
  };

  return (
    <div className={`collapsebox ${mode}`} style={checkBg()}>
      <button
        onClick={handleClick}
        aria-controls="t1"
        aria-expanded={open}
        className={buttonClass}
      >
        {title}
      </button>

      <div className={contentClass} style={{ whiteSpace: "pre-line" }}>
        {children}
      </div>
    </div>
  );
};

export default CollapseBox;
