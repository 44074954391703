import React from "react";
import { useLocation } from "react-router-dom";
import I18n from "../../../../i18n";

export default function File({
  field,
  value, // not the value of setValue! will stay at defaultValue!
  error,
  alertStatus,
  active,
  register,
}) {
  const location = useLocation();
  const { label, multiple, accepts, frontendrequired } = field.properties;
  const [filesDeleted, setFilesDeleted] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(null);

  const handleFileRemove = (fileId) => {
    setFilesDeleted(filesDeleted.concat([fileId]));
  };

  const clearInput = () => {
    // get input without ref (conflict with register, but less reliable)
    const currentInput = document.getElementById("fileInput" + field.id);
    // clear the input field value
    if (currentInput) currentInput.value = null;
    setInputValue(null);
  };

  const fieldIsRequired = () => {
    if (!active) return {};
    if (frontendrequired && !multiple && !value)
      return {
        required: I18n.getTranslation(location, "apply.required"),
      };
    if (
      frontendrequired &&
      value &&
      !value.filter((file) => !filesDeleted.includes(file.uuid)).length
    )
      return {
        required: I18n.getTranslation(location, "apply.required"),
      };
    return { required: false };
  };

  return (
    <>
      <div
        className={`
          text-input 
          ${error?.message && "show-error"}
          ${alertStatus && error === undefined && "show-correct"}
        `}
      >
        <label>
          {label?.nl} {multiple && <em>(meerdere bestanden mogelijk)</em>}
        </label>
        <div className="file-upload-wrapper">
          <input
            id={"fileInput" + field.id}
            type="file"
            multiple={multiple}
            accept={accepts}
            name={field.id + ""}
            {...register(field.id + "", {
              validate: {
                acceptedFormats: (files) => {
                  if (!files) return "";

                  let accepted = 0;
                  for (let i = 0; i < files.length; i++) {
                    let file = files[i] || files.item(i);
                    if (!file.type || accepts.includes(file.type))
                      accepted += 1;
                  }

                  return (
                    accepted === files.length ||
                    !files.length ||
                    I18n.getTranslation(location, "apply.unacceptableFileType")
                  );
                },
              },
              ...fieldIsRequired(),
              onChange: (e) => setInputValue(e.target?.value),
            })}
          />
          {!!inputValue && (
            <button
              className="remove-button"
              type="button"
              onClick={() => clearInput()}
            ></button>
          )}
        </div>

        {value?.map((file) => {
          if (filesDeleted.includes(file.uuid)) return null;
          return (
            <div key={file.uuid} className="file-uploaded">
              <img src="/icons/file.svg" alt="document" />
              <span className="filename">{file.filename}</span>

              <button
                type="button"
                onClick={() => handleFileRemove(file.uuid)}
              />
            </div>
          );
        })}

        {/* show validation errors, but don't render unacceptable error if value has been removed */}
        {!(!inputValue && error?.type === "acceptedFormats") && (
          <span className="form-error">{error?.message}</span>
        )}
      </div>

      {/* add explicit input field for deleted files */}
      {filesDeleted.map((fileId, index) => {
        return (
          <input
            {...register(field.id + "-remove." + index)}
            value={fileId}
            hidden
            key={fileId}
          />
        );
      })}
    </>
  );
}
