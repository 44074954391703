import React, { useState } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { makeSiteBossURL } from "../../utils";
import PhoneInput from "react-phone-input-2";
import I18n from "../../i18n";

export default function UpdateMobile(props) {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [mobile, setMobile] = useState(0);
  const [selCountry, setCountry] = useState("nl");
  // eslint-disable-next-line no-unused-vars
  const [password, setPassword] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    setInvalidCredentials(false);
    axios.defaults.headers.put[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("siteboss_client_token")}`;
    data.country = selCountry;

    axios
      .put(makeSiteBossURL(`/account/update/mobile/`), data)
      .then((response) => {
        if (response.data.status === "ok") {
          setInvalidCredentials(response.data.message);
        } else if (response.data.status === "code") {
          setMobile(data.mobile);
          setPassword(data.password);
          setShowCode(true);
        } else {
          setInvalidCredentials(response.data.message);
        }
      })
      .catch((error) => {
        setInvalidCredentials(
          I18n.getTranslation(window.location, "somethingWrong")
        );
      });
  };

  const confirmCode = (data) => {
    setInvalidCredentials(false);
    data.mobile = mobile;
    data.country = selCountry;

    axios
      .put(makeSiteBossURL(`/account/confirm/mobile`), data)
      .then((response) => {
        if (response.data.status === "ok") {
          setMobile(0);
          setPassword("");
          reset();
          setShowCode(false);
        }

        setInvalidCredentials(response.data.message);
      })
      .catch((error) => {
        setInvalidCredentials(
          I18n.getTranslation(window.location, "somethingWrong")
        );
      });
  };

  return (
    <div className="header-login">
      <h3>
        <I18n t="account.edit.editPhone" />
      </h3>

      <p>
        <I18n t="account.edit.editPhoneText" />
      </p>

      <div className="invalidCredentials">{invalidCredentials}</div>

      {showCode ? (
        <SmsConfirm
          register={register}
          handleSubmit={handleSubmit}
          confirmCode={confirmCode}
          errors={errors}
        ></SmsConfirm>
      ) : (
        <PasswordConfirm
          setCountry={setCountry}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          onSubmit={onSubmit}
          errors={errors}
        ></PasswordConfirm>
      )}
    </div>
  );
}

const PasswordConfirm = (props) => (
  <form onSubmit={props.handleSubmit(props.onSubmit)}>
    <div className="form-group">
      <label htmlFor="currentPassword">
        <input
          type="password"
          placeholder={I18n.getTranslation(
            window.location,
            "account.edit.currentPassword"
          )}
          className="form-control"
          {...props.register("password", {
            required: true,
            minLength: 8,
            maxLength: 50,
          })}
        />
      </label>

      {props.errors.password && (
        <span className="invalidCredentials">
          <I18n t="account.edit.currentPasswordError" />
        </span>
      )}
    </div>

    <div className="form-group">
      <label htmlFor="mobileInput">
        <Controller
          control={props.control}
          name="mobile"
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error, validate },
            formState,
          }) => (
            <PhoneInput
              country="nl"
              value={value}
              onChange={onChange}
              enableSearch
              isValid={(inputNumber, country) => {
                props.setCountry(country.iso2);
                return inputNumber.startsWith(country.dialCode);
              }}
              containerClass="phoneClass"
              inputClass="inpurClass"
              regions={["europe"]}
            />
          )}
        />

        {props.errors.mobile && (
          <span className="invalidCredentials">
            <I18n t="account.edit.newPhoneText" />
          </span>
        )}
      </label>
    </div>

    <button type="submit" className="login-button">
      <I18n t="account.edit.editPhone" />
    </button>
  </form>
);

const SmsConfirm = (props) => (
  <form onSubmit={props.handleSubmit(props.confirmCode)} autocomplete="off">
    <div className="form-group">
      <label htmlFor="code">
        <input
          type="number"
          placeholder="Code"
          className="form-control"
          id="code"
          {...props.register("code", {
            required: true,
            minLength: 6,
            maxLength: 6,
          })}
        />
      </label>

      {props.errors.code && (
        <span className="invalidCredentials">
          <I18n t="account.edit.newCodeText" />
        </span>
      )}
    </div>

    <button type="submit" className="login-button">
      <I18n t="account.edit.confirmCode" />
    </button>
  </form>
);
