import React from "react";
import BaseLayout from "../../components/baselayout";
import { useStaleSWR } from "../../utils";
import Error from "../../components/error";
import Loading from "../../components/loading";
import ContentList from "../../components/contentlist";
import HeaderImage from "../../assets/images/home_header_public_sector.svg";

import "./styles.scss";
import HomeHeader from "../../components/HomeHeader";

const HomePublicSector = ({ location }) => {
  const { data: page, error } = useStaleSWR("/home-public-sector");

  const hash = location.hash;
  React.useEffect(() => {
    const element = document.getElementById(hash.replace("#", ""));
    if (!!element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  if (error) {
    return <Error message={error.message} />;
  }

  if (!page) {
    return <Loading />;
  }

  const setItemBackground = (item) => {
    const blocksWithBg = page.blocksWithBackground?.split(",");
    const itemsWithBg = blocksWithBg?.map((name) => `contentblocks.${name}`);

    if (!item || !itemsWithBg) return "";
    if (itemsWithBg.includes(item.__component)) return "bg-light";
    return "";
  };

  return (
    <BaseLayout type="home-public-sector" noContainer noBreadCrumbs>
      <div className="container">
        <HomeHeader
          headerImage={HeaderImage}
          headerTitleContent={page.header}
          headerCallToAction={{
            description: page.ctaDescription,
            contact: true,
            avatar: page.ctaContactAvatar,
            name: page.ctaContactName,
            function: page.ctaContactFunction,
            button: page.ctaContactButton,
            link: page.ctaContactLink,
          }}
        />
      </div>
      <ContentList
        pageType="home"
        contentitems={page.content}
        itemWrapper={({ item, children }) => (
          <div className={`home__content_block ${setItemBackground(item)}`}>
            <div className="container">
              <div
                className={
                  item.__component.includes("tijdlijn")
                    ? "container__small container__timeline"
                    : ""
                }
              >
                {children}
              </div>
            </div>
          </div>
        )}
      />
    </BaseLayout>
  );
};

export default HomePublicSector;
