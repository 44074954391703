import React from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { getApiPrefix } from "../../utils";
import I18n from "../../i18n";
import "./styles.scss";

export default function Login({ location, history }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const onSubmit = (data) => {
    axios
      .post(getApiPrefix() + `/auth/local`, data)
      .then((response) => {
        localStorage.setItem("token", response.data.jwt);
        history.replace("/answer");
      })
      .catch((error) => {
        setError("submit", {
          type: "custom",
          message:
            error.response?.status === 400
              ? I18n.getTranslation(location, "login.incorrectCredentials")
              : I18n.getTranslation(location, "somethingWrong"),
        });
      });
  };

  return (
    <div className="header-login">
      <div className="container">
        <a href="/">
          <img src="/images/innovatiepartners_logo.svg" alt="logo" />
        </a>

        <div className="about-content small-width">
          <h1>
            <I18n t="qna.portal" />
          </h1>
          <h2>
            <I18n t="login.title" />
          </h2>
          <div className="login">
            {errors?.submit?.message && (
              <div className="invalidCredentials">
                {errors?.submit?.message}
              </div>
            )}
            <form
              onChange={() => clearErrors("submit")}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label htmlFor="inputEmail">
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail"
                    aria-describedby="emailHelp"
                    placeholder={I18n.getTranslation(location, "login.email")}
                    name="identifier"
                    {...register("identifier", {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                </label>
                {errors.identifier && (
                  <span className="form-error">
                    <I18n t="qna.form.required" />
                  </span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="inputPassword">
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword"
                    aria-describedby="emailHelp"
                    name="password"
                    placeholder={I18n.getTranslation(
                      location,
                      "login.password"
                    )}
                    {...register("password", { required: true, maxLength: 50 })}
                  />
                </label>
                {errors.password && (
                  <span className="form-error">
                    <I18n t="qna.form.required" />
                  </span>
                )}
              </div>

              <button type="submit" className="login-button">
                <I18n t="login.loginAction" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
