import React from "react";
import "./styles.scss";
import I18n from "../../i18n";
import { NavLink } from "react-router-i18n";
import ExternalLink from "../externallink";
import linkedInLogo from "../../assets/icons/linkedin.svg";
import { DateTime } from "luxon";
import { ReactComponent as AccountIcon } from "../../assets/icons/account.svg";
import NewsletterForm from "../NewsletterForm";

const Footer = ({ location, loggedIn, blogs, blogsPath, paginas }) => {
  const mainInfoPage = paginas?.find((page) => page.mainMenu === true);
  return (
    <div className="footer">
      <div className="container footer__columns">
        <div>
          <img
            src="/images/innovatiepartners_logo.svg"
            alt="Site Logo"
            className="site_logo"
          />
          <NavLink
            to={mainInfoPage ? "/info/" + mainInfoPage.slug : "/"}
            className="about_text"
          >
            <I18n t="footer.aboutIP" />
          </NavLink>
          <div className="footer__linkedin">
            <ExternalLink to={I18n.getTranslation(location, "footer.linkedIn")}>
              <img src={linkedInLogo} alt="linkedIn logo" />
              <I18n t="footer.linkedInLabel" />
            </ExternalLink>
          </div>
        </div>
        <div className="footer__menu">
          <NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
            <I18n t="home" />
          </NavLink>
          {blogs && <NavLink to={"/" + blogsPath}>{blogs.title}</NavLink>}
          <NavLink to={mainInfoPage ? "/info/" + mainInfoPage.slug : "/"}>
            <I18n t="menu.procedures" />
          </NavLink>
          <NavLink to="/account">
            <AccountIcon />
            {loggedIn ? <I18n t="yourProfile" /> : <I18n t="login.title" />}
          </NavLink>
        </div>
        <div>
          <div>
            <h4>
              <I18n t="footer.newsletterHeader" />
            </h4>
            <p>
              <I18n t="footer.newsletterSubheader" />
            </p>
          </div>
          <NewsletterForm />
        </div>
      </div>
      <div className="footer__subfooter">
        <div className="container">
          <div className="footer__subfooter_menu">
            <ExternalLink
              to={I18n.getTranslation(location, "footer.privacyLink")}
            >
              <I18n t="footer.privacy" />
            </ExternalLink>
            <NavLink
              to={I18n.getTranslation(location, "footer.cookiesLink")}
              className="item-link "
              activeClassName="active"
            >
              <I18n t="footer.cookies" />
            </NavLink>

            <NavLink
              to={I18n.getTranslation(location, "footer.termsLink")}
              className="item-link "
              activeClassName="active"
            >
              <I18n t="footer.terms" />
            </NavLink>
          </div>
          <div className="footer__copyright">
            <span>&copy;</span>
            <I18n
              t="footer.copyright"
              args={{ year: DateTime.now().toFormat("yyyy") }}
            />
            <span>-</span>
            <I18n t="footer.title" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
