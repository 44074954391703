import React from "react";
import ExternalLink from "../externallink";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedin_simple.svg";
import { ReactComponent as Mail } from "../../assets/icons/e-mail_icon_purple.svg";
import { ReactComponent as WhatsApp } from "../../assets/icons/whatsapp_icon.svg";
import I18n from "../../i18n";

import "./styles.scss";
import { useLocation } from "react-router-dom";

const ShareLinks = ({ type, subject }) => {
  const location = useLocation();
  if (!subject) return null;

  const url = process.env.PUBLIC_URL || "https://www.innovatiepartners.nl";
  const subjectUrl = `${url}/${type}/${subject.slug}`;

  const shareMailSubject = encodeURIComponent(
    I18n.getTranslation(location, "projects.shareMailSubject", {
      type: I18n.getTranslation(location, "projects.type", { type: type }),
      title: subject.Titel,
    })
  );
  const shareMailBody = encodeURIComponent(
    I18n.getTranslation(location, "projects.shareMailBody", {
      title: subject.Titel,
      url: subjectUrl,
    })
  );
  const shareWhatsApp = encodeURIComponent(
    I18n.getTranslation(location, "projects.shareWA", {
      title: subject.Titel,
      url: subjectUrl,
    })
  );

  return (
    <div className="share__container">
      <I18n t="projects.share" args={{ type: type }} />
      <div>
        <ExternalLink
          to={`https://www.linkedin.com/sharing/share-offsite/?url=${subjectUrl}`}
        >
          <LinkedIn width={20} height={20} />
        </ExternalLink>
      </div>
      <div>
        <ExternalLink
          to={`mailto:?subject=${shareMailSubject}&body=${shareMailBody}`}
        >
          <Mail width={20} height={20} />
        </ExternalLink>
      </div>
      <div>
        <ExternalLink to={`https://wa.me/?text=${shareWhatsApp}`}>
          <WhatsApp width={20} height={20} />
        </ExternalLink>
      </div>
    </div>
  );
};

export default ShareLinks;
