import React from "react";

export default function Dropdown(props) {
  const properties = props.field.properties;

  var commaSeperatedValues = () => {
    const options = properties.options.list.filter(
      (option) => option.index === parseInt(props.value)
    );
    const optionNames = options.map((item) => {
      return item.option.nl;
    });

    return optionNames.join(", ");
  };

  return (
    <div className="item-text">
      <h3>{properties.label.nl}</h3>
      <p>{commaSeperatedValues()}</p>
    </div>
  );
}
