import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import I18n from "../../i18n";
import ReactMarkdown from "react-markdown";
import EmailPink from "../../assets/icons/email_pink.png";

import "./styles.scss";
import { useLocation } from "react-router-dom";
import { makeSiteBossURL } from "../../utils";

const ContactForm = ({ introduction, succesMessage }) => {
  const location = useLocation();
  const [succes, setSucces] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const dutchPhoneRegex =
    /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onChange = () => {
    if (error) setError(null);
    if (loading) setLoading(false);
    if (succes) setSucces(null);
  };

  const onSubmit = (data) => {
    const errorText = I18n.getTranslation(location, "somethingWrong");
    const succesText =
      succesMessage ?? I18n.getTranslation(location, "contact.succes");

    if (loading) return;

    setSucces(null);
    setError(null);
    setLoading(true);

    axios.defaults.headers.post["Authorization"] = "";
    axios
      .post(makeSiteBossURL("/user/contact/"), data)
      .then((response) => {
        setSucces(succesText);
        reset();
        setLoading(false);
      })
      .catch((response) => {
        setError(errorText);
        setLoading(false);
      });
  };

  return (
    <div className="contact__wrapper">
      <ReactMarkdown>{introduction}</ReactMarkdown>
      <form
        onChange={onChange}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="contact__form"
      >
        <div className="contact__fields">
          <div className="field">
            <input
              placeholder={I18n.getTranslation(location, "qna.form.name")}
              {...register("name", { required: true, maxLength: 50 })}
            />
            {errors.name && (
              <div className="form-error">
                <I18n t="qna.form.required" />
              </div>
            )}
          </div>
          <div className="field">
            <input
              placeholder={I18n.getTranslation(location, "qna.form.tel")}
              {...register("telephone", {
                required: true,
                pattern: dutchPhoneRegex,
              })}
            />
            {errors.telephone && (
              <div className="form-error">
                <I18n t="qna.form.correctTelephone" />
              </div>
            )}
          </div>
        </div>
        <div className="field">
          <input
            type="email"
            placeholder={I18n.getTranslation(location, "qna.form.email")}
            {...register("email", {
              required: true,
              pattern: emailRegex,
            })}
          />
          {errors.email && (
            <div className="form-error">
              <I18n t="qna.form.correctEmail" />
            </div>
          )}
        </div>
        <div className="field">
          <textarea
            rows={6}
            placeholder={I18n.getTranslation(location, "contact.question")}
            {...register("question", { required: true })}
          />
          {errors.question && (
            <div className="form-error">
              <I18n t="qna.form.required" />
            </div>
          )}
        </div>
        {/* <div className="field">
          <div className="checkbox__wrapper">
            <label className="checkbox__container">
              <input
                type="checkbox"
                value="1"
                {...register("checkbox1", { required: true })}
              />
              <span className="checkmark" />
              <p>
                <I18n t="qna.form.privacy" />
              </p>
            </label>
          </div>
          {errors.checkbox1 && (
            <div className="form-error">
              <I18n t="qna.form.privacyRequired" />
            </div>
          )}
        </div>
        <div className="field">
          <div className="checkbox__wrapper">
            <label className="checkbox__container">
              <input
                type="checkbox"
                value="1"
                {...register("checkbox2", { required: true })}
              />
              <span className="checkmark" />
              <p>
                <I18n t="qna.form.privacy2" />
              </p>
            </label>
          </div>
          {errors.checkbox2 && (
            <div className="form-error">
              <I18n t="qna.form.privacyRequired" />
            </div>
          )}
        </div> */}
        <button
          disabled={loading || error}
          type="submit"
          className="submit__form apply__button"
        >
          <img src={EmailPink} alt="email" />
          <I18n t="contact.send" />
        </button>
        {error && <div className="form-error">{error}</div>}
        {succes && (
          <ReactMarkdown className="form-succes">{succes}</ReactMarkdown>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
