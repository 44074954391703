import React from "react";
import ReactMarkdown from "react-markdown";
import QuotesLogo from "../../assets/icons/quotes.svg";
import { getApiPrefix } from "../../utils";
import Image from "../Image";
import Carousel from "../Carousel";

import "./styles.scss";

const Testimonials = ({ testimonials }) => {
  return (
    <div
      className={
        "testimonials__wrapper" + (testimonials.length > 1 ? " padded" : "")
      }
    >
      <Carousel>
        {testimonials.map((testimonial, index) => {
          return (
            <div key={index} className="testimonial">
              {testimonial.organizationLogo && (
                <Image
                  wrapper={(img) => (
                    <div className="testimonial__logo">
                      <div className="img__block">{img}</div>
                    </div>
                  )}
                  src={
                    getApiPrefix() +
                    (testimonial.organizationLogo.formats?.thumbnail?.url || "")
                  }
                  alt={testimonial.organizationLogo.alternativeText}
                />
              )}
              <div className="testimonial__quote">
                {/* add quote svg as markdown to memo */}
                <ReactMarkdown
                  source={`![](${QuotesLogo})` + testimonial.memo}
                />
              </div>
              <div className="testimonial__source">
                {testimonial.sourceAvatar && (
                  <Image
                    wrapper={(img) => (
                      <div className="img__block">
                        <div className="img__overlay" />
                        {img}
                      </div>
                    )}
                    src={
                      getApiPrefix() +
                      (testimonial.sourceAvatar.formats?.thumbnail?.url || "")
                    }
                    alt={testimonial.sourceAvatar.alternativeText}
                  />
                )}
                <div className="text">
                  <h4>{testimonial.sourceName}</h4>
                  {testimonial.sourceFunction && (
                    <p>{testimonial.sourceFunction}</p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Testimonials;
