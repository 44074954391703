import React from "react";
import { Scrollspy } from "@makotot/ghostui";
import { useRouteMatch } from "react-router-dom";
import { NavLink } from "react-router-i18n";

import "./styles.scss";

const OldSchoolMenuLink = ({ title, to, activeOnlyWhenExact, list, refs }) => {
  const match = useRouteMatch({
    path: to.pathname,
    exact: activeOnlyWhenExact,
  });

  return (
    <div className={`index__list__container ${match ? "active" : ""}`}>
      <NavLink to={to} className={`item-link ${match ? "active" : ""}`} exact>
        {title}
      </NavLink>
      <div className="index__list">
        {!!refs && !!refs.length && !!list && !!list.length && (
          <Scrollspy sectionRefs={refs} offset={-136}>
            {({ currentElementIndexInViewport }) => {
              return (
                <>
                  {match &&
                    list.map((item, index) => {
                      if (!item.title) return null;
                      return (
                        <li
                          key={index}
                          className={`${
                            currentElementIndexInViewport === index
                              ? "is-current-row"
                              : ""
                          }`}
                        >
                          <a href={`#anchor${index}`}>{item.title}</a>
                        </li>
                      );
                    })}
                </>
              );
            }}
          </Scrollspy>
        )}
      </div>
    </div>
  );
};

export default OldSchoolMenuLink;
