import React from "react";
import ReactMarkdown from "react-markdown";
import BaseLayout from "../../components/baselayout";
import "./styles.scss";
import { useStaleSWR } from "../../utils";
import Error from "../../components/error";
import Loading from "../../components/loading";
import TimedToast from "../../components/timedToast";
import ContentList from "../../components/contentlist";
import HomeHeader from "../../components/HomeHeader";
import HomeHeaderImage from "../../assets/images/home_header.svg";
import GridRow from "../../components/gridrow";

export default function Home() {
  const { data: page, error } = useStaleSWR("/homepage/");

  if (error) {
    return <Error message={error.message} />;
  }

  if (!page) {
    return <Loading />;
  }

  const setItemBackground = (item) => {
    const blocksWithBg = page.blocksWithBackground?.split(",");
    const itemsWithBg = blocksWithBg?.map((name) => `contentblocks.${name}`);

    if (!item || !itemsWithBg) return "";
    if (itemsWithBg.includes(item.__component)) return "bg-light";
    return "";
  };

  const extraWrapOnType = (type) => {
    if (type.includes("tijdlijn"))
      return "container__small container__timeline";
    if (type.includes("procedure-blocks")) return "large-width";
    return "";
  };

  return (
    <BaseLayout type="home" noBreadCrumbs noContainer>
      <div className="container">
        <HomeHeader
          headerTitleContent={page.header}
          headerCallToAction={{
            description: page.call_to_action_description,
            memo: page.call_to_action_memo,
            buttons: true,
            button_primary: page.call_to_action_button_primary,
            link_primary: page.call_to_action_primary_link,
            button_secondary: page.call_to_action_button_secondary,
            link_secondary: page.call_to_action_secondary_link,
          }}
          headerContent={
            <div>
              <ReactMarkdown>{page.subtitle}</ReactMarkdown>
              <p>{page.titledescription}</p>
            </div>
          }
          headerImage={HomeHeaderImage}
          headerList={[
            {
              name: page.icon1_text,
              image: "/icons/budget_yellow.svg",
            },
            {
              name: page.icon2_text,
              image: "/icons/location-purple.svg",
            },
            {
              name: page.icon3_text,
              image: "/icons/solution-blue.svg",
            },
          ]}
        />
      </div>
      <ContentList
        pageType="home"
        underConstructionText={page.text1}
        contentitems={page.content}
        itemWrapper={({ item, children }) => (
          <div className={`home__content_block ${setItemBackground(item)}`}>
            <div className="container">
              <div className={extraWrapOnType(item.__component)}>
                {children}
              </div>
            </div>
          </div>
        )}
      />
      {(!!page.footer || !!page.subfooter) && (
        <GridRow type="small">
          <div className="credits__wrapper">
            {page.footer && <p className="credits-title">{page.footer}</p>}
            {page.subfooter && <ReactMarkdown>{page.subfooter}</ReactMarkdown>}
            <img
              src="/images/amsterdam_logo.svg"
              alt="logo gemeente Amsterdam"
            />
          </div>
        </GridRow>
      )}
      {!!page.popup && (
        <TimedToast>
          <ReactMarkdown linkTarget="_blank">{page.popup}</ReactMarkdown>
        </TimedToast>
      )}
    </BaseLayout>
  );
}
