import React from "react";
import "./styles.scss";

const GridRow = ({ type = "small", columns = false, colorBlock, children }) => {
  let gridClass = `gridrow__${type}`;
  gridClass = columns ? `${gridClass} cols` : gridClass;
  gridClass = colorBlock ? `${gridClass} color_block ${colorBlock}` : gridClass;

  return <div className={gridClass}>{children}</div>;
};

export default GridRow;
