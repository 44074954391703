import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import I18n from "../../i18n";
import { makeSiteBossURL } from "../../utils";

export default function AccountRegister(props) {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [allOK, setAllOK] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    handleSubmitPost(data);
  };

  // Register end user
  const handleSubmitPost = (data) => {
    setInvalidCredentials(false);
    axios
      .post(makeSiteBossURL("/account/register/email"), data)
      .then((response) => {
        if (response.data.status !== "ok") {
          if (response.data.message) {
            setInvalidCredentials(response.data.message);
          } else {
            setInvalidCredentials(
              I18n.getTranslation(window.location, "somethingWrong")
            );
          }
        } else {
          setAllOK(true);
        }
      })
      .catch((error) => {
        setInvalidCredentials(
          I18n.getTranslation(window.location, "somethingWrong")
        );
      });
  };

  return (
    <div className="container">
      <h1>
        <I18n t="login.createAccount" />
      </h1>

      {allOK ? (
        <div>
          <p>
            <strong>
              <I18n t="login.confirmEmail" />
            </strong>
          </p>
          <p>
            <I18n t="login.getEmail" />
          </p>
          <p>
            <I18n t="login.noEmailConfirmation" />
            <a href="mailto:innovatiepartners@amsterdam.nl">
              innovatiepartners@amsterdam.nl
            </a>
            .
          </p>
        </div>
      ) : (
        <>
          <p>
            <strong>
              <I18n t="login.supplyEmail" />
            </strong>
          </p>

          <p>
            <I18n t="login.registerEmailText" />
          </p>

          <div className="login">
            <div className="invalidCredentials">{invalidCredentials}</div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="emailAddress">
                  <input
                    type="email"
                    className="form-control"
                    id="emailAddress"
                    aria-describedby="emailHelp"
                    placeholder={I18n.getTranslation(
                      window.location,
                      "login.email"
                    )}
                    name="identifier"
                    {...register("identifier", {
                      required: true,
                      type: "email",
                      pattern: /^\S+@\S+$/,
                      maxLength: 100,
                    })}
                  />
                </label>

                {errors.identifier && (
                  <span className="invalidCredentials">
                    <I18n t="qna.form.correctEmail" />
                  </span>
                )}
              </div>

              <button type="submit" className="login-button">
                <I18n t="login.confirmCreate" />
              </button>
            </form>
          </div>
        </>
      )}

      <button className="switch-link" onClick={() => props.setState("login")}>
        <I18n t="login.backToLogin" />
      </button>
    </div>
  );
}
