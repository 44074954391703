import React from "react";
import ReactMarkdown from "react-markdown";
import { NavLink } from "react-router-i18n";
import { getApiPrefix } from "../../utils";
import { useStaleSWR } from "../../utils";
import "./styles.scss";

export default function ProcedureBlocks({ blocks = [] }) {
  const { data: paginas } = useStaleSWR(`/paginas`);

  const mainInfoPage = paginas?.find((page) => page.mainMenu === true);

  return (
    <div className="aanbesteding-cards">
      {blocks.map((block, index) => {
        const pagina = paginas?.find((page) => page.id === block.pagina);

        if (!pagina) return null;
        return (
          <NavLink
            key={index}
            to={{
              pathname: "/info/" + pagina.slug,
              state: mainInfoPage && {
                mainLink: "/info/" + mainInfoPage.slug,
              },
            }}
            className={`inkopen__aanbesteden bg_color_${
              block.color ? block.color : "lila"
            }`}
          >
            <div className="flex-orientation">
              <h3>{block.title_nl}</h3>

              <ReactMarkdown source={block.text_nl} />
            </div>
            {block.image !== null && (
              <img
                src={`${getApiPrefix()}${block.image.url}`}
                alt={block.title_nl}
              />
            )}
          </NavLink>
        );
      })}
    </div>
  );
}
