import React from "react";
import ReactMarkdown from "react-markdown";
import NewsletterForm from "../NewsletterForm";
import Modal from "../Modal";
import I18n from "../../i18n";
import "./styles.scss";

const UnderConstructionCard = ({
  text,
  asCard,
  horizontal,
  addNewsletterCTA,
  addImage,
}) => {
  if (!text) return null;
  return (
    <div
      className={`beta_melding ${asCard ? "card" : ""} ${
        horizontal ? "horizontal" : ""
      } ${addImage ? "with_image" : ""}`}
    >
      {addImage && (
        <img
          className="beta_melding__image"
          src="/images/binnenkort_meer.svg"
          alt="wachtend persoon"
        />
      )}
      <div>
        <ReactMarkdown className="beta_content">{text}</ReactMarkdown>
        {addNewsletterCTA && (
          <Modal trigger={<I18n t="newsletter.submit" />}>
            <h4>
              <I18n t="footer.newsletterHeader" />
            </h4>
            <p>
              <I18n t="footer.newsletterSubheader" />
            </p>
            <NewsletterForm />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default UnderConstructionCard;
