import React from "react";
import BaseLayout from "../../components/baselayout";
import { useStaleSWR } from "../../utils";
import ContentItem from "../../components/contentitem";
import Loading from "../../components/loading";
import Error from "../../components/error";
import NotFound from "../notfound";
import ReactMarkdown from "react-markdown";
import I18n from "../../i18n";

import "./styles.scss";
import { Redirect } from "react-router-dom";

// dynamic info pages
export default function Info({ match, location }) {
  const { data, error } = useStaleSWR(
    `/paginas?slug=${match.params.slug}&_locale=all`,
    false,
    false
  );
  const page = !!data && data[0];

  if (error) {
    return <Error message={error.message} />;
  }

  if (!data) {
    return <Loading />;
  }

  if (
    page &&
    page.locale !== "nl-NL" &&
    !location.pathname.includes(`/${page.locale}/`)
  ) {
    return <Redirect to={`/${page.locale}${location.pathname}`} />;
  }

  if (data && !page) return <NotFound />;

  const mainCrumb = location.state?.mainLink
    ? [
        {
          title: I18n.getTranslation(window.location, "projects.procedures"),
          path: location.state?.mainLink,
        },
      ]
    : [];

  return (
    <BaseLayout type="page" breadcrumbs={[...mainCrumb, { title: page.title }]}>
      {page.introduction && (
        <header className="header__procedure">
          <div className="title__and__intro">
            <div>
              <h3>{page.title}</h3>
              <ReactMarkdown source={page.introduction} />
            </div>
          </div>
          <img src="/images/wat_is_aanbesteden.svg" alt="vraagteken" />
        </header>
      )}
      {!page.introduction && (
        <div className="item__title__big small-width">
          <h1>{page.title}</h1>
        </div>
      )}
      <div>
        {page.content?.map((block, index) => {
          return (
            <div
              className={
                block.__component === "contentblocks.procedure-blocks" ||
                block.__component === "contentblocks.image-list"
                  ? "item__title__big"
                  : "item__title__big small-width"
              }
              key={index}
            >
              <ContentItem content={block} partners={[]} events={[]} />
            </div>
          );
        })}
      </div>
    </BaseLayout>
  );
}
