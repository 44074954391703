import React from "react";
import axios from "axios";
import { Link } from "react-router-i18n";
import { getApiPrefix, useNormalSWR } from "../../utils";
import Loading from "../../components/loading";
import Error from "../../components/error";
import I18n from "../../i18n";

import "./styles.scss";
import QnaLayout from "../../components/qnalayout";

export default function CategoriesOverview(props) {
  const { data: questions, error } = useNormalSWR(
    `/questions/?project=${props.match.params.id}`,
    false,
    false
  );

  if (error) {
    return <Error message={error.message} />;
  }

  if (!questions) {
    return <Loading />;
  }

  function createCategoryObject(question) {
    let category = question.category;
    category.questions = [];
    category.questions.push(question);
    return category;
  }

  let categories = [];
  let questionsWithoutCategory = [];

  for (let i = 0; i < questions.length; i++) {
    if (
      !questions[i].category ||
      Object.keys(questions[i].category).length === 0
    ) {
      questionsWithoutCategory.push(questions[i]);
      continue;
    }

    if (categories.length === 0) {
      categories.push(createCategoryObject(questions[i]));
    } else {
      let found = false;
      for (let j = 0; j < categories.length; j++) {
        if (categories[j].id === questions[i].category.id) {
          categories[j].questions.push(questions[i]);
          found = true;
        }
      }

      if (!found) {
        categories.push(createCategoryObject(questions[i]));
      }
    }
  }

  function downloadWord() {
    axios({
      url:
        getApiPrefix() +
        `/questions/word?project.id=${questions[0]?.project.id}&_sort=category.categoryCode:asc,questionCode:asc`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        questions[0]?.project.name + "-vragen.docx"
      );
      document.body.appendChild(link);
      link.click();
    });
  }

  return (
    <QnaLayout
      breadcrumbs={[
        { title: <I18n t="qna.portal" />, path: "/answer" },
        { title: questions[0]?.project.name },
      ]}
      backButtonLink="/answer"
      downloadBtn={
        <button className="button-qna" onClick={() => downloadWord()}>
          <I18n t="qna.downloadWord" />
        </button>
      }
    >
      <div className="about-content small-width">
        {categories.map((category) => {
          const i = category.categoryCode ? category.categoryCode + ". " : "- ";

          return (
            <div className="category" key={category.id}>
              <h1 className="category-title"> {i + category.label}</h1>

              <div className="question__list">
                {category.questions.map((question) => {
                  const qi = question.questionCode ? question.questionCode : "";
                  const prefix = i + qi;

                  if (question.status === "deleted") {
                    return (
                      <Link
                        key={`answer${question.id}`}
                        className="question__link question questiondeleted"
                        to={"/answer/" + question.id}
                      >
                        <I18n t="qna.question" /> {question.id}:{" "}
                        <I18n t="qna.answerForm.removed" />
                      </Link>
                    );
                  }

                  return (
                    <div className="question" key={question.id}>
                      <Link
                        className="question__link"
                        to={"/answer/" + question.id}
                      >
                        <span>{prefix + " " + question.question}</span>
                        <I18n t="qna.question" /> {question.id}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        {questionsWithoutCategory.length !== 0 && (
          <div className="category no__category">
            <h1>
              <I18n t="qna.questionsNoCategory" />
            </h1>
            {questionsWithoutCategory.map((question) => {
              return (
                <div className="question__list" key={question.id}>
                  <div className="question" key={question.id}>
                    <Link
                      className="question__link"
                      to={"/answer/" + question.id}
                    >
                      {question.id + ". " + question.question}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {questionsWithoutCategory.length === 0 && categories.length === 0 && (
          <div className="no__questions">
            <I18n t="qna.noQuestions" />
          </div>
        )}
      </div>
    </QnaLayout>
  );
}
