import React from "react";
import useFormValidation from "../../useFormValidation";

export default function Number(props) {
  const field = props.field;
  const properties = props.field.properties;
  const validation = useFormValidation(props.active, properties, field.type);

  return (
    <div
      className={`
        text-input 
        ${props.error?.message && "show-error"}
        ${props.alertStatus && props.error === undefined && "show-correct"}
      `}
    >
      <label htmlFor="number">{properties.label?.nl}</label>
      <input
        name={field.id}
        type="text"
        placeholder={properties.placeholder?.nl}
        {...props.register(field.id + "", validation)}
      ></input>
      <span className="form-error type-input">{props.error?.message}</span>
    </div>
  );
}
