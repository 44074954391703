import React from "react";
import Divider from "../divider";
import QnAQuestionBlock from "../../components/qnaquestionblock";
import { useStaleSWR } from "../../utils";
import { useParams } from "react-router-dom";
import Loading from "../../components/loading";
import Error from "../../components/error";
import I18n from "../../i18n";

export default function QnaSearch(props) {
  const { query } = useParams();
  const projectId = props.projectId;
  const { data: category, error } = useStaleSWR(
    `/categories/publishedSearch/${query}?project=${projectId}`,
    false,
    false
  );

  if (!query) {
    return <> </>;
  }

  if (!category) {
    return <Loading />;
  }

  if (error) {
    return <Error block message={error.message} />;
  }

  let hasAnswers = true;
  let numberOfQuestions = 0;
  category.forEach((cat) => {
    if (cat.questions.length !== 0) {
      hasAnswers = false;
    }

    numberOfQuestions += cat.questions.length;
  });

  if (hasAnswers) {
    return (
      <>
        <I18n t="qna.none" />
        <Divider />
      </>
    );
  }

  return (
    <>
      <div className="search-results">
        <h3>
          <I18n t="qna.searchresult" /> ({numberOfQuestions})
        </h3>
        <p>
          <I18n t="qna.results" args={numberOfQuestions} />
        </p>
      </div>

      {category.map((category) => {
        return (
          <div key={category.id}>
            {category.questions.map((question) => {
              return (
                <QnAQuestionBlock
                  key={question.id}
                  question={question}
                  categoryCode={category.categoryCode}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
}
