import React from "react";
import Footer from "../footer";

import Breadcrumbs from "../breadcrumbs";
import NavBar from "../navbar";
import { useLocation } from "react-router-dom";
import { useStaleSWR } from "../../utils";
import I18n from "../../i18n";
import HeaderSDGs from "../HeaderSDGs";
import ProcessBar from "../ProcessBar";
import ShareLinks from "../ShareLinks";

import "./styles.scss";
import PartnerLogo from "../PartnerLogo";

const BaseLayout = ({
  type = "home",
  breadcrumbs = [],
  headerContent = "",
  headerImage = null,
  headerPartner = null,
  headerSDGs,
  headerShare,
  noBreadCrumbs,
  noContainer,
  children,
}) => {
  const { data: blogs } = useStaleSWR("/blog-overview");
  const { data: paginas } = useStaleSWR("/paginas");
  const location = useLocation();
  const blogsPath = I18n.getTranslation(location, "blogs.path");
  const loggedIn = localStorage.getItem("siteboss_client_token");

  return (
    <div className={`page ${type}`}>
      <NavBar
        location={location}
        loggedIn={loggedIn}
        blogs={blogs}
        blogsPath={blogsPath}
        paginas={paginas}
      />
      <div className={noContainer ? "" : "container"}>
        {!noBreadCrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        {headerContent && (
          <div className="header__margin">
            <header className={`header__content__wrapper ${type}`}>
              <section className="header__content">
                <div className="header__content__inner">{headerContent}</div>
                <div className={`header__thumbnail ${type}`}>
                  {headerImage && (
                    <img
                      className="header__thumbnail__image"
                      src={headerImage}
                      alt=""
                    />
                  )}
                  {headerPartner && <PartnerLogo partner={headerPartner} />}
                </div>
              </section>
              {!!headerSDGs?.length && <HeaderSDGs headerSDGs={headerSDGs} />}
              {location.pathname.endsWith("apply") && <ProcessBar />}
            </header>
            {headerShare && (
              <div className="header__share">
                <ShareLinks subject={headerShare} type={type} />
              </div>
            )}
          </div>
        )}
        {children}
      </div>
      <Footer
        location={location}
        loggedIn={loggedIn}
        blogs={blogs}
        blogsPath={blogsPath}
        paginas={paginas}
      />
    </div>
  );
};

export default BaseLayout;
