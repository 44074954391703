import React from "react";
import useStaleSWR from "swr";
import { makeSiteBossURL } from "../../utils";
import Loading from "../../components/loading";
import Error from "../../components/error";

import "./styles.scss";
import I18n from "../../i18n";

export default function AccountUpdateEmail(props) {
  const { data: userData, error } = useStaleSWR(
    makeSiteBossURL(`/account/confirm/email/${props.match.params.emailkey}/`),
    true
  );

  if (error) {
    return <Error message={error.message} />;
  }

  if (!userData) {
    return <Loading />;
  }

  return (
    <div className="header-login">
      <div className="container">
        <a href="/">
          <img src="/images/innovatiepartners_logo.svg" alt="logo" />
        </a>

        <div className="about-content small-width">
          {userData.status === "ok" ? (
            <>
              <h1>
                <I18n t="emailEdited" />
              </h1>
              <p>
                <I18n t="emailEditedSucces" />
              </p>
            </>
          ) : (
            <>
              <h1>
                <I18n t="emailEditedFail" />
              </h1>
              <p>{userData.message}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
