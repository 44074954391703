import React, { useState } from "react";
import I18n from "../../../i18n";
import { useHistory, useRouteMatch } from "react-router-dom";
import Modal from "../../Modal";
import "./styles.scss";

export default function FormSubmitted(props) {
  const [showPopup, setShowPopup] = useState(false);
  let history = useHistory();
  let { url: currentUrl } = useRouteMatch();

  const redirectToReadableForm = () => {
    history.push(
      currentUrl + (currentUrl.slice(-1) === "/" ? "review" : "/review")
    );
  };

  return (
    <div className="info__block">
      <div className="img-right">
        <h1>
          <I18n t="apply.receivedTitle" />
        </h1>
        <img src="/images/success.svg" alt="" id="succesimage" />
      </div>

      {!props.deadlinePassed && (
        <>
          <p>
            <I18n t="apply.receivedText" />
          </p>

          <button
            className="button__apply retract-button"
            onClick={() => redirectToReadableForm()}
          >
            <I18n t="apply.review" />
          </button>
          <button
            onClick={() => setShowPopup(true)}
            className="button-outline button__apply"
          >
            <I18n t="apply.retract" />
          </button>
        </>
      )}

      <Modal open={showPopup} onClose={() => setShowPopup(false)}>
        <h1 className="regular">
          <I18n t="apply.confirmTitle" />
        </h1>
        <p>
          <I18n t="apply.confirmText" />
        </p>
        <p>
          <I18n t="apply.confirmText2" />
        </p>
        <button
          onClick={(e) => props.draftProject(e)}
          className="button__apply button-outline-red retract-button"
        >
          <I18n t="apply.makeChange" />
        </button>
        <button
          onClick={() => setShowPopup(false)}
          className="button-outline button__apply retract-button"
        >
          <I18n t="cancel" />
        </button>
      </Modal>
    </div>
  );
}
