import React from "react";
import { ReactComponent as Logo } from "../../assets/icons/innovatiepartners_logo_icon.svg";

import "./styles.scss";

const Loader = (props) => {
  return (
    <div {...props}>
      <Logo className="loader" />
    </div>
  );
};

export default Loader;
