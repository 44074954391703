import React from "react";
import useFormValidation from "../../useFormValidation";

export default function Text(props) {
  const field = props.field;
  const properties = props.field.properties;
  const validation = useFormValidation(
    props.active,
    properties,
    "default",
    field.id
  );

  return (
    <div
      className={`
        text-input 
        ${props.error?.message && "show-error"}
        ${props.alertStatus && props.error === undefined && "show-correct"}
      `}
    >
      <label>{properties.label?.nl}</label>
      <input
        type="text"
        name={field.id + ""}
        placeholder={properties.placeholder?.nl}
        {...props.register(field.id + "", validation)}
      ></input>

      {props.error?.message && (
        <span className="form-error type-text">{props.error?.message}</span>
      )}
    </div>
  );
}
