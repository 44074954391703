import React from "react";
import { numberOfDaysToDeadline, useStaleSWR } from "../../utils";
import { Link } from "react-router-i18n";
import Divider from "../../components/divider";
import QnaLayout from "../../components/qnalayout";
import Loading from "../../components/loading";
import Error from "../../components/error";
import I18n from "../../i18n";

import "./styles.scss";

export default function QuestionOverview() {
  const { data, error } = useStaleSWR(`/projects/auth`);

  if (error) {
    return <Error message={error.message} />;
  }

  if (!data) {
    return <Loading />;
  }

  return (
    <QnaLayout breadcrumbs={[{ title: <I18n t="qna.portal" /> }]}>
      {/* overview projects  */}
      <div className="container projects-overview-container">
        <div className="about-content small-width">
          <h1>
            <I18n t="qna.portal" />
          </h1>
          <div className="projects-overview">
            {data.map((project) => {
              const { closed, renderDate } = numberOfDaysToDeadline(
                project.deadline,
                true
              );
              return (
                <div className="item" key={project.id}>
                  <h3>{project.name}</h3>
                  <div className="project-List">
                    <div>
                      <Link to={`/categories/${project.id}`}>
                        <div className="answerbox">
                          <div className="answerbox__content">
                            <div className="answerbox__open">
                              {closed ? (
                                <div className="open_answer">
                                  <div className="icon">
                                    <img src="/images/check.svg" alt="vinkje" />
                                  </div>

                                  <div className="text-open">
                                    <h4>
                                      <I18n t="qna.closed" />
                                    </h4>
                                  </div>
                                </div>
                              ) : (
                                <div className="open_answer">
                                  <div className="icon-open"></div>

                                  <div className="text-open">
                                    <h4 className="open">
                                      <I18n t="qna.open" />{" "}
                                    </h4>
                                    <span>
                                      <I18n
                                        t="qna.closeDate"
                                        args={renderDate}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Divider></Divider>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </QnaLayout>
  );
}
