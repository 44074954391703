import React from "react";
import ReactMarkdown from "react-markdown";
import { parseDate } from "../../utils";

import "./styles.scss";

const ProjectDetails = ({ project }) => {
  const setContent = (key) => {
    if (Object.keys(project).includes(key)) {
      if (!project[key]) return null;

      switch (key) {
        case "created_at":
        case "deadline":
        case "deadline2":
        case "published_at":
        case "updated_at":
          return parseDate(project[key]);

        default:
          return project[key];
      }
    }

    return null;
  };

  return (
    <div className="project__details__container">
      {project.details.map((list, index) => (
        <div key={index} className="project__details__list">
          <h3>{list.title}</h3>
          {list.details?.map((item, i) => (
            <div className="project__details__item" key={i}>
              <h5>{item.label}</h5>
              <ReactMarkdown>
                {setContent(item.projectValue) ?? item.value}
              </ReactMarkdown>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ProjectDetails;
