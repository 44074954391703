import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-i18n";
import I18n from "../../i18n";
import { getApiPrefix } from "../../utils";

import "./styles.scss";

const PartnerLogo = ({ partner }) => {
  const location = useLocation();
  const aboutRoute = I18n.getTranslation(location, "aboutBasePath");
  if (!partner?.logo) return null;
  return (
    <Link className="partner__logo" to={`/${aboutRoute}/${partner.slug}/`}>
      <img
        src={`${getApiPrefix()}${partner.logo?.formats?.small?.url}`}
        alt={partner.logo.alternativeText}
      />
    </Link>
  );
};

export default PartnerLogo;
