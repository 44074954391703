import React from "react";
import { getApiPrefix } from "../../utils";

import { useLocation } from "react-router-dom";
import I18n from "../../i18n";
import orderBy from "lodash/orderBy";
import { ReactComponent as PointerLogo } from "./../../assets/icons/arrow_right_blue.svg";

import ExternalLink from "../externallink";
import Popup from "../popup";

import "./styles.scss";

const HeaderSDGs = ({ headerSDGs }) => {
  const location = useLocation();

  return (
    <section className="header__content">
      <div className="header__content__inner sdgs">
        <h4>
          <I18n t="projects.headerSDGs" />
          {" ("}
          <Popup
            role="tooltip"
            on={["hover", "click", "focus"]}
            trigger={
              <div className="popup-trigger">
                <I18n t="projects.headerTriggerSDGs" />
                <img
                  src="/icons/info.svg"
                  alt="download icon"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
              </div>
            }
            position="top center"
            keepTooltipInside
          >
            <h4>
              <I18n t="projects.infoHeaderSDGs" />
            </h4>
            <p>
              <I18n t="projects.infoSDGs" />
            </p>
            <p>
              <ExternalLink
                to={I18n.getTranslation(location, "projects.urlSDGs")}
                className="sdgs__info_link"
              >
                <I18n t="projects.linkSDGs" />
                <PointerLogo
                  style={{ height: 7, marginLeft: ".1rem" }}
                  className="sdgs__info_link_pointer"
                />
              </ExternalLink>
            </p>
          </Popup>
          {"):"}
        </h4>
        <div className="sdgs__container">
          {orderBy(headerSDGs, "Nummer").map((sdg, index) => {
            const thumbnailUrl = sdg.Logo?.url ?? "";

            if (!sdg.Naam) return null;
            return (
              <div key={index} className="sdg">
                <ExternalLink to={sdg.Link ?? ""} className="sdg__link">
                  {thumbnailUrl ? (
                    <img
                      src={`${getApiPrefix()}${thumbnailUrl}`}
                      alt={sdg.icoon?.alternativeText ?? ""}
                      className="sdg__thumbnail"
                    />
                  ) : (
                    <div className="sdg__thumbnail" />
                  )}
                  <div>{`${sdg.Nummer}. ${sdg.Naam}`}</div>
                </ExternalLink>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default HeaderSDGs;
