import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import AccountModal from "../../AccountModal";
import { Link } from "react-router-i18n";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useStaleSWR, useGlobalState, makeSiteBossURL } from "../../../utils";

import Loading from "../../loading";
import Error from "../../error";

import Checkbox from "../fields/checkbox";
import DateComp from "../fields/date";
import Dropdown from "../fields/dropdown";
import Email from "../fields/email";
import File from "../fields/file";
import Header from "../fields/header";
import Info from "../fields/info";
import Number from "../fields/number";
import Radio from "../fields/radio";
import Text from "../fields/text";
import Textarea from "../fields/textarea";
import Toggle from "../fields/toggle";
import LinkComp from "../fields/link";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import useAccordionManager from "./accordionManager";

import I18n from "../../../i18n";
import FormSubmitted from "../submitted";

export default function Apply(props) {
  const prefix = () => {
    const href = window.location.pathname;
    const language = href.substring(1, 3);
    if (language === "en") {
      return "/en/";
    }
    return "/";
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    reset,
    control,
    setValue,
    setFocus,
  } = useForm({
    mode: "onChange",
  });

  const redirectToReadableForm = () => {
    history.push(
      currentUrl + (currentUrl.slice(-1) === "/" ? "review" : "/review")
    );
  };

  const {
    setAccordionOpen,
    accordionDone,
    setAccordionDone,
    isAccordionOpen,
    isAccordionDone,
    getCurrentAccordionOpenIndex,
  } = useAccordionManager();

  const [defaultValues, setDefaultValues] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(true);
  const [defaultValuesWithoutFiles, setDefaultValuesWithoutFiles] = useState(
    []
  );
  let history = useHistory();
  let { url: currentUrl } = useRouteMatch();

  const [percentage, setPercentage] = useGlobalState("percentage");
  const setFormSubmitted = useGlobalState("formSubmitted")[1];

  const {
    data: projectInfo,
    error,
    mutate,
  } = useStaleSWR(
    makeSiteBossURL(`/formdata/${props.type}/${props.queryId}/`),
    true
  );

  // Get current form data if available
  useEffect(() => {
    if (
      projectInfo &&
      projectInfo.application &&
      projectInfo.application.data
    ) {
      const values = projectInfo.application.data;

      let defaultValues = {};
      let defaultValuesWithoutFiles = {};

      // react-form-hook function to fill the form values
      for (let key in values) {
        if (
          key !== "siteboss-page" &&
          key !== "siteboss-progress" &&
          key !== "company" &&
          values[key].type !== "file"
        ) {
          setValue(key, values[key].value);
          defaultValuesWithoutFiles[key] = values[key].value;
        }

        defaultValues[key] = values[key].value;
      }

      reset(defaultValuesWithoutFiles, { keepValues: true });

      setDefaultValues(defaultValues);
      setDefaultValuesWithoutFiles(defaultValuesWithoutFiles);

      if (history.location?.state?.question && openAccordion) {
        if (history.location?.state?.question === "company") {
          setAccordionOpen([0 + "-page"]);
        } else {
          setAccordionOpen([history.location.state.question + "-page"]);
        }
        setOpenAccordion(false);
        scrollDivIntoView(history.location.state.question);
      }

      if (values["siteboss-page"]) {
        setAccordionDone(values["siteboss-page"]);

        if (openAccordion && !history.location?.state?.question) {
          for (let i = 0; i < 100; i++) {
            if (!values["siteboss-page"].includes(i)) {
              setAccordionOpen([i + 1 + "-page"]);
              break;
            }
          }
        }
      }

      if (values["siteboss-progress"]) {
        setPercentage(values["siteboss-progress"]);
      }

      setFormSubmitted(projectInfo.application.status === "PUBLISHED");
    } else {
      if (openAccordion) setAccordionOpen([0 + "-page"]);
    }
  }, [
    history.location,
    openAccordion,
    projectInfo,
    reset,
    setAccordionDone,
    setAccordionOpen,
    setFormSubmitted,
    setPercentage,
    setValue,
  ]);

  if (
    localStorage.getItem("siteboss_client_token") === null ||
    localStorage.getItem("siteboss_client_token") === ""
  ) {
    return <AccountModal> </AccountModal>;
  }

  if (error) {
    return <Error block message={error.message} />;
  }

  if (!projectInfo) {
    return <Loading />;
  }

  if (projectInfo.status === "no-company") {
    let url = prefix();
    if (window.location.pathname.startsWith("/loket")) {
      url += "loket";
    } else {
      url += "project";
    }

    return (
      <div className="info__block">
        <h2>
          <I18n t="account.companyProfile" />
        </h2>
        <p>
          <I18n t="account.companyProfileRequired" />
        </p>
        <Link
          to={`${prefix()}account/company`}
          className="button__apply"
          onClick={() =>
            localStorage.setItem("goToProject", `${url}/${props.slug}/apply`)
          }
        >
          <I18n t="apply.toCompanyProfile" />
        </Link>
      </div>
    );
  }

  const deadlinePassed =
    Math.floor(Date.now() / 1000) > parseInt(projectInfo.project.deadline);

  if (deadlinePassed) {
    return (
      <div className="info__block">
        <h1>
          <I18n t="apply.deadline" />
        </h1>
        <p>
          <I18n t="apply.deadlineText" />
        </p>
        <button
          className="button__apply button-mobile button-right"
          onClick={() => redirectToReadableForm()}
        >
          <I18n t="apply.review" />
        </button>
      </div>
    );
  }

  const formFields = projectInfo.fields;

  if (!formFields) {
    return <div></div>;
  }

  const componentList = {
    checkbox: Checkbox,
    dropdown: Dropdown,
    challenges: Dropdown,
    date: DateComp,
    email: Email,
    file: File,
    header: Header,
    info: Info,
    number: Number,
    radio: Radio,
    text: Text,
    textarea: Textarea,
    toggle: Toggle,
    link: LinkComp,
    price: File,
  };

  const onSubmit = (data, submitEvent) => {
    var formData = new FormData();
    for (var key in data) {
      if (data[key] instanceof Date) {
        const formattedDate = data[key].toISOString().substring(0, 10);
        formData.append(key, formattedDate);

        continue;
      }

      if (data[key] === null || data[key] === undefined) {
        formData.append(key, "");

        continue;
      }

      if (data[key] !== null && typeof data[key] === "object") {
        for (var i = 0; i < data[key].length; i++) {
          formData.append(key + "[]", data[key][i]);
        }
        continue;
      }

      formData.append(key, data[key]);
    }

    // set the appropriate combinations as done
    let currentIndexOpen = getCurrentAccordionOpenIndex();
    let tmpAccordionDone = [...accordionDone];
    if (!accordionDone.includes(currentIndexOpen)) {
      tmpAccordionDone.push(currentIndexOpen);

      formData.append("siteboss-page", tmpAccordionDone);
    }

    // Set percentage
    let percentage = (tmpAccordionDone.length / formFields.length) * 100;
    if (percentage === undefined) percentage = 0;
    if (percentage > 100) percentage = 100;
    percentage = Math.floor(percentage);
    formData.append("siteboss-progress", percentage);

    // If it is complete
    if (currentIndexOpen === formFields.length - 1) {
      formData.append("siteboss-done", true);
    }

    axios.defaults.headers.post[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("siteboss_client_token")}`;

    axios
      .post(
        makeSiteBossURL(
          `/formdata/${projectInfo.project.form_id}/page/${submitEvent.target.dataset.id}/`
        ),
        formData,
        {
          contentType: false,
          processData: false,
        }
      )
      .then((response) => {
        if (response.data.status === "ok") {
          // resest fields so that they aren't dirty.
          reset();

          // open the next accordion
          setAccordionOpen([currentIndexOpen + 2 + "-page"]);
          setAccordionDone(tmpAccordionDone);
          scrollDivIntoView(currentIndexOpen + 2);

          //Get SWR again
          mutate();

          setOpenAccordion(false);
        } else {
          alert(I18n.getTranslation(window.location, "somethingWrong"));
        }
      })
      .catch((error) => {
        alert(I18n.getTranslation(window.location, "somethingWrong"));
        console.log("error", error);
      });
  };

  const scrollDivIntoView = (id, asString = false) => {
    let el;
    if (asString) {
      el = document.getElementById(id + "");
    } else {
      el = document.getElementById(id + "-page");
    }

    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  };

  const draftProject = (e) => {
    e.preventDefault();

    axios.defaults.headers.post[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("siteboss_client_token")}`;

    axios
      .post(makeSiteBossURL(`/formdata/${props.type}/${props.queryId}/draft/`))
      .then((response) => {
        if (response.data.status === "ok") {
          mutate();
        } else {
          alert(I18n.getTranslation(window.location, "somethingWrong"));
        }
      })
      .catch((error) => {
        alert(I18n.getTranslation(window.location, "somethingWrong"));
        console.log("error" + error);
      });
  };

  const publishLoket = () => {
    axios.defaults.headers.post[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("siteboss_client_token")}`;

    axios
      .post(makeSiteBossURL(`/formdata/loket/${props.queryId}/published/`))
      .then((response) => {
        if (response.data.status === "ok") {
          mutate();
        } else {
          alert(I18n.getTranslation(window.location, "somethingWrong"));
        }
      })
      .catch((error) => {
        alert(I18n.getTranslation(window.location, "somethingWrong"));
        console.log("error" + error);
      });
  };

  const clickAccordion = (arrayIdWithPageAsString) => {
    if (!isDirty) {
      setAccordionOpen(arrayIdWithPageAsString);
      setTimeout(() => {
        scrollDivIntoView(arrayIdWithPageAsString[0], true);
      });
      return;
    }

    if (
      window.confirm(
        I18n.getTranslation(window.location, "apply.leaveWithoutSaveWarning")
      )
    ) {
      reset(defaultValuesWithoutFiles);
      setAccordionOpen(arrayIdWithPageAsString);
      scrollDivIntoView(arrayIdWithPageAsString);
    } else {
      setFocus(Object.keys(dirtyFields)[0]);
    }
  };

  if (projectInfo.application?.status === "PUBLISHED") {
    return (
      <FormSubmitted
        deadlinePassed={deadlinePassed}
        draftProject={draftProject}
      />
    );
  }

  return (
    <>
      {/* for "inschrijven" timeline part introduction header  */}
      <div className="signin-accordion account__apply">
        <div className="signin-timeline-title">
          <h2>
            <I18n t="inschrijven" />
          </h2>
          <p>
            <I18n t="apply.answerQuestions" />
          </p>
        </div>

        <Accordion
          allowMultipleExpanded={false}
          allowZeroExpanded
          onChange={(keys) => clickAccordion(keys)}
        >
          <div className="timeline timeline-signin">
            <AccordionItem
              className={`timeline__item ${isAccordionOpen(0) ? "open" : ""}`}
              uuid={0 + "-page"}
              key={0 + "-page"}
              dangerouslySetExpanded={isAccordionOpen(0)}
            >
              <AccordionItemHeading className="accordion__heading">
                <AccordionItemButton
                  className={`timeline__item_button bullet-checked`}
                >
                  <div className="timeline__item_bullet ">
                    <span>{1}</span>
                  </div>
                  <div className="timeline__item_title on_line_title">
                    <h3>
                      <I18n t="account.companyProfile" />
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              {isAccordionOpen(0) && (
                <AccordionItemPanel className="timeline__item_content">
                  <p>
                    <I18n t="apply.viewCompanyProfile" />
                  </p>
                  <Link
                    to={`${prefix()}account/company`}
                    className=""
                    onClick={() =>
                      localStorage.setItem(
                        "goToProject",
                        `${prefix()}${props.type}/${props.slug}/apply`
                      )
                    }
                  >
                    <I18n t="apply.toCompanyProfile" />
                  </Link>
                  <div className="buttons btn-inside-itemPanel">
                    <button
                      className="button__apply button-mobile button-left"
                      onClick={() => setAccordionOpen([1 + "-page"])}
                    >
                      <I18n t="apply.saveAndNext" />
                    </button>
                  </div>
                </AccordionItemPanel>
              )}
            </AccordionItem>
            {formFields.map((combination, index) => {
              let lIndex = index + 1;
              return (
                <AccordionItem
                  className={`timeline__item ${
                    isAccordionOpen(lIndex) ? "open" : ""
                  }`}
                  uuid={lIndex + "-page"}
                  key={lIndex + "-page"}
                  dangerouslySetExpanded={isAccordionOpen(lIndex)}
                >
                  <AccordionItemHeading className="accordion__heading">
                    <AccordionItemButton
                      className={`timeline__item_button ${
                        isAccordionDone(lIndex) ? "bullet-checked" : ""
                      }`}
                    >
                      <div
                        className="timeline__item_bullet "
                        id={lIndex + "-page"}
                      >
                        <span>{lIndex + 1}</span>
                      </div>
                      <div className="timeline__item_title on_line_title">
                        <h3> {combination.properties.label.nl}</h3>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  {isAccordionOpen(lIndex) && (
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      data-id={combination.id}
                      noValidate
                    >
                      <AccordionItemPanel className="timeline__item_content">
                        {combination.fields.map((field) => {
                          const name = field.type.toLowerCase();
                          const Type = componentList[name];
                          return (
                            <Type
                              key={field.id}
                              field={field}
                              register={register}
                              active={isAccordionOpen(lIndex)}
                              error={errors[field.id]}
                              value={defaultValues[field.id]}
                              setValue={setValue}
                              alertStatus={dirtyFields[field.id]}
                              control={control}
                            />
                          );
                        })}

                        <div className="buttons btn-inside-itemPanel">
                          <button
                            className="button__apply button-mobile button-left"
                            type="submit"
                          >
                            <I18n t="apply.saveAndNext" />
                          </button>
                        </div>
                      </AccordionItemPanel>
                    </form>
                  )}
                </AccordionItem>
              );
            })}

            <AccordionItem
              className={`timeline__item ${
                isAccordionOpen(formFields.length + 1) ? "open" : ""
              }`}
              uuid={formFields.length + 1 + "-page"}
              key={formFields.length + 1 + "-page"}
              dangerouslySetExpanded={isAccordionOpen(formFields.length + 1)}
            >
              <AccordionItemHeading className="accordion__heading">
                <AccordionItemButton className={`timeline__item_button `}>
                  <div className="timeline__item_bullet ">
                    <span>{formFields.length + 2}</span>
                  </div>
                  <div className="timeline__item_title on_line_title">
                    <h3>
                      <I18n t="apply.checkAndApply" />
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              {isAccordionOpen(formFields.length + 1) && (
                <AccordionItemPanel className="timeline__item_content">
                  {percentage >= 100 ? (
                    <>
                      {props.type === "loket" ? (
                        <>
                          <I18n t="apply.loketSubmit" />

                          <div className="buttons btn-inside-itemPanel">
                            <button
                              className="button__apply button-mobile button-right"
                              onClick={() => publishLoket()}
                            >
                              <I18n t="apply.loketSubmitButton" />
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <I18n t="apply.readyToSubmit" />

                          <div className="buttons btn-inside-itemPanel">
                            <button
                              className="button__apply button-mobile button-right"
                              onClick={() => redirectToReadableForm()}
                            >
                              <I18n t="apply.checkAndApply" />
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <I18n t="apply.applicationIncomplete" />
                  )}
                </AccordionItemPanel>
              )}
            </AccordionItem>
          </div>
        </Accordion>
      </div>
    </>
  );
}
