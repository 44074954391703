import React from "react";
import useFormValidation from "../../useFormValidation";

import { Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import "react-datepicker/dist/react-datepicker.css";

export default function DateComp(props) {
  const field = props.field;
  const properties = props.field.properties;
  registerLocale("nl", nl);

  const validation = useFormValidation(props.active, properties);

  return (
    <>
      <div
        className={`
          date-picker 
          ${props.error?.message && "show-error"}
          ${props.alertStatus && props.error === undefined && "show-correct"}
        `}
      >
        <label htmlFor="datum">{properties.label.nl}</label>

        <Controller
          control={props.control}
          // Name must be a string for Controller to work
          name={field.id + ""}
          render={({ field }) => (
            <DatePicker
              locale="nl"
              dateFormat="dd-MM-yyyy"
              selected={field.value ? new Date(field.value) : ""}
              onChange={(e) => field.onChange(e)}
              isClearable={!!field.value}
              shouldCloseOnSelect
            />
          )}
          rules={validation}
        />

        <div>
          <span className="form-error">{props.error?.message}</span>
        </div>
      </div>
    </>
  );
}
