import React from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import useFormValidation from "../../useFormValidation";
import I18n from "../../../../i18n";

// overwrite default inline styling for dev/staging
import { ipStyles, ipTheme } from "./styles";
// scss styling for production (no inline csp)
import "./styles.scss";

export default function MultipleChoice({
  field,
  active,
  register,
  error,
  alertStatus,
  getValues,
  setValue,
}) {
  const location = useLocation();
  const properties = field.properties;
  const items = properties.items;
  const validation = useFormValidation(active, properties);

  const options = items.map((item) => ({ ...item, value: item.id }));
  const defaultOptions = getValues(field.id + "")?.map((id) =>
    options.find((item) => item.id === id)
  );

  const placeholderMessage = I18n.getTranslation(
    location,
    "forms.placeholderMultiplechoice"
  );
  const noOptionsMessage = I18n.getTranslation(location, "forms.noOptions");

  return (
    <div
      className={`
        multichoice
        ${error?.message && "show-error"}
        ${alertStatus && error === undefined && "show-correct"}
      `}
    >
      <div>
        <div>
          <label>{properties.label?.nl}</label>
          <Select
            styles={ipStyles}
            theme={ipTheme}
            placeholder={placeholderMessage}
            noOptionsMessage={() => noOptionsMessage}
            isMulti
            options={options}
            defaultValue={defaultOptions}
            // DO NOT REMOVE: add key to trigger default value update
            key={defaultOptions + Math.floor(Math.random() * 10000)}
            {...register(field.id + "", validation)}
            // overwrite default onChange from register to use options and send id's
            onChange={(currentOptions) =>
              setValue(
                field.id + "",
                currentOptions.map((item) => item.id)
              )
            }
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <p
        className="form-error"
        style={{ marginBottom: "20px", position: "relative" }}
      >
        {error?.message}
      </p>
    </div>
  );
}
