import React, { useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-i18n";
import I18n from "../../i18n";
import { ReactComponent as AccountIcon } from "../../assets/icons/account.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down_blue.svg";
//import LocaleLink from "../LocaleLink";
import "./styles.scss";

const NavBar = ({ location, loggedIn, blogs, blogsPath, paginas }) => {
  const history = useHistory();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const participatePath = I18n.getTranslation(location, "participatePath");

  const mainInfoPage = paginas?.find((page) => page.mainMenu === true);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuExpanded(!menuExpanded);
  };

  const logout = () => {
    localStorage.removeItem("siteboss_client_token");
    history.push("/");
  };

  return (
    <header
      className={classNames("navbar__wrapper", {
        "menu-expanded": menuExpanded,
      })}
    >
      <div className="container">
        <nav
          aria-label="Main navigation"
          role="navigation"
          className="navbar__logos"
        >
          <NavLink className="logo" to="/">
            <img src="/images/innovatiepartners_logo.svg" alt="Site Logo" />
          </NavLink>
          <NavLink className="logo-right" to="/">
            <p>
              <I18n t="menu.initiativeOf" />
            </p>
            <img src="/images/amsterdam_logo_header.svg" alt="Amsterdam Logo" />
          </NavLink>
        </nav>
        <button
          type="button"
          onClick={toggleMenu}
          className="navbar__mobile_toggle"
        >
          <div className="toggle_icon">
            <span />
            <span />
            <span />
          </div>
          <I18n t={menuExpanded ? "menu.close" : "menu.expand"} />
        </button>
        {/* section with menu list items for all displays  */}
        <nav
          aria-label="Main navigation"
          className="navbar__menu"
          role="navigation"
        >
          <div className="navbar__items">
            <ul className="navbar__list">
              {!loggedIn ? (
                <li>
                  <NavLink to="/account">
                    <div>
                      <AccountIcon /> <I18n t="login.title" />
                    </div>
                  </NavLink>
                </li>
              ) : (
                <li className="navbar__submenu_item">
                  <NavLink to="/account">
                    <div>
                      <AccountIcon />
                      <span className="up-to-desktop">
                        <I18n t="yourProfile" />
                      </span>
                    </div>
                    <ArrowDown />
                  </NavLink>
                  <div className="navbar__submenu">
                    <NavLink to="/account">
                      <I18n t="menu.dashboard" />
                    </NavLink>
                    <a href="/" onClick={() => logout()}>
                      <I18n t="menu.logout" />
                    </a>
                  </div>
                </li>
              )}
            </ul>
            <ul className="navbar__list">
              <li>
                <NavLink to="/">
                  <I18n t="home" />
                </NavLink>
              </li>
              <li className="navbar__submenu_item">
                <NavLink to={`/${participatePath}`}>
                  <I18n t="menu.participate" /> <ArrowDown />
                </NavLink>
                <div className="navbar__submenu">
                  <NavLink to={`/${participatePath}#project`}>
                    <I18n t="projects.projects" />
                  </NavLink>
                  <NavLink to={`/${participatePath}#loket`}>
                    <I18n t="menu.lokets" />
                  </NavLink>
                </div>
              </li>
              {!!blogs && (
                <li>
                  <NavLink to={"/" + blogsPath}>{blogs.title}</NavLink>
                </li>
              )}
              <li className="navbar__submenu_item">
                <NavLink to={mainInfoPage ? "/info/" + mainInfoPage.slug : "/"}>
                  <I18n t="menu.procedures" /> <ArrowDown />
                </NavLink>
                <div className="navbar__submenu">
                  {paginas &&
                    paginas
                      .filter((page) => page.subMenu === true)
                      .map((page) => (
                        <NavLink
                          key={page.slug}
                          to={{
                            pathname: "/info/" + page.slug,
                            state: page.procedure_block && {
                              mainLink: "/info/" + mainInfoPage.slug,
                            },
                          }}
                        >
                          {page.title}
                        </NavLink>
                      ))}
                </div>
              </li>
            </ul>
          </div>
          {/* <ul className="navbar__list">
            <li className="navbar__language">
              <LocaleLink location={location} />
            </li>
          </ul> */}
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
