import React from "react";
import BaseLayout from "../../components/baselayout";
import { getApiPrefix, useStaleSWR } from "../../utils";
import Error from "../../components/error";
import Loader from "../../components/Loader";
import ReactMarkdown from "react-markdown";

import "./styles.scss";
import ProjectList from "../../components/projectlist";
import GridRow from "../../components/gridrow";

const Participate = ({ location }) => {
  const { data: page, error } = useStaleSWR("/participate");
  const [doneLoading, setDoneLoading] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(false);

  // scroll into view once if path has hash && list with full data is present on page
  const hash = location.hash;
  React.useEffect(() => {
    if (!scrolled && hash && doneLoading) {
      const element = document.getElementById(hash.replace("#", ""));

      if (!!element) {
        element.scrollIntoView({ behavior: "smooth" });
        setScrolled(true);
      }
    } else if (!hash) {
      setScrolled(true);
    }
  }, [scrolled, hash, doneLoading]);

  // scroll on hash change
  React.useEffect(() => {
    const element = document.getElementById(hash.replace("#", ""));
    if (!!element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  // first three items of title list will have the icons from home as the default
  // (can be overwritten from strapi)
  const defaultTitleIcons = [
    "/icons/budget_yellow.svg",
    "/icons/location-purple.svg",
    "/icons/solution-blue.svg",
  ];

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <BaseLayout type="page" breadcrumbs={[{ title: page?.title }]}>
      <header>
        <section className="small-width">
          {!page && <Loader />}
          <div className="participate__header">
            <div className="participate__header__intro">
              <ReactMarkdown>{page?.titleDescription}</ReactMarkdown>
            </div>
            {page?.titleIcons?.length > 0 && (
              <ul>
                {page.titleIcons.map((item, index) => {
                  const iconUrl = item.icon
                    ? getApiPrefix() + item.icon.url
                    : defaultTitleIcons[index]
                    ? defaultTitleIcons[index]
                    : null;

                  return (
                    <li
                      key={item.id}
                      style={{
                        backgroundImage: iconUrl
                          ? `url(${iconUrl})`
                          : undefined,
                      }}
                    >
                      {item.text}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </section>

        {doneLoading && <div id="project" />}
        <GridRow colorBlock="purple" type="full">
          <GridRow>
            <div className="block">
              <ReactMarkdown>{page?.projectListIntroduction}</ReactMarkdown>
            </div>
            <ProjectList
              onlyProjects
              underConstructionText={page?.underConstructionCard}
              noProjectsText={page?.noProjectListCard}
              setDoneLoading={setDoneLoading}
            />
          </GridRow>
        </GridRow>

        {doneLoading && <div id="loket" />}
        <GridRow colorBlock="pink" type="full">
          <GridRow>
            <div className="block">
              <ReactMarkdown>{page?.loketListIntroduction}</ReactMarkdown>
            </div>
            <ProjectList onlyLokets noProjectsText={page?.noLoketListCard} />
          </GridRow>
        </GridRow>

        <GridRow colorBlock="green" type="full">
          <GridRow>
            <div className="block">
              <ReactMarkdown>{page?.inactiveListIntroduction}</ReactMarkdown>
            </div>
            <ProjectList
              onlyInactiveProjects
              noProjectsText={page?.noInactiveListCard}
              collapsed
              collapsedText={page?.inactiveListExpandButton}
            />
          </GridRow>
        </GridRow>
      </header>
    </BaseLayout>
  );
};

export default Participate;
