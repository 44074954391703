import React from "react";
import { percentageText, useGlobalState } from "../../utils";

import "./styles.scss";

const ProcessBar = ({ progress, submitted }) => {
  const progressPercentageState = useGlobalState("percentage")[0];
  const formSubmittedState = useGlobalState("formSubmitted")[0];

  let progressPercentage, formSubmitted;
  if (progress) {
    progressPercentage = progress;
  } else {
    progressPercentage = progressPercentageState;
  }

  if (submitted) {
    formSubmitted = submitted;
  } else {
    formSubmitted = formSubmittedState;
  }

  // Procentage design changed midway, and I don't want to refactor everything
  // So this is the solution.
  // if the form is 100 % filled in but not submitted it isn't done yet,
  // so set to 95%. That procentage is only used for frontend display
  let actualPercentage = progressPercentage;
  if (actualPercentage >= 100 && !formSubmitted) {
    actualPercentage = 95;
  }

  return (
    <div className="process-bar">
      <div className="completed" style={{ width: actualPercentage + "%" }} />
      <p>{percentageText(progressPercentage, formSubmitted)}</p>
    </div>
  );
};

export default ProcessBar;
