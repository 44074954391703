import React from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import I18n from "../../i18n";

import "./styles.scss";
import { makeSiteBossURL } from "../../utils";

const NewsletterForm = ({ lijstId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [includeGeneral, setIncludeGeneral] = React.useState(true);
  const [submit, setSubmit] = React.useState("");
  const location = useLocation();
  const nameLabel = I18n.getTranslation(location, "newsletter.name");
  const emailLabel = I18n.getTranslation(location, "newsletter.email");
  const addGeneralLabel = I18n.getTranslation(
    location,
    "newsletter.addGeneral"
  );
  const succesMessage = I18n.getTranslation(
    location,
    "newsletter.submitSucces"
  );
  const errorMessage = I18n.getTranslation(location, "somethingWrong");

  const onSubmit = (data) => {
    const defaultList = "general";
    let lists = lijstId ?? defaultList;

    // opt-in to the default list as well when signing up for a given list
    if (lijstId && includeGeneral) lists = [lijstId, defaultList].join(",");

    axios
      .post(
        makeSiteBossURL(
          `/user/newsletter/?list=${lists}&email=${data.email}&name=${data.name}`
        )
      )
      .then((res) => {
        if (res.status <= 300) {
          setSubmit(succesMessage);
          reset();
        }
      })
      .catch(() => {
        // TODO handle errors based on Laposta responses
        setSubmit(errorMessage);
      });
  };

  React.useEffect(() => {
    const delayedSubmitReset = () =>
      setTimeout(() => {
        setSubmit("");
      }, 8000);

    if (submit !== "") {
      delayedSubmitReset();
    }
    return () => clearTimeout(delayedSubmitReset);
  }, [submit, setSubmit]);

  return (
    <form className="newsletter__form" onSubmit={handleSubmit(onSubmit)}>
      {submit && (
        <div
          className={`form-result ${
            submit === succesMessage ? "form-succes" : "form-error"
          }`}
        >
          {submit}
        </div>
      )}
      <div className="form-group">
        <label htmlFor="email">{emailLabel}*</label>
        <input
          id="email"
          className="form-control"
          placeholder={emailLabel}
          {...register("email", {
            required: true,
            pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, // e-mail address regex
          })}
        />
        {errors.email && (
          <span className="form-error">
            <I18n t="account.edit.correctEmailText" />
          </span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="name">{nameLabel}*</label>
        <input
          id="name"
          className="form-control"
          placeholder={nameLabel}
          {...register("name", { required: true })}
        />{" "}
        {errors.name && (
          <span className="form-error">
            <I18n t="qna.form.required" />
          </span>
        )}
      </div>
      {!!lijstId && (
        <div className="form-group">
          <div className="checkbox__wrapper">
            <label className="checkbox__container">
              <input
                type="checkbox"
                name="addGeneral"
                value={includeGeneral}
                checked={includeGeneral}
                onChange={() => setIncludeGeneral(!includeGeneral)}
              />
              <span className="checkmark" />
              <p>{addGeneralLabel}</p>
            </label>
          </div>
        </div>
      )}
      <button type="submit" className="login-button submit">
        <I18n t="newsletter.submit" />
      </button>
    </form>
  );
};

export default NewsletterForm;
