import React from "react";
import I18n from "../../i18n";
import "./style.scss";

export default function Loading() {
  return (
    <div id="messageContainer">
      <h1>
        <I18n t="loading.moment" />
      </h1>

      <p>
        <I18n t="loading.loading" />
      </p>
    </div>
  );
}
