import axios from "axios";
import useSWR, { cache } from "swr";
import { getApiPrefix, getCurrentLanguage } from "./settingUtils";

const fetcher = async (...args) => {
  const header = {};

  if (args[2]) {
    header.headers = {
      "Sb-Authorization": `Bearer ${args[1]}`,
    };
  } else if (args[1]) {
    header.headers = {
      Authorization: `Bearer ${args[1]}`,
    };
  }

  return axios.get(args[0], header).then((res) => {
    return res.data;
  });
};

const getApiSuffix = (url, useLocale) => {
  if (process.env.REACT_APP_BACKEND !== "site") return url;
  let prefix = "?";
  if (url.indexOf("?") !== -1) prefix = "&";

  let locale = ``;
  if (useLocale && !url.includes("_locale=")) {
    locale = `${prefix}_locale=` + getCurrentLanguage();
  }

  return `${url}${locale}`;
};

const getDataKeyWithArgs = (
  dataKey,
  siteboss = false,
  useLocale = true,
  altName = false
) => {
  if (siteboss) {
    return [dataKey, localStorage.getItem("siteboss_client_token"), altName];
  } else {
    let newDataKey = getApiPrefix() + getApiSuffix(dataKey, useLocale);
    return [newDataKey, localStorage.getItem("token")];
  }
};

const useStaleSWR = (
  dataKey,
  siteboss = false,
  useLocale = true,
  altName = false
) => {
  let newDataKeyArray = getDataKeyWithArgs(
    dataKey,
    siteboss,
    useLocale,
    altName
  );

  const revalidationOptions = {
    revalidateOnMount: !cache.has(newDataKeyArray[0]),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  const { data, mutate, error } = useSWR(
    newDataKeyArray,
    fetcher,
    revalidationOptions
  );
  return { data, mutate, error };
};

const useNormalSWR = (dataKey, siteboss = false, useLocale = true) => {
  let newDataKeyArray = getDataKeyWithArgs(dataKey, siteboss, useLocale);

  const { data, mutate, error } = useSWR(newDataKeyArray, fetcher);
  return { data, mutate, error };
};

export { useStaleSWR, useNormalSWR };
