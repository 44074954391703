import React from "react";
import "./styles.scss";
import I18n from "../../i18n";
import { numberOfDaysToDeadline } from "../../utils";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";
import Modal from "../Modal";
import { useLocation } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const DeadlineBox = ({ project = {}, title, overwrite = "" }) => {
  const location = useLocation();
  if (!project) return null;
  const { closed, difference, renderDate, deadlineDate } =
    numberOfDaysToDeadline(project.deadline, true);
  const renderDays = difference.seconds <= 0 ? 0 : difference.days;
  const deadlineDescription = title ?? project.name ?? "";

  // setup add to calendar modal
  const formattedDate = (date) =>
    `${date.toFormat("yyyyLLdd")}T${date.toFormat("HHmmss")}`;
  const calendarEvent = {
    title: I18n.getTranslation(location, "calendar.eventTitle", {
      project: project.name,
    }),
    description: I18n.getTranslation(location, "calendar.eventDescription", {
      project: project.name,
    }),
    duration: 1,
    location: `https://innovatiepartners.nl${project.url}`,
    endDatetime: formattedDate(deadlineDate),
    startDatetime: formattedDate(deadlineDate.minus({ hours: 1 })),
  };
  const CalendarTrigger = (props) => (
    <a href="/" className="deadlinebox__text_link" {...props}>
      <I18n t="calendar.add" />
    </a>
  );
  const CalendarModal = ({ children, ...props }) => (
    <Modal open {...props}>
      <h2>
        <I18n t="calendar.add" />
      </h2>
      {children}
    </Modal>
  );
  const AddToCalendarModal = AddToCalendarHOC(CalendarTrigger, CalendarModal);

  return (
    <div className="deadlinebox">
      <div className="deadlinebox__content">
        {overwrite ? (
          <div className="deadlinebox__text">
            <p>{overwrite}</p>
          </div>
        ) : (
          <>
            <div className="deadlinebox__text">
              <div className="deadline__discription">
                <h4>{deadlineDescription}:</h4>
                {!closed && (
                  <span className="deadlinebox__date">{renderDate}</span>
                )}
              </div>

              {closed ? (
                <I18n t="timeLeft" args={{ days: -1 }} />
              ) : (
                <AddToCalendarModal
                  linkProps={{
                    target: "_blank",
                    rel: "noopener noreferrer",
                  }}
                  event={calendarEvent}
                  filename="deadline"
                  items={[
                    SHARE_SITES.GOOGLE,
                    SHARE_SITES.ICAL,
                    SHARE_SITES.OUTLOOK,
                  ]}
                />
              )}
            </div>
            <div className="deadlinebox__countdown">
              <span>
                <I18n t="togo" />
              </span>
              <span>{renderDays}</span>
              <span>
                <I18n t="days" args={renderDays} />
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeadlineBox;
