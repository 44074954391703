import React from "react";
import useFormValidation from "../../useFormValidation";
import I18n from "../../../../i18n";
import { useLocation } from "react-router-dom";

export default function Dropdown(props) {
  const location = useLocation();
  const field = props.field;
  const properties = props.field.properties;
  const validation = useFormValidation(props.active, properties);

  const options = properties.options.list.filter((option) => !option.deleted);

  return (
    <div
      className={`
        dropdown 
        ${props.error?.message && "show-error"}
        ${props.alertStatus && props.error === undefined && "show-correct"}
      `}
    >
      <label>{properties.label?.nl}</label>

      <div className="dropdown-select-wrapper">
        <select name={field.id} {...props.register(field.id + "", validation)}>
          <option className="custom-option" value="">
            {I18n.getTranslation(location, "forms.choose")}
          </option>
          {options.map((option) => (
            <option
              className="custom-option"
              value={option.index}
              key={option.index}
            >
              {option.option.nl}
            </option>
          ))}
        </select>
      </div>
      <p
        className="form-error"
        style={{ marginBottom: "20px", position: "relative" }}
      >
        {props.error?.message}
      </p>
    </div>
  );
}
