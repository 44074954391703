// styling object instead of scss as quickest option to overwrite styling of react-select
// https://react-select.com/style

// ripped from styles/shared/colors.scss
const primaryBlue = "#2f196b";
const primaryLight = "#78d8e5";
const primaryPurple = "#d8bdee";
const greyLight = "#eeebeb";
const white = "#fff";

export const ipStyles = {
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: primaryBlue,
    color: white,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: white,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: primaryBlue,
    opacity: 0.5,
  }),
  input: (provided) => ({
    ...provided,
    color: primaryBlue,
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? primaryLight : primaryBlue,
    boxShadow: state.isFocused ? primaryLight : "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: greyLight,
    margin: 0,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: primaryBlue,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: primaryBlue,
  }),
};

export const ipTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  spacing: {
    ...theme.spacing,
    baseUnit: 6,
  },
  colors: {
    ...theme.colors,
    primary: primaryBlue,
    primary25: primaryPurple,
    primary50: primaryPurple,
    danger: primaryBlue,
    dangerLight: primaryPurple,
    neutral80: primaryBlue,
  },
});
