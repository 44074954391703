import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import AccountModal from "../../AccountModal";
import Loading from "../../loading";
import Error from "../../error";

import { useStaleSWR, useGlobalState, makeSiteBossURL } from "../../../utils";
import axios from "axios";

import Checkbox from "../previewfields/checkbox";
import DateComp from "../previewfields/date";
import Dropdown from "../previewfields/dropdown";
import Email from "../previewfields/email";
import File from "../previewfields/file";
import Header from "../previewfields/header";
import Info from "../previewfields/info";
import Number from "../previewfields/number";
import Radio from "../previewfields/radio";
import Text from "../previewfields/text";
import Textarea from "../previewfields/textarea";
import Toggle from "../previewfields/toggle";
import Link from "../previewfields/link";
import Thema from "../previewfields/multiplechoice";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "./styles.scss";

import { useHistory } from "react-router-dom";
import I18n from "../../../i18n";

export default function Review(props) {
  const history = useHistory();
  const { register, setValue, control } = useForm({
    mode: "onChange",
  });

  // Shows the help modal
  const [defaultValues, setDefaultValues] = useState([]);
  const [companyValues, setCompanyValues] = useState([]);
  const [accordionDone, setAccordionDone] = useState([]);
  const [loading, setLoading] = useState(false);

  const setProgressBarPercentage = useGlobalState("percentage")[1];

  const [percentage, setPercentage] = useState(0);
  const { data: companyData, errorCompany } = useStaleSWR(
    makeSiteBossURL("/user/company-profile/"),
    true
  );

  // the name projectInfo is a lie, but legacy
  const { data: projectInfo, error } = useStaleSWR(
    makeSiteBossURL(`/formdata/${props.type}/${props.queryId}/`),
    true
  );

  // Get current form data if available
  useEffect(() => {
    if (projectInfo && companyData) {
      let defaultValues = {};
      let profileValues = {};
      // Load form data
      if (projectInfo.application && projectInfo.application.data) {
        const values = projectInfo.application.data;

        // react-form-hook function to fill the form values
        for (let key in values) {
          if (key !== "siteboss-page" && values[key].type !== "file") {
            setValue(key, values[key].value);
          }
          defaultValues[key] = values[key].value;

          if (values["siteboss-progress"]) {
            setPercentage(values["siteboss-progress"]);
            setProgressBarPercentage(values["siteboss-progress"]);
          }

          if (values["siteboss-page"]) {
            setAccordionDone(values["siteboss-page"]);
          }
        }

        // Show the correct version of the company profile
        if (projectInfo.application.status === "PUBLISHED") {
          profileValues = projectInfo.application.company_profile;
        } else {
          const companyDataObject = companyData.data.data;
          for (let key in companyDataObject) {
            profileValues[key] = companyDataObject[key].value;
          }
        }
        setCompanyValues(profileValues);
        setDefaultValues(defaultValues);
      }
    }
  }, [projectInfo, companyData, setProgressBarPercentage, setValue]);

  if (
    localStorage.getItem("siteboss_client_token") === null ||
    localStorage.getItem("siteboss_client_token") === ""
  ) {
    return <AccountModal> </AccountModal>;
  }

  if (error) {
    return <Error block message={error.message} />;
  }

  if (errorCompany) {
    return <Error block message={errorCompany.message} />;
  }

  if (!projectInfo) {
    return <Loading />;
  }

  if (!companyData) {
    return <Loading />;
  }

  const formFields = projectInfo.fields;
  if (!formFields) {
    return (
      <div>
        <I18n t="forms.no" />
      </div>
    );
  }

  const deadlinePassed =
    Math.floor(Date.now() / 1000) > parseInt(projectInfo.project.deadline);

  const confirmProject = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.defaults.headers.post[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("siteboss_client_token")}`;

    axios
      .post(
        makeSiteBossURL(`/formdata/${props.type}/${props.queryId}/published/`)
      )
      .then((response) => {
        if (response.data.status === "ok") {
          history.push("../apply");
        } else {
          alert(I18n.getTranslation(window.location, "somethingWrong"));
        }
        setLoading(false);
      })
      .catch((error) => {
        alert(I18n.getTranslation(window.location, "somethingWrong"));
        console.log("error", error);
        setLoading(false);
      });
  };

  const components = {
    checkbox: Checkbox,
    dropdown: Dropdown,
    challenges: Dropdown,
    date: DateComp,
    email: Email,
    file: File,
    header: Header,
    info: Info,
    number: Number,
    radio: Radio,
    text: Text,
    textarea: Textarea,
    toggle: Toggle,
    link: Link,
    price: File,
    thema: Thema,
  };

  const redirectBackToQuestion = (index) => {
    history.push("../apply", { question: index });
  };

  if (loading) {
    return (
      <>
        <img id="sendloader" src="/images/loading.png" alt="Loading" />
        <h1>
          <I18n t="apply.loading" />
        </h1>
        <p>
          <I18n t="apply.loadingText" />
        </p>
      </>
    );
  }

  return (
    <>
      <div className="signin-accordion">
        <div className="signin-timeline-title">
          {deadlinePassed ? (
            <>
              <h1>
                <I18n t="apply.deadlinePassedTitle" />
              </h1>
              <p>
                <I18n t="apply.deadlinePassed" />
              </p>
            </>
          ) : (
            <>
              {percentage >= 100 ? (
                <>
                  {projectInfo.application.status === "PUBLISHED" ? (
                    <>
                      <h1>
                        <I18n t="apply.applied" />
                      </h1>
                      <p>
                        <I18n t="apply.allGood" />
                      </p>
                    </>
                  ) : (
                    <>
                      <h1>
                        <I18n t="apply.submit" />
                      </h1>
                      <p>
                        <I18n t="apply.finalCheck" />
                      </p>
                    </>
                  )}
                </>
              ) : (
                <>
                  <h1>
                    <I18n t="apply.completedPercent" args={percentage} />
                  </h1>
                  <p>
                    <I18n t="apply.checkAnswers" />
                  </p>
                </>
              )}
            </>
          )}
        </div>

        <Accordion allowMultipleExpanded={true}>
          <div className="timeline timeline-signin timeline-review">
            <AccordionItem
              uuid={9999 + "-page"}
              key={9999 + "-page"}
              dangerouslySetExpanded={true}
            >
              <AccordionItemHeading className="accordion__heading">
                <AccordionItemButton className={`timeline__item_button`}>
                  <div className="timeline__item_bullet review_bullet">
                    <span>{1}</span>
                  </div>
                  <div className="on_line_title">
                    <h3>
                      <I18n t="account.companyProfile" />
                    </h3>
                  </div>

                  {!deadlinePassed &&
                    projectInfo.application?.status !== "PUBLISHED" && (
                      <button
                        className="edit"
                        onClick={() => redirectBackToQuestion("company")}
                      >
                        <img
                          src="/images/pencil.svg"
                          alt={I18n.getTranslation(history.location, "change")}
                        />
                        <I18n t="change" />
                      </button>
                    )}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="timeline__item_content">
                {companyData.fields.data[0].fields.map((field) => {
                  const Type = components[field.type];
                  return (
                    <Type
                      key={field.id}
                      field={field}
                      register={register}
                      value={companyValues[field.id]}
                      setValue={setValue}
                      control={control}
                    />
                  );
                })}
              </AccordionItemPanel>
            </AccordionItem>
            {formFields.map((combination, index) => {
              return (
                <AccordionItem
                  uuid={index + "-page"}
                  key={index + "-page"}
                  dangerouslySetExpanded={true}
                >
                  <AccordionItemHeading className="accordion__heading">
                    <AccordionItemButton className={`timeline__item_button`}>
                      <div
                        className={`timeline__item_bullet ${
                          accordionDone.includes(index) ? "review_bullet" : ""
                        }`}
                      >
                        <span>{index + 2}</span>
                      </div>
                      <div className="on_line_title">
                        <h3> {combination.properties.label.nl}</h3>
                      </div>
                      {!deadlinePassed &&
                        projectInfo.application?.status === "DRAFT" && (
                          <button
                            className="edit"
                            onClick={() => redirectBackToQuestion(index + 1)}
                          >
                            <img
                              src="/images/pencil.svg"
                              alt={I18n.getTranslation(
                                history.location,
                                "change"
                              )}
                            />
                            <I18n t="change" />
                          </button>
                        )}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="timeline__item_content">
                    {combination.fields.map((field) => {
                      const Type = components[field.type];
                      return (
                        <Type
                          key={field.id}
                          field={field}
                          register={register}
                          value={defaultValues[field.id]}
                          setValue={setValue}
                          control={control}
                        />
                      );
                    })}
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
            <AccordionItem dangerouslySetExpanded={true}>
              <AccordionItemHeading className="accordion__heading">
                <AccordionItemButton className={`timeline__item_button `}>
                  <div className="timeline__item_bullet ">
                    <span>{formFields.length + 2}</span>
                  </div>
                  <div className=" on_line_title">
                    <h3>
                      <I18n t="apply.checkAndApply" />
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel className="timeline__item_content">
                {deadlinePassed ? (
                  <p>
                    <I18n t="apply.deadlinePassedReview" />
                  </p>
                ) : (
                  <>
                    {percentage >= 100 ? (
                      <>
                        {projectInfo.application.status === "PUBLISHED" ? (
                          <div className="info__block">
                            <p>
                              <I18n t="apply.changeOrRetract" />
                            </p>
                            <button
                              onClick={() => redirectBackToQuestion(0)}
                              className="button__apply button-mobile button-right"
                            >
                              <I18n t="apply.toApplication" />
                            </button>
                          </div>
                        ) : (
                          <div className="info__block">
                            <p>
                              <I18n t="apply.applicationIsComplete" />
                            </p>
                            <button
                              onClick={() => redirectBackToQuestion(0)}
                              className="button__apply button-mobile button-right"
                            >
                              <I18n t="apply.makeChange" />
                            </button>
                            <button
                              onClick={(e) => confirmProject(e)}
                              className="button__apply button-mobile button-right"
                            >
                              <I18n t="apply.sendApplication" />
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <p>
                          <I18n t="apply.notComplete" />
                        </p>
                        <button
                          onClick={() => redirectBackToQuestion(0)}
                          className="button__apply button-mobile button-right"
                        >
                          <I18n t="apply.continue" />
                        </button>
                      </>
                    )}
                  </>
                )}
              </AccordionItemPanel>
            </AccordionItem>
          </div>
        </Accordion>
      </div>
    </>
  );
}
