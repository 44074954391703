import React from "react";

export default function File(props) {
  const properties = props.field.properties;
  return (
    <>
      <label>{properties.label.nl}</label>

      {props.value &&
        props.value?.map((file, index) => {
          return (
            <div key={file.url} className="download__bar">
              <div className="text__part">
                <img src="/icons/file.svg" alt="download icon" />
                <p>{file.filename}</p>
              </div>
            </div>
          );
        })}
    </>
  );
}
