import React from "react";
import { useStaleSWR } from "../../utils";
import Loading from "../loading";
import Error from "../error";

import Partners from "../Partners";

export default function AllPartners({ horizontal }) {
  const baseQuery = horizontal ? "/partners?_limit=8" : "/partners";
  const { data, error } = useStaleSWR(baseQuery);

  if (error) {
    return <Error block message={error.message} />;
  }

  if (!data) {
    return <Loading />;
  }

  return <Partners partners={data} horizontal={horizontal} />;
}
