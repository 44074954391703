import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import "./styles.scss";

const PianooBox = ({ text = "" }) => {
  if (text === "" || text === null) {
    return <> </>;
  }

  return (
    <div className="border__item">
      <img
        className="piano-logo"
        src="/images/procedure/pianoo-logo.svg"
        alt="plan"
      />
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};

PianooBox.propTypes = {
  content: PropTypes.string,
};

export default PianooBox;
