import React, { useState, useEffect, useCallback } from "react";
import Modal from "../Modal";
import AccountLogin from "../AccountLogin";
import AccountRegister from "../AccountRegister";
import AccountForgot from "../AccountForgot";
import { useHistory } from "react-router-dom";

import "./styles.scss";

export default function UserModal() {
  const [state, setState] = useState("login");
  const [loggedIn, setLoggedIn] = useState(false);
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeMe = useCallback(() => {
    history.goBack();
  });

  useEffect(() => {
    const escFunction = (event) => {
      if (event.keyCode === 27) {
        closeMe();
      }
    };

    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [closeMe]);

  return (
    <Modal open={!loggedIn} onClose={() => closeMe()}>
      {state === "login" && (
        <AccountLogin
          setState={setState}
          setLoggedIn={setLoggedIn}
        ></AccountLogin>
      )}

      {state === "register" && (
        <AccountRegister setState={setState}> </AccountRegister>
      )}

      {state === "forgot" && (
        <AccountForgot setState={setState}> </AccountForgot>
      )}
    </Modal>
  );
}
