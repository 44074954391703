import React from "react";
import { makeSiteBossURL, useStaleSWR } from "../../utils";
import { NavLink } from "react-router-i18n";
import I18n from "../../i18n";
import Loading from "../loading";
import Error from "../error";

export default function AccountSidebar() {
  // 0 is for company profiles
  const url = makeSiteBossURL("/user/company-profile/filled/");

  const { data: companyInfo, error } = useStaleSWR(url, true, false, true);

  if (error) {
    return <Error block message={error.message} />;
  }

  if (!companyInfo) {
    return <Loading />;
  }

  const companyDone = companyInfo.company;

  return (
    <div className="col1" id="sidebar">
      <div className="stickyMenu stickyMenuHigher">
        <div className="index-item">
          <NavLink
            to={{
              pathname: `/account/company`,
              state: { scrollTo: "contentTop" },
            }}
            className={`item-link ${companyDone ? "company-checkmark" : ""}`}
            activeClassName="active"
          >
            <I18n t="account.companyProfile" />
          </NavLink>

          <NavLink
            to={{
              pathname: `/account`,
              state: { scrollTo: "contentTop" },
            }}
            className="item-link"
            exact
          >
            <I18n t="yourApplications" />
          </NavLink>

          <NavLink
            to={{
              pathname: `/account/profile`,
              state: { scrollTo: "contentTop" },
            }}
            className="item-link"
            activeClassName="active"
          >
            <I18n t="yourProfile" />
          </NavLink>
        </div>
      </div>
    </div>
  );
}
