import React from "react";
import BaseLayout from "../../components/baselayout";
import ProjectList from "../../components/projectlist";
import ContentList from "../../components/contentlist";
import { useStaleSWR, getApiPrefix } from "../../utils";
import "./styles.scss";
import Loading from "../../components/loading";
import Error from "../../components/error";
import I18n from "../../i18n";
import NotFound from "../notfound";

export default function Partner({ match }) {
  const { data, error } = useStaleSWR(`/partners/?slug=${match.params.slug}`);
  const partner = data && data[0];

  if (error) {
    return <Error message={error.message} />;
  }

  if (!data) {
    return <Loading />;
  }

  if (data && !partner) return <NotFound />;

  const img = partner?.logo?.formats?.medium
    ? `${getApiPrefix()}/${partner.logo.formats.medium.url}`
    : null;
  const imgThumb = partner?.logo?.formats?.thumbnail
    ? `${getApiPrefix()}/${partner.logo.formats.thumbnail.url}`
    : null;

  const scrollToPartners = () => {
    document
      .getElementsByClassName("gridrow__large partner__content__top")[0]
      .scrollIntoView();
  };

  return (
    <BaseLayout
      type="partner"
      headerImage={img ?? imgThumb ?? ""}
      headerContent={
        <>
          <h3>{partner.name}</h3>
          <p> {partner.header}</p>
          {partner.projectsbuttontext && (
            <>
              <button
                className="button__apply"
                onClick={() => scrollToPartners()}
              >
                {partner.projectsbuttontext}
              </button>
            </>
          )}
        </>
      }
      breadcrumbs={[
        {
          title: I18n.getTranslation(window.location, "about"),
          path: "/info/" + I18n.getTranslation(window.location, "aboutUsPath"),
        },
        { title: partner.name },
      ]}
    >
      <div className="container">
        <div className="gridrow__small">
          <ContentList contentitems={partner.content} />
        </div>

        <div className="gridrow__large partner__content__top">
          <ProjectList projects={partner.projects} lokets={partner.lokets} />
        </div>
      </div>
    </BaseLayout>
  );
}
