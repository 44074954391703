import React from "react";
import Divider from "../divider";
import QnAQuestion from "../../components/qnaquestion";
import QnASearch from "../../components/qnasearch";
import Loading from "../../components/loading";
import Error from "../../components/error";
import { useStaleSWR, getApiPrefix, parseDate } from "../../utils";
import { Switch, Route, NavLink } from "react-router-dom";
import QnaQuestionForm from "../qnaquestionform";
import PdfGenerator from "../pdfgenerator";

import "./styles.scss";
import I18n from "../../i18n";

export default function QnaProject({ project, location, history, match }) {
  const { url } = match;
  const globalProjectId =
    project.localizations[0]?.locale === "nl-NL"
      ? project.localizations[0].id
      : project.id;

  const { data: categories, error } = useStaleSWR(
    `/categories/published/?project=${globalProjectId}&_sort=categoryCode`,
    false,
    false
  );

  let input = React.createRef();

  const handleSearch = (event) => {
    event.preventDefault();
    history.push(`${url}/search/${input.current.value}/`);
  };

  const QnaDeadlineIsPast =
    new Date().getTime() > new Date(project.deadline2).getTime();

  if (!categories) {
    return <Loading />;
  }

  if (error) {
    return <Error block message={error.message} />;
  }

  const backgroundColor = (index) => {
    index = index % 6;

    switch (index) {
      case 1:
        return "bg_color_pink";
      case 2:
        return "bg_color_purple";
      case 3:
        return "bg_color_babyblue";
      case 4:
        return "bg_color_turquoise";
      case 5:
        return "bg_color_green";
      case 6:
        return "bg_color_yellow";
      default:
        return "bg_color_lila";
    }
  };

  return (
    <>
      <div className="header">
        <div className="intro__title">
          <h3>
            <I18n t="qna.qna" />
          </h3>
          <p>
            <I18n t="qna.qnaText" />
          </p>
        </div>

        <form className="search__kennisbank" onSubmit={handleSearch}>
          <label htmlFor="site-search">
            <input
              placeholder={I18n.getTranslation(location, "qna.search")}
              type="search"
              id="site-search"
              name="search"
              aria-label="Search through site content"
              ref={input}
            />
          </label>
          <button type="submit">
            <img
              src="/images/vraag-antwoord/search-icon.svg"
              alt="search icon"
            />
          </button>
        </form>
      </div>

      <Switch>
        <Route path="/:language?/project/:project/qna/cat/:cat">
          <QnAQuestion />
        </Route>

        <Route path="/:language?/project/:project/qna/search/:query">
          <QnASearch projectId={globalProjectId} />
        </Route>
      </Switch>

      {/* subject items in colored boxes  */}
      <div className="subject__block__container">
        {categories.map((category, index) => {
          return (
            <NavLink
              to={{
                pathname: `${url}/cat/${category.id}/`,
                state: { scrollTo: false },
              }}
              className={`subject__item ${backgroundColor(index)}`}
              key={category.id}
            >
              <div className="subject__title">
                <h4>{category.categoryCode + ". " + category.label}</h4>
                <img src="/icons/arrow_right_blue.svg" alt="arrow" />
              </div>

              <p>
                <I18n t="questions" args={category?.questions?.length} />
              </p>
            </NavLink>
          );
        })}
      </div>

      <Divider />
      <PdfGenerator projectId={globalProjectId} />

      <Divider />
      {QnaDeadlineIsPast ? (
        <I18n t="qna.form.noMore" />
      ) : (
        <>
          <div className="item__title">
            <h4>
              <I18n t="qna.form.ask" />
            </h4>
            <p>
              <I18n
                t="qna.form.text"
                args={
                  project.deadline2 ? parseDate(project.deadline2) : undefined
                }
              />
            </p>
          </div>
          <div className="qna__form__wrapper">
            <QnaQuestionForm
              newsletterListId={project.newsletterLijstId}
              location={location}
              submitUrl={getApiPrefix() + "/questions"}
              submitData={{
                project: globalProjectId,
              }}
              requireAll
              succesMessage={
                <p>
                  <I18n t="qna.form.timeline" />
                </p>
              }
            />
          </div>
        </>
      )}
    </>
  );
}
