import { useState } from "react";

const useAccordionManager = () => {
  // Array with 1 number. Appended by "-page" to neglect potiential id issues
  // That number is the index of which accordion is open
  const [accordionsOpen, setAccordionOpen] = useState([]);

  // Number which index is done, all previous are also done.
  const [accordionDone, setAccordionDone] = useState([]);

  const isAccordionOpen = (index) => {
    return accordionsOpen.includes(index + "-page");
  };

  const isAccordionDone = (index) => {
    return accordionDone.includes(index - 1);
  };

  const getCurrentAccordionOpenIndex = () => {
    if (accordionsOpen.length !== 0) {
      return parseInt(accordionsOpen[0].split("-")[0] - 1);
    }

    return "not open";
  };

  return {
    accordionsOpen,
    setAccordionOpen,
    accordionDone,
    setAccordionDone,
    isAccordionOpen,
    isAccordionDone,
    getCurrentAccordionOpenIndex,
  };
};

export default useAccordionManager;
