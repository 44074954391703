import React from "react";
import ReactMarkdown from "react-markdown";
import PianooBox from "../pianoobox";
import ContentItem from "../contentitem";
import { getApiPrefix } from "../../utils";

const ProcedureBox = ({ procedure, inline }) => {
  return (
    <div className="content__basic">
      <div>
        <div className={procedure.image ? "content__with__image" : ""}>
          <div className="text__content">
            <h2>
              {inline === true ? "Procedure: " : ""} {procedure.title_nl}
            </h2>
            <ReactMarkdown>{procedure.intro_nl}</ReactMarkdown>
          </div>
          {procedure.image && (
            <img src={`${getApiPrefix()}${procedure.image.url}`} alt="" />
          )}
        </div>

        {/* TODO should be removed (from strapi as well);
          never happens/no use + procedure.Content not available */}
        {inline === false &&
          procedure.Content.map((subblock, index) => {
            return (
              <ContentItem
                key={index}
                content={subblock}
                partners={[]}
                events={[]}
              />
            );
          })}
      </div>
      <PianooBox text={procedure.pianoo_nl} />
    </div>
  );
};

export default ProcedureBox;
