import React from "react";
import BaseLayout from "../baselayout";
import I18n from "../../i18n";

export default function Error(props) {
  if (props.block) {
    return (
      <>
        <h1>{props.title ? props.title : <I18n t="error.loading" />}</h1>
        <h3>{props.message}</h3>

        <div>
          <p>
            <I18n t="error.contact" />
          </p>
        </div>
      </>
    );
  }
  return (
    <BaseLayout
      type="project"
      breadcrumbs={[{ title: <I18n t="error.error" /> }]}
      headerContent={
        <>
          <h2>{props.title ? props.title : <I18n t="error.loading" />}</h2>
          <p>{props.message}</p>
        </>
      }
    >
      <div>
        <p>
          <I18n t="error.contact" />
        </p>
      </div>
    </BaseLayout>
  );
}
