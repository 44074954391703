import React from "react";
import PropTypes from "prop-types";

import Divider from "../divider";
import ContentItem from "../contentitem";

import "./styles.scss";
import I18n from "../../i18n";

const ContentList = ({
  contentitems = [],
  projectInfo = [],
  itemWrapper,
  underConstructionText,
  pageType = "",
}) => {
  let events = [];
  const timeline = contentitems.filter((item) => {
    // eslint-disable-next-line no-underscore-dangle
    return item.__component === "contentblocks.tijdlijn";
  });

  if (timeline.length > 0) {
    events = timeline[0].steps;
  }

  const DefaultWrapper = ({ children }) => <>{children}</>;
  const ItemWrapper = itemWrapper ?? DefaultWrapper;

  return (
    <div>
      {contentitems.map((block, index) => {
        return (
          <div id={`item${index}`} key={index}>
            <ItemWrapper item={block}>
              <ContentItem
                content={block}
                projectInfo={projectInfo}
                events={events}
                underConstructionText={underConstructionText}
                pageType={pageType}
              />
            </ItemWrapper>
          </div>
        );
      })}

      {contentitems.ShowMatchMaker ||
        (contentitems.ShowQuestions && (
          <>
            <Divider />
            <div className="project__boxes">
              <h3>
                <I18n t="otherPages" />
              </h3>
              <div className="boxes__container">
                {contentitems.ShowQuestions && (
                  <a
                    href="/"
                    className="project__box__fullwidth halfwidth__box"
                  >
                    <div className="project__box__title">
                      <h4>
                        <I18n t="qna.qna" />
                      </h4>
                      <img
                        src="/icons/arrow_right_blue.svg"
                        alt="arrow to the right"
                      />
                    </div>
                    <p>
                      <I18n t="qna.allFind" />
                    </p>
                  </a>
                )}

                {contentitems.ShowMatchMaker && (
                  <a
                    href="/"
                    className="project__box__fullwidth halfwidth__box"
                  >
                    <div className="project__box__title">
                      <h4>
                        <I18n t="matchmaker" />
                      </h4>
                      <img
                        src="/icons/arrow_right_blue.svg"
                        alt="arrow to the right"
                      />
                    </div>
                    <p>
                      <I18n t="matchmakerFind" />
                    </p>
                  </a>
                )}
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

ContentList.propTypes = {
  contentitems: PropTypes.array,
};

export default ContentList;
