const en = {
  result(number) {
    if (number === 0) {
      return `no results`;
    }
    if (number === 1) {
      return `1 result`;
    }
    return `${number} results`;
  },
  days(number) {
    if (number === 1) {
      return `day`;
    }
    return `days`;
  },
  timeLeft({ days, hours, minutes }) {
    if (days > 0) {
      const dayText = days === 1 ? "day" : "days";
      return `${days} ${dayText} left`;
    }
    if (days === 0) {
      if (hours > 0) {
        const hourText = hours === 1 ? "hour" : "hours";
        return `${hours} ${hourText} left`;
      }
      if (hours === 0 && minutes > 0) {
        const minuteText = minutes === 1 ? "minute" : "minutes";
        return `${minutes} ${minuteText} left`;
      }
    }
    return "deadline passed";
  },
  questions(number) {
    if (number === 1) {
      return `One question`;
    }
    return `${number} questions`;
  },
  passwordComplexityHint:
    "Choose a password that contains at least ten characters containing enough different letters, capitals and special characters.",
  timeAt: "at",

  readmore: "Read more",
  home: "Home",
  about: "About",
  partners: "Partners",
  inschrijven: "Apply",
  leesweergave: "Review",
  openInschrijven: "Open application",
  terugNaarInfo: "Back to project",
  yourProfile: "My account",
  yourApplications: "My applications",
  otherPages: "Other pages",
  change: "Change",
  cancel: "Cancel",
  save: "Save",
  applyDeadline: "Application deadline",
  downloadNoFile: "No file added to Strapi",
  noContent: "no match",
  matchmaker: "Matchmaker",
  matchmakerFind: "Find a match.",
  calendar: {
    add: "Add to my calendar",
    eventTitle: ({ project }) => (project ? `Deadline ${project}` : "Deadline"),
    eventDescription: ({ project }) =>
      `This is the final hour for applications to the project${
        project && ` "${project}"`
      }.`,
  },
  participatePath: "participate",
  publicSectorPath: "gemeente",
  aboutUsPath: "about-innovatiepartners",
  aboutBasePath: "about",
  infoBasePath: "info",

  footer: {
    newsletterHeader: "Sign up for our newsletter",
    newsletterSubheader:
      "Stay informed about new projects and the quarterly newsletter",
    aboutIP:
      "Innovatiepartners involves entrepreneurs in solving the challenges of public and social organisations.",
    linkedInLabel: "/innovatiepartners-nl",
    linkedIn: "https://www.linkedin.com/company/innovatiepartners-nl",
    privacy: "Privacy Policy",
    privacyLink:
      "https://www.amsterdam.nl/privacy/specifieke/privacyverklaringen-ondernemen/privacy-innovatiepartners/",
    cookies: "Cookie Policy",
    cookiesLink: "/info/cookies-on-this-website-and-privacy",
    terms: "Terms of Service",
    termsLink: "/info/about-innovatiepartners",
    title: "Innovatiepartners",
  },

  newsletter: {
    follow: "Follow this project",
    submit: "Sign up",
    submitSucces:
      "It's great that you want to be kept informed of Innovatiepartners. You'll hear from us!",
    name: "First name",
    email: "E-mail",
    addGeneral:
      "Yes, I would also like to stay informed about new projects and the quarterly newsletter of innovationpartners.nl.",
  },

  togo: "in",
  somethingWrong: "Something went wrong. Try again!",
  saved: "Your additions are saved.",

  logout: "Log out",
  logoutWarning: "Are you sure you want to log out?",
  logoutConfirm: "Yes",
  logoutDeny: "Cancel",
  back: "Back",

  login: {
    incorrectCredentials: "Your credentials are incorrect",
    title: "Log in",
    text: "Enter your e-mail address and password. You will receive a text message to verify your login information.",
    codeIncorrect: "Incorrect code.",
    loginAction: "Log in",
    email: "E-mail address",
    // deepcode ignore NoHardcodedPasswords: Translation
    password: "Password",
    createAccount: "Create account",
    backToLogin: "Back to login",
    registerEmailText:
      "You will receive an e-mail. Click on the button in the e-mail to verify your account. ",
    confirmCreate: "Create account",
    confirmEmail: "Confirm E-mail address",
    getEmail:
      "You will receive an e-mail. Click on the button in the e-mail to verify your account. ",
    noEmailConfirmation:
      "If you have not received an e-mail after 15 minutes, please contact ",

    phone: "Phone number",
    phoneText:
      "In order to log in on Innovatiepartners, you always have to enter a code as well as your e-mail address. You receive this code by tekst message. Enter your phone number. ",
    phoneIncorrect: "Incorrect phone number. Please only use numbers.",
    confirmSms: "Send text message",

    supplyEmail: "Enter your e-mail address",

    supplySms: "Enter SMS-code",
    smsReceive: "Enter the code that you received by text message.",
    smsIncorrect: "Incorrect code",
    smsCheck: "Check",

    passwordSafe: "Choose a safe password.",
    passwordRepeat: "Repeat password",
    passwordConfirm: "Confirm password",
    passwordRequirements: "The password required atleast 10 characters",
    done: "Done!",
    loggedIn: "You are now logged in.",
    toProfile: "To profile",

    sms: "Text message verification",
    smsText:
      "Enter your e-mail address. You will receive verification text message on the phone number that is connected to your e-mail address.",
    loggedInPasswordChanged:
      "Your new password has been set and you are now logged in.",
  },

  account: {
    needAccount:
      "You need an account to be able to apply. Don't have an account?",
    companyProfile: "Company profile",
    companyProfileText: "Please fill in your company profile.",
    companyProfileExplain:
      "You can adjust your company profile below, adjustments you make before the deadline of a tender are automatically sent.",
    companyProfileRequired:
      "To apply for projects it is required to have a filled in company profile. You can update the data at any time.",
    noProjects: "No projects yet",
    noProjectsText:
      "Is there nothing here? Then you haven't started an application yet.",
    noProjectsLook: "See the projects you can apply to.",

    editEmail: "change e-mail",
    emailEdited: "E-mail changed",
    emailEditedSucces: "The change has been successfully implemented",
    emailEditedFail: "Error changing e-mail",
    edit: {
      profile: "You can edit your account data here.",
      editPassword: "Change password",
      editPasswordText: "Choose a new password",
      newPassword: "New password",
      passwordCharacters: "The password requires 10 characters at minimum",
      currentPassword: "Current password",
      currentPasswordError: "Fill in your current password.",
      editEmail: "Change e-mail",
      editEmailText:
        "You'll receive an email. Follow the link in the email to confirm the new emailadres",
      nowEmailText: "You're logged in as: ",
      newEmail: "New e-mail",
      correctEmailText: "Supply a correct e-mail",
      editPhone: "Change phone number",
      editPhoneText: "Change your phone number here",
      newPhone: "New phone number",
      newPhoneText: "Fill in the phone number",
      newCodeText: "Code is not correct, try again.",
      confirmCode: "Confirm code",
      deleteAccount: "Delete your account",
      deleteAccountText:
        "If you delete your account, all data regarding the account will be deleted. If your data is used in a ongoing application, that data can't be deleted.",
      deleteAccountSure: "Are you sure?",
      deleteAccountIrreversible:
        "Your account will be deleted. This action is irreversible.",

      accountDeleted: "Your account is deleted. You're being logged out.",

      accountComplete:
        "Your company profile is complete, You can continue your application.",
    },
    applications: {
      status: "View the status of your applications.",
    },
  },

  loading: {
    moment: "Just a moment...",
    loading: "Loading data.",
  },

  forms: {
    choose: "Choose...",
    placeholderMultiplechoice: "Select one or multiple options",
    noOptions: "no options",
    no: "No form available.",
    download: "Download questions",
    submittedPercentage: "100% completed and submitted",
    seeAndEdit: "View and edit",
    see: "View",
    submitFail: "An unknown error occurs at submit",
  },

  menu: {
    switchTo: "naar",
    initiativeOf: "An initiative of",
    close: "Close",
    expand: "Menu",
    eng: "English",
    nl: "Nederlands",
    dashboard: "My applications",
    logout: "Logout",
    participate: "Participate",
    lokets: "Desks",
    procedures: "How does it work?",
    fairness: "Fairness",
    procedure: "Procedure",
    glossary: "Glossary",
    checklist: "Checklist",
    form: "UEA form",
  },

  projects: {
    type: ({ type }) => {
      if (type === "loket") return "desk";
      return "project";
    },
    share: ({ type }) => `Share ${type} on`,
    details: "Details overview",
    shareMailSubject: ({ type, title }) =>
      `Check out this ${type} op Innovatiepartners.nl: "${title}"`,
    shareMailBody: ({ title, url }) => `Here is the link to "${title}": ${url}`,
    shareWA: ({ title, url }) => `Check out "${title}": ${url}`,
    the: "The project",
    projects: "Projects",
    closed: "Closed",
    showPrevious: "Show previous projects",
    unableToApply: "It is no longer possible to apply for these projects",
    headerSDGs: "This project contributes to the Sustainable Development Goals",
    headerTriggerSDGs: "SDGs",
    infoHeaderSDGs: "Sustainable Development Goals",
    infoSDGs:
      "The Sustainable Development Goals were established by the United Nations in 2015 as the new global sustainable development agenda for 2030.",
    linkSDGs: "Read more",
    procedures: "what is procurement",
    urlSDGs: "https://www.sdgnederland.nl/de-17-sdgs/",
  },

  answerQuestion: "Answer question",

  qna: {
    results(results) {
      if (results > 1)
        return `
      There are ${results} results for this query:`;
      return `There is a result for this query:`;
    },
    questions(number) {
      if (number === 0) {
        return `no questions`;
      }

      if (number === 1) {
        return `1 question`;
      }

      return `${number} questions`;
    },
    questionSubmitted({ dateCreated, datePublished }) {
      return `Question submitted on ${dateCreated}, answer published on ${datePublished}.`;
    },
    question: "Question",
    questionDeleted: "This question was deleted",
    questionsNoCategory: "Questions without category",
    noQuestions: "No questions for this project yet",
    answer: "Answer",
    qna: "Question and answer",
    portal: "Question and answer portal",
    closed: "Closed",
    closeDate: (date) => `(closing date ${date})`,
    open: "Open",
    answerForm: {
      who: "Who",
      afterPublication: "Follows after publication",
      noNameError: "Supply a name please",
      noCategoryError: "Choose or create a category",
      name: "Name",
      email: "E-mail",
      org: "Organisation",
      submitTime: "Submitted at",
      removed: "Removed",
      questionNumber: "Question number",
      publicationNumber: "Publication number",
      publicationTime: "Published at",
      category: "Category",
      categoryNew: "new",
      categoryNewLabel: "Category name",
      categoryNewSave: "save",
      publish: "Publish",
      save: "Save",
      remove: "Remove",
      publishWarning:
        "Are you sure? You can no longer change the question and answer afterwards.",
      publishConfirm: "Yes, I want to publish now",
      removeWarning: "Are you sure you want to remove this question?",
      confirm: "Yes",
    },
    qnaSimpleText:
      "Check out the questions that have been asked about the project and ask your own questions using the form at the bottom of the page.",
    qnaText: `Check out the questions that have been asked about the project and ask your own questions using the form at the bottom of the page. 
    For tender-insiders: this Q&A page is the summary of information. `,
    searchresult: "Results search",
    search: "Search for a topic",
    none: "No questions or answers found for this search.",
    downloadWord: "Download as Word",
    downloadPdf: "Download as PDF",
    downloadPdfText:
      "All questions and answers are collected in a PDF. Ideal if you want to print them. This is the summary of information.",
    all: "All questions with answers",
    allFind: "Find all questions and answers",
    form: {
      noMore: "It is no longer possible to ask a question about this project",
      ask: "Ask your question",
      text(deadline) {
        return `You can ask questions about the contents or procedure of the project. Your question needs to be in English. 
        All questions and answers will be published anonymously on this page.  ${
          !!deadline ? `You can ask questions until ${deadline}.` : ""
        }`;
      },
      name: "My name is...",
      org: "My company is...",
      tel: "My phone number is...",
      question: "My question is...",
      email: "My e-mail address is...",
      privacy: "Yes, I agree with ",
      privacyDocument: "the privacy policy.",
      privacy2:
        "Yes, I agree that my name and e-mail address may be used to contact me about my question.",
      anonymous: "All questions will be published anonymously.",
      required: "This field is required.",
      maxLength: "Please provide a shorter answer",
      correctEmail: "This field requires a correct e-mail address.",
      correctTelephone: "This field requires a valid (Dutch) phone number",
      privacyRequired: "To ask a question you have to agree.",
      close: "Close window",
      thanks:
        "Thanks for you question. You'll receive an copy of your question in the supplied e-mail address.",
      timeline:
        "Look in the timeline of this project when questions will be answerd. De answers will be posted here in the answer and question page of this project.",
      follow: "Keep me posted on this project",
    },
  },

  createAccount: "Create your account here",
  forgotPassword: "Forgot your password?",

  deadline: "Deadline",
  apply: {
    retract: "Change application",
    review: "View application ",
    receivedTitle: "Done! We received your application.",
    receivedText: "You will receive a confirmation e-mail.",
    apply: "Apply",
    required: "This field is required",
    correctKvK: "This field requires a (Dutch) Chamber of Commerce number",
    emailRequired: "This field requires a correct E-mail address",
    numberRequired: "This field requires a number",
    deadlinePassedTitle: "The deadline has passed", //De deadline is voorbij",
    deadlinePassed:
      "The deadline has passed. You can’t submit, change or withdraw your application anymore.",
    deadlinePassedReview:
      "The deadline has passed. You can’t submit, change or withdraw your application anymore.",
    leaveWithoutSaveWarning:
      "If you don't save this block, your changes will be lost. Are you sure?",
    saveAndNext: "Save and continue", //Bewaar en naar volgende vraag",
    answerQuestions: "Answer the questions to apply for this challenge.", //Geef antwoord op de vragen om je in te schrijven voor dit project.
    viewCompanyProfile:
      "Check your company profile to see if it is still up to date.", //      "Bekijk je bedrijfsprofiel om te kijken of alles nog klopt.",
    toCompanyProfile: "To company profile", //"Naar bedrijfsprofiel"
    toApplication: "To application form",
    readyToSubmit:
      "You completed the form and can now submit your application! Please review all your answers one last time.",
    readyToSubmitBtn: "Review answers",
    submit: "Check and submit",
    applicationClosed: "Deadline passed",
    applicationIncomplete:
      "Your application isn’t complete yet. Please answer all questions before you apply.",
    checkAndApply: "Check and submit",
    completedPercent(percent) {
      return `You’ve completed ${percent}%`;
    },
    checkAnswers: "Check your answers.",
    loketSubmit: "You can now send the information definitely.",
    loketSubmitButton: "Send",

    finalCheck:
      "You’re almost there! Check your answers one last time and submit your application. You can change or withdraw your application until the deadline.",
    allGood:
      "Done! We received your application. You can change or withdraw your application until the deadline.",

    applied: "Submitted",
    changeOrRetract:
      "We received your application. Go to the application form to withdraw, change and resubmit your application.",
    continue: "Continue application",
    notComplete:
      "Your application isn’t complete yet. Please answer all questions before you apply.",
    makeChange: "Change application",
    sendApplication: "Submit application",
    applicationIsComplete: "Your application is complete.",
    confirmTitle: "Do you want to change your application?",
    confirmText: `You can change your application until the moment of the deadline. 
    For example, if you want to add something or think of a better answer to a question. To change your application, withdraw, edit and resubmit your application. `,
    confirmText2: `Please note: if you edit your application, you withdraw. Resubmit your application to join again. 
    If you wish to withdraw your application entirely, you can also withdraw and not resubmit your application. You can do this until the deadline.`,
    loading: "Your application is being sent",
    loadingText: "Please wait, your application is currently being sent",

    deadline: "The deadline has passed",
    deadlineText:
      "Did you submit your application? Fingers crossed! If you didn’t submit yet, unfortunately you can’t apply anymore.",
    unacceptableFileType: "File type not accepted",
  },

  error: {
    error: "Error",
    missing: "Could not find requested data",
    unknown: "An unknown error occurred",
    serverConnection: "Error connecting to the server. Try again.",
    contact: "Contact us if this error keep occurring.",
    loading: "Error loading data",
    loadingPage: "Error loading page",
    notFoundPage: "Page not found",
    notFoundCheck: "Make sure you have the right address",
  },

  blogs: {
    path: "experiences",
  },

  contact: {
    send: "Send",
    question: "Let us know how we can help you!",
  },
};

export default en;
