import React from "react";
import ProjectCard from "../projectcard";
import UnderConstructionCard from "../UnderConstructionCard";
import { numberOfDaysToDeadline, useStaleSWR } from "../../utils";
import icon from "../../assets/icons/arrow_down_blue.svg";
import Image from "../Image";

import "./styles.scss";

export default function ProjectList({
  horizontal,
  underConstructionText,
  noProjectsText,
  lokets: givenLokets,
  projects: givenProjects,
  onlyProjects,
  onlyLokets,
  onlyInactiveProjects,
  collapsed: givenCollapsed = false,
  collapsedText,
  setDoneLoading,
  wrapperClass,
}) {
  const { data: projects, error: projectError } = useStaleSWR(
    "/projects/?published=true&_sort=deadline,slug"
  );
  const { data: lokets, error: loketError } = useStaleSWR(
    "/lokets/?published=true&_sort=slug"
  );
  const [collapsed, setCollapsed] = React.useState(givenCollapsed);

  const filterProjectsOnActiveStatus = (projects) =>
    projects?.filter((project) => {
      const { closed } = numberOfDaysToDeadline(project.deadline);

      if (onlyInactiveProjects) return closed;
      return !closed;
    });
  const currentProjects =
    givenProjects ?? filterProjectsOnActiveStatus(projects) ?? [];

  const currentLokets = givenLokets ?? lokets ?? [];
  const currentLoketsIdentified = currentLokets.map((loket) => ({
    ...loket,
    loket: true,
  })); //identify loket between projects

  if (projectError) console.log(projectError.message);
  if (loketError) console.log(loketError.message);

  const currentLoading = !lokets || !projects;
  const cards = onlyLokets
    ? currentLoketsIdentified
    : currentProjects.concat(onlyProjects ? [] : currentLoketsIdentified);

  const setProjectEvents = (project) => {
    let events = [];
    const timeline = project.content?.filter((item) => {
      // eslint-disable-next-line no-underscore-dangle
      return item.__component === "contentblocks.tijdlijn";
    });

    if (timeline?.length > 0) {
      events = timeline[0].steps;
    }
    return events;
  };

  React.useEffect(() => {
    if (setDoneLoading && !!projects && !!lokets) {
      setDoneLoading(true);
    }
  }, [setDoneLoading, projects, lokets]);

  const setCardsAmount = (length) => {
    let amount = length;
    if (length === 0) amount += 1;
    if (!!underConstructionText) amount += 1;

    return amount;
  };

  const Wrapper = wrapperClass ? "div" : React.Fragment;
  const wrapperProps = wrapperClass
    ? {
        className: wrapperClass(setCardsAmount(cards.length)),
      }
    : {};

  return (
    <Wrapper {...wrapperProps}>
      {!currentLoading && !cards.length && noProjectsText && (
        <UnderConstructionCard text={noProjectsText} asCard horizontal />
      )}
      {/* render the first card before the under construction card */}
      {!!cards[0] && (
        <ProjectCard
          key={cards[0].id}
          id={cards[0].id}
          title={cards[0].Titel}
          thumbnail={
            cards[0].thumbnail?.formats == null
              ? ""
              : cards[0].thumbnail.formats.small.url
          }
          text={cards[0].summary}
          deadline={cards[0].deadline}
          deadline_text={cards[0].deadline_text}
          url={`/${cards[0].loket ? "loket" : "project"}/${cards[0].slug}`}
          events={setProjectEvents(cards[0])}
          thema={cards[0].thema}
          partner={cards[0].partner}
          overlay={cards[0].Overlaypng}
          loket={cards[0].loket}
          newsletterLijstId={cards[0].newsletterLijstId}
          vertical={horizontal} // horizontal list -> vertical card
        />
      )}
      {!!underConstructionText && (
        <UnderConstructionCard
          text={underConstructionText}
          addNewsletterCTA
          addImage
          asCard={horizontal}
        />
      )}
      {cards.length > 1 && collapsed && (
        <button
          className="projectlist__collapsed__button"
          onClick={() => setCollapsed(false)}
        >
          {collapsedText}{" "}
          <Image src={icon} wrapper={(i) => <div className="grey">{i}</div>} />
        </button>
      )}
      {cards.length > 1 &&
        !collapsed &&
        cards
          .filter((project, index) => index !== 0)
          .map((project) => (
            <ProjectCard
              key={project.id}
              id={project.id}
              title={project.Titel}
              thumbnail={
                project.thumbnail?.formats == null
                  ? ""
                  : project.thumbnail.formats.small.url
              }
              text={project.summary}
              deadline={project.deadline}
              deadline_text={project.deadline_text}
              url={`/${project.loket ? "loket" : "project"}/${project.slug}`}
              events={setProjectEvents(project)}
              thema={project.thema}
              partner={project.partner}
              overlay={project.Overlaypng}
              loket={project.loket}
              newsletterLijstId={project.newsletterLijstId}
              vertical={horizontal} // horizontal list -> vertical card
            />
          ))}
    </Wrapper>
  );
}
