import React from "react";
import { Link } from "react-router-i18n";
import "./styles.scss";
import I18n from "../../i18n";

const Breadcrumbs = ({ breadcrumbs = [] }) => {
  return (
    <nav className="breadcrumbs__container">
      <ol>
        <li>
          <Link to={`/`}>
            <I18n t="home" />
          </Link>
        </li>

        {breadcrumbs.map((breadcrumb, index) => {
          if (breadcrumb.path !== undefined) {
            return (
              <li key={index}>
                <Link to={breadcrumb.path}>{breadcrumb.title}</Link>
              </li>
            );
          }

          return <li key="202020">{breadcrumb.title}</li>;
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
