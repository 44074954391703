import React from "react";
import ReactMarkdown from "react-markdown";
import { Switch, Route, Redirect } from "react-router-dom";
import { NavLink } from "react-router-i18n";
import Loading from "../../components/loading";
import Error from "../../components/error";

import I18n from "../../i18n";
import BaseLayout from "../../components/baselayout";
import Divider from "../../components/divider";

import "./styles.scss";
import ApplyButton from "../../components/applybutton";
import ProjectLabels from "../../components/projectlabels";
import ProjectDetails from "../../components/ProjectDetails";

import ContentList from "../../components/contentlist";
import QnaProject from "../../components/qnaproject";
import Apply from "../../components/apply/form";
import ApplyReview from "../../components/apply/review";
import {
  useStaleSWR,
  getApiPrefix,
  numberOfDaysToDeadline,
  makeSiteBossURL,
} from "../../utils";
import StickyHeader from "../../components/stickyheader";
import OldSchoolMenuLink from "../../components/oldschoolmenulink";

export default function Project({ history, match, location }) {
  const [elRefs, setElRefs] = React.useState([]);
  const [scrolled, setScrolled] = React.useState(false);

  const glue = process.env.REACT_APP_BACKEND === "site" ? "?slug=" : "";
  const postfix = process.env.REACT_APP_BACKEND === "site" ? "" : "/";
  const { data, error } = useStaleSWR(
    `/projects/${glue}${match.params.projectUrl}${postfix}&_locale=all`,
    false,
    false
  );

  const { data: form } = useStaleSWR(
    makeSiteBossURL(`/project/${match.params.projectUrl}/`),
    true
  );

  // divide content into blocks for each NavigationTitle
  const setContentBlocks = function (allContent) {
    const tree = [{ id: 0, list: [] }];
    let index = 0;
    allContent.forEach((element) => {
      if (element.__component === "contentblocks.navigation-title") {
        tree.push({ title: element.title_nl, id: element.id, list: [] });
        index += 1;
      } else {
        tree[index].list.push(element);
      }
    });
    return tree;
  };
  const contentBlocks = setContentBlocks(
    data && data[0] ? data[0]?.content : []
  );
  const contentBlocksLength = contentBlocks.length;

  // create array of refs for all blocks (used at OldSchoolMenuLink > Scrollspy)
  React.useEffect(() => {
    setElRefs((elRefs) =>
      Array(contentBlocksLength)
        .fill()
        .map((_, i) => elRefs[i] || React.createRef())
    );
  }, [contentBlocksLength]);

  // scroll into view once if path has hash && element is present on page
  const hash = location.hash;
  const scrollTo = location.state?.scrollTo;
  React.useEffect(() => {
    if (!scrolled && hash) {
      const element = document.getElementById(hash.replace("#", ""));

      if (!!element) {
        element.scrollIntoView({ behavior: "smooth" });
        setScrolled(true);
      }
    } else if (!hash) {
      setScrolled(true);
    }

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (!!element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrolled, hash, scrollTo, contentBlocks]);

  if (!data) {
    return <Loading />;
  }

  if (
    data &&
    data[0] &&
    data[0].locale !== "nl-NL" &&
    !location.pathname.includes(`/${data[0].locale}/`)
  ) {
    return <Redirect to={`/${data[0].locale}${location.pathname}`} />;
  }

  const { closed, difference } = numberOfDaysToDeadline(
    data[0]?.deadline,
    true
  );

  const stickyHeaderText = () => {
    if (data[0].deadline_text !== null && data[0].deadline_text !== "") {
      return <div>{data[0].deadline_text}</div>;
    }

    if (!closed) {
      return (
        <p>
          <strong>
            <I18n t="deadline" />{" "}
          </strong>{" "}
          <br />
          <I18n t="timeLeft" args={difference} />
        </p>
      );
    }
  };

  if (error) {
    return <Error message={error.message} />;
  }

  if (!data[0]) {
    return <Error message={I18n.getTranslation(location, "error.missing")} />;
  }

  const globalProjectId =
    data[0].localizations[0]?.locale === "nl-NL"
      ? data[0].localizations[0].id
      : data[0].id;

  const formAvailable = (form) => {
    if (form?.form_id === undefined) {
      return false;
    }

    if (form?.form_id === null) {
      return false;
    }

    return true;
  };

  const localizeLink = () => {
    if (data[0].form_url !== null && data[0].form_url.trim() !== "") {
      if (
        data[0].form_url.startsWith("/") &&
        !data[0].form_url.startsWith("/en")
      ) {
        return true;
      }

      return false;
    }

    return true;
  };

  const applyLink = {
    missing: !formAvailable(form) && !data[0].form_url?.trim(),
    active:
      (!closed && formAvailable(form)) ||
      (!closed && data[0].form_url !== null && data[0].form_url.trim() !== ""),
    text: I18n.getTranslation(location, "inschrijven"),
    type:
      data[0].form_url !== null && data[0].form_url.trim() !== ""
        ? "external"
        : "internal",
    localizeLink: localizeLink(),
    target:
      data[0].form_url !== null && data[0].form_url.trim() !== ""
        ? data[0].form_url
        : `/project/${match.params.projectUrl}/apply`,
  };

  const goToReview = () => {
    history.push({
      pathname: match.url + "/apply/review",
    });
  };

  const showApplyButton = () => {
    return (
      !location.pathname.endsWith("apply") &&
      !location.pathname.endsWith("review")
    );
  };

  const showTimeLeftWarning =
    applyLink.active && !applyLink.missing && difference.days === 0;

  return (
    <StickyHeader
      noForm={!formAvailable(form)}
      activeForm={!showApplyButton()}
      name={data[0].name}
      stickyHeaderText={stickyHeaderText()}
      applyLink={applyLink}
    >
      <BaseLayout
        type="project"
        headerImage={
          data[0].thumbnail?.formats === null
            ? ""
            : `${getApiPrefix()}/${
                data[0].thumbnail.formats.medium?.url ??
                data[0].thumbnail.formats.small?.url ??
                data[0].thumbnail.url
              }`
        }
        headerPartner={data[0].partner}
        headerContent={
          <div>
            <ProjectLabels
              theme={data[0].thema}
              categories={data[0].project_categories}
            />

            <h2 className="no-margin">{data[0].name}</h2>
            <div className="project__meta__details">
              {data[0].contract_number && (
                <span>{data[0].contract_number}</span>
              )}
              {data[0].contract_number && data[0].CPV && (
                <span className="separator">|</span>
              )}
              {data[0].CPV && <span>{data[0].CPV}</span>}
            </div>

            <ReactMarkdown source={data[0].summary} />
            {showApplyButton() && (
              <div className="project__header_apply">
                <ApplyButton link={applyLink} />{" "}
                {showTimeLeftWarning && <I18n t="timeLeft" args={difference} />}
              </div>
            )}
          </div>
        }
        headerSDGs={data[0].SDGs}
        breadcrumbs={[{ title: data[0].name }]}
        headerShare={data[0]}
      >
        <div className="project_container" id="project_container">
          <div className="col1">
            <div className="stickyMenu">
              <div className="index-item">
                <OldSchoolMenuLink
                  title={<I18n t="projects.the" />}
                  activeOnlyWhenExact
                  to={{
                    pathname: `/project/${match.params.projectUrl}/`,
                    state: { scrollTo: "contentTop" },
                  }}
                  list={contentBlocks}
                  refs={elRefs}
                />
                {!!data[0].details?.length && (
                  <NavLink
                    to={{
                      pathname: `/project/${match.params.projectUrl}/details`,
                      state: { scrollTo: "contentTop" },
                    }}
                    className="item-link"
                    activeClassName="active"
                  >
                    <I18n t="projects.details" />
                  </NavLink>
                )}
                {data[0].ShowQuestions && (
                  <NavLink
                    to={{
                      pathname: `/project/${match.params.projectUrl}/qna`,
                      state: { scrollTo: "contentTop" },
                    }}
                    className="item-link"
                    activeClassName="active"
                  >
                    <I18n t="qna.qna" />
                  </NavLink>
                )}
                {/* Duplicate code in ApplyButton */}
                {applyLink.active === false ? (
                  <></>
                ) : applyLink.localizeLink ? (
                  <NavLink
                    to={{
                      pathname: "" + applyLink.target,
                      state: { scrollTo: "contentTop" },
                    }}
                    className="item-link"
                    activeClassName="active"
                  >
                    {applyLink.text}
                  </NavLink>
                ) : (
                  <a className="item-link" href={applyLink.target}>
                    {applyLink.text}
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="col2">
            <div id="contentTop" />
            <Switch>
              <Route exact path="/:language?/project/:project/">
                {contentBlocks.map((block, index) => {
                  return (
                    <div key={index} id={`anchor${index}`} ref={elRefs[index]}>
                      <ContentList
                        projectInfo={data[0]}
                        contentitems={block.list}
                        partners={data[0].partners}
                      />
                    </div>
                  );
                })}
              </Route>

              <Route exact path="/:language?/project/:project/details">
                <ProjectDetails project={data[0]} />
              </Route>

              <Route
                path="/:language?/project/:project/qna"
                component={(props) => (
                  <QnaProject project={data[0]} {...props} />
                )}
              />

              <Route exact path="/:language?/project/:project/apply/review">
                <ApplyReview
                  slug={match.params.projectUrl}
                  type="project"
                  queryId={globalProjectId}
                />
              </Route>

              <Route exact path="/:language?/project/:project/apply">
                <Apply
                  slug={match.params.projectUrl}
                  type="project"
                  queryId={globalProjectId}
                />
              </Route>
            </Switch>

            <Divider />
          </div>

          <aside className="col3">
            <Route exact path="/:language?/project/:project/apply">
              <div className="buttons-right-side">
                {data[0].Formulier_document !== null &&
                  data[0].Formulier_document.url !== null && (
                    <a
                      href={`/strapi${data[0].Formulier_document.url}`}
                      className="button__apply"
                    >
                      <I18n t="forms.download" />
                    </a>
                  )}

                <button className="button__apply" onClick={() => goToReview()}>
                  <I18n t="leesweergave" />
                </button>
              </div>
            </Route>
          </aside>
        </div>
      </BaseLayout>
    </StickyHeader>
  );
}
