import React from "react";

export default function Radio(props) {
  const properties = props.field.properties;
  const radios = properties.options.list;

  const radioItems = radios.map((radio, index) => {
    if (!radio.deleted && radio.id === parseInt(props.value)) {
      return <p key={index}>{radio.nl}</p>;
    }
    return null;
  });

  return (
    <div className="item-text">
      <h3>{properties.label.nl}</h3>
      {radioItems}
    </div>
  );
}
