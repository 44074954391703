import React from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import I18n from "../i18n";
import AuthenticatedAnswerRoute from "../components/AuthenticatedAnswerRoute";

import Home from "../pages/home";
import HomePublicSector from "../pages/HomePublicSector";
import Project from "../pages/project";
import Loket from "../pages/loket";
import AnswerQuestion from "../pages/answerquestion";
import QuestionOverview from "../pages/questionoverview";
import CategoriesOverview from "../pages/categoriesoverview";
import Partner from "../pages/partner";
import QnaLogin from "../pages/QnaLogin";
import AccountUpdateMail from "../pages/updatemail";
import AccountRegisterSMS from "../pages/sms";
import Dashboard from "../pages/dashboard";
import Company from "../pages/company";
import Profile from "../pages/profile";
import NotFound from "../pages/notfound";
import Blog from "../pages/blog";
import Blogs from "../pages/blogs";
import Info from "../pages/info";
import Participate from "../pages/participate";

const SiteRouter = () => {
  const location = useLocation();

  const locationScrollState = location?.state?.scrollTo !== undefined;
  const pathname = location.pathname;
  const base = "/:locale(nl|en)?";
  const blogsRoute = I18n.getTranslation(location, "blogs.path");
  const aboutRoute = I18n.getTranslation(location, "aboutBasePath");
  const infoRoute = I18n.getTranslation(location, "infoBasePath");
  const participatePath = I18n.getTranslation(location, "participatePath");
  const publicSectorPath = I18n.getTranslation(location, "publicSectorPath");
  const aboutUsPath = I18n.getTranslation(location, "aboutUsPath");

  React.useEffect(() => {
    // scroll to top on path changes without anchors
    if (!pathname.includes("#") && !locationScrollState) {
      window.scrollTo(0, 0);
    }

    // google analytics on each location change
    if (!window.gtag) {
      console.log("No tracking ID found, check index.html");
      return;
    }
    if (!window.trackingID) {
      console.log("No tracking ID found, check index.html");
      return;
    }
    window.gtag("config", window.trackingID, { page_path: pathname });
  }, [pathname, locationScrollState]);

  return (
    <Switch>
      {/* public routes */}
      <Route path={base} exact component={Home} />
      <Route
        path={`${base}/${publicSectorPath}`}
        component={HomePublicSector}
      />
      <Route path={`${base}/${participatePath}`} component={Participate} />
      <Route path={`${base}/project/:projectUrl`} component={Project} />
      <Route path={`${base}/loket/:loketUrl`} component={Loket} />
      <Redirect
        exact
        from={`${base}/${aboutRoute}`}
        to={`${base}/${infoRoute}/${aboutUsPath}`}
      />
      <Route exact path={`${base}/${aboutRoute}/:slug`} component={Partner} />
      <Route exact path={`${base}/${blogsRoute}`} component={Blogs} />
      <Route exact path={`${base}/${blogsRoute}/:slug`} component={Blog} />

      {/* main info route */}
      <Route exact path={`${base}/${infoRoute}/:slug`} component={Info} />

      {/* Answer questions accounts (strapi users) */}
      <AuthenticatedAnswerRoute
        path={`${base}/answer/:slug`}
        component={AnswerQuestion}
      />
      <AuthenticatedAnswerRoute
        exact
        path={`${base}/answer`}
        component={QuestionOverview}
      />
      <AuthenticatedAnswerRoute
        exact
        path={`${base}/categories/:id`}
        component={CategoriesOverview}
      />
      <Route exact path={`${base}/login`} component={QnaLogin} />

      {/* Business accounts (siteboss users) */}
      <Route
        exact
        path={`${base}/account/register/:emailkey`}
        component={AccountRegisterSMS}
      />
      <Route
        exact
        path={`${base}/account/update/:emailkey`}
        component={AccountUpdateMail}
      />
      <Route exact path={`${base}/account/profile`} component={Profile} />

      <Route exact path={`${base}/account/company`} component={Company} />
      <Redirect from={`${base}/account/login`} to={`${base}/account`} />
      <Redirect from={`${base}/account/register`} to={`${base}/account`} />
      <Route exact path={`${base}/account`} component={Dashboard} />

      {/* fallback */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default SiteRouter;
