import React, { useEffect } from "react";
import { useStaleSWR, makeSiteBossURL } from "../../utils";
import { useForm } from "react-hook-form";
import axios from "axios";
import AccountModal from "../../components/AccountModal";
import BaseLayout from "../../components/baselayout";
import Loading from "../../components/loading";
import Error from "../../components/error";

import Checkbox from "../../components/apply/fields/checkbox";
import DateComp from "../../components/apply/fields/date";
import Dropdown from "../../components/apply/fields/dropdown";
import Email from "../../components/apply/fields/email";
import File from "../../components/apply/fields/file";
import Header from "../../components/apply/fields/header";
import Info from "../../components/apply/fields/info";
import Number from "../../components/apply/fields/number";
import Radio from "../../components/apply/fields/radio";
import Text from "../../components/apply/fields/text";
import Textarea from "../../components/apply/fields/textarea";
import Toggle from "../../components/apply/fields/toggle";
import MultipleChoice from "../../components/apply/fields/multiplechoice";
import AccountSidebar from "../../components/AccountSidebar";

import useAccordionManager from "../../components/apply/form/accordionManager";
import I18n from "../../i18n";

export default function AccountCompany({ history, location }) {
  const { data: user } = useStaleSWR(makeSiteBossURL(`/account/`), true);
  const userEmail = user?.email;

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    control,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const {
    setAccordionOpen,
    accordionDone,
    setAccordionDone,
    isAccordionOpen,
    getCurrentAccordionOpenIndex,
  } = useAccordionManager();

  const url = makeSiteBossURL("/user/company-profile/");

  const {
    data: projectInfo,
    error,
    mutate,
  } = useStaleSWR(url, true, false, true);

  useEffect(() => {
    if (projectInfo && projectInfo.data) {
      const formFields = projectInfo.fields?.data[0]?.fields;
      const storedValues = projectInfo.data.data;
      let defaultValuesWithoutFiles = {};

      // Get current form data if available
      if (storedValues) {
        for (let key in storedValues) {
          if (key !== "siteboss-page" && storedValues[key].type !== "file") {
            setValue(key, storedValues[key].value);
            defaultValuesWithoutFiles[key] = storedValues[key].value;
          }
        }
      }

      // add user email as company contact email by default
      if (formFields) {
        const emailFieldIdentifier = "contact_email";
        const emailField = formFields.find(
          (field) => field.id === emailFieldIdentifier
        );
        if (!!emailField && !storedValues[emailFieldIdentifier] && userEmail) {
          setValue(emailFieldIdentifier, userEmail);
        }
      }

      reset(defaultValuesWithoutFiles, { keepValues: true });

      if (storedValues["siteboss-page"]) {
        setAccordionDone(storedValues["siteboss-page"]);
      }
      setAccordionOpen(["0-page"]);
    } else {
      setAccordionOpen(["0-page"]);
    }
  }, [
    projectInfo,
    reset,
    setAccordionDone,
    setAccordionOpen,
    setValue,
    userEmail,
  ]);

  const scrollTo = location.state?.scrollTo;
  React.useEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (!!element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollTo]);

  if (
    localStorage.getItem("siteboss_client_token") === null ||
    localStorage.getItem("siteboss_client_token") === ""
  ) {
    return <AccountModal />;
  }

  if (error) {
    return <Error message={error.message} />;
  }

  if (!projectInfo) {
    return <Loading />;
  }

  const formFields = projectInfo.fields;

  if (!formFields) {
    return (
      <div>
        <I18n t="forms.no" />
      </div>
    );
  }

  const componentList = {
    checkbox: Checkbox,
    dropdown: Dropdown,
    challenges: Dropdown,
    date: DateComp,
    email: Email,
    file: File,
    header: Header,
    info: Info,
    number: Number,
    radio: Radio,
    text: Text,
    textarea: Textarea,
    toggle: Toggle,
    thema: MultipleChoice,
  };

  const onSubmit = (data) => {
    var formData = new FormData();
    for (var key in data) {
      if (data[key] instanceof Date) {
        const formattedDate = data[key].toISOString().substring(0, 10);
        formData.append(key, formattedDate);

        continue;
      }

      if (data[key] !== null && typeof data[key] === "object") {
        for (var i = 0; i < data[key].length; i++) {
          formData.append(key + "[]", data[key][i]);
        }
        continue;
      }

      formData.append(key, data[key]);
    }

    // set the appropriate combinations as done
    let currentIndexOpen = getCurrentAccordionOpenIndex();
    let tmpAccordionDone = [...accordionDone];
    if (!accordionDone.includes(currentIndexOpen)) {
      tmpAccordionDone.push(currentIndexOpen);

      formData.append("siteboss-page", tmpAccordionDone);
    }

    // Set percentage
    let percentage = (tmpAccordionDone.length / formFields.data.length) * 100;
    if (percentage === undefined) percentage = 0;
    if (percentage > 100) percentage = 100;
    percentage = Math.floor(percentage);
    formData.append("siteboss-progress", percentage);

    // If it is complete
    if (currentIndexOpen === formFields.data.length - 1) {
      formData.append("siteboss-done", true);
    }

    axios
      .post(url, formData, {
        contentType: false,
        processData: false,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "siteboss_client_token"
          )}`,
        },
      })
      .then((response) => {
        if (response.data.status === "ok") {
          const next = localStorage.getItem("goToProject");
          if (next !== null) {
            alert(
              I18n.getTranslation(location, "account.edit.accountComplete")
            );

            history.push(next);
            localStorage.removeItem("goToProject");
          } else {
            alert(I18n.getTranslation(location, "saved"));
          }

          //Get SWR again
          mutate();
        } else {
          alert(I18n.getTranslation(location, "somethingWrong"));
        }
      })
      .catch((error) => {
        console.log("error" + error);
        alert(I18n.getTranslation(location, "forms.submitFail"));
      });
  };

  return (
    <BaseLayout
      type="project"
      breadcrumbs={[{ title: <I18n t="yourProfile" /> }]}
      headerContent={
        <>
          <h2>
            <I18n t="account.companyProfile" />
          </h2>
          <p>
            <I18n t="account.companyProfileRequired" />
          </p>
        </>
      }
    >
      <div className="account-content">
        <div className="project_container" id="project_container">
          <AccountSidebar />
          <div className="col2">
            <div id="contentTop" />
            <div className="signin-accordion">
              <div className="signin-timeline-title">
                <h2>
                  <I18n t="account.companyProfile" />
                </h2>
                {accordionDone === 0 ? (
                  <p>
                    <I18n t="account.companyProfileExplain" />
                  </p>
                ) : (
                  <p>
                    <I18n t="account.companyProfileText" />
                  </p>
                )}
              </div>

              {formFields.data.map((combination, index) => {
                return (
                  <form
                    key={`form${index}`}
                    onSubmit={handleSubmit(onSubmit)}
                    data-id={combination.id}
                    className="company__form"
                  >
                    {combination.fields.map((field) => {
                      const name = field.type.toLowerCase();
                      const Type = componentList[name];

                      return (
                        <Type
                          key={field.id}
                          field={field}
                          register={register}
                          active={isAccordionOpen(index)}
                          error={errors[field.id]}
                          setValue={setValue}
                          getValues={getValues}
                          alertStatus={dirtyFields[field.id]}
                          control={control}
                        />
                      );
                    })}

                    <div className="buttons btn-inside-itemPanel">
                      <button
                        className="button__apply button-mobile button-right"
                        type="submit"
                      >
                        <I18n t="save" />
                      </button>
                    </div>
                  </form>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
