/* eslint-disable react/jsx-no-target-blank */
import React from "react";

export default function Link(props) {
  const properties = props.field.properties;

  return properties.document === true ? (
    <a href={properties.url.nl} className="download__bar">
      <div className="text__part">
        <img src="/icons/file.svg" alt="download icon" />
        <p>{properties.label.nl}</p>
      </div>
      <img src="/icons/download-arrow-white.svg" alt="download arrow" />
    </a>
  ) : (
    <p>
      <a className="external__link" href={properties.url.nl} target="_blank">
        {properties.label.nl}
      </a>
    </p>
  );
}
