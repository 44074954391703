import React from "react";
import "./styles.scss";

const IconList = ({ children, color = "default" }) => {
  return (
    <ul className="iconlist">
      {React.Children.map(children, (child) => {
        const content = child.props.children;
        const type = child.props.type ? child.props.type : "default";

        return (
          <li className={`${type} colors-${color}`}>
            <span>{content}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default IconList;
