import React, { useState, useEffect } from "react";
import "./styles.scss";

const TimedToast = (props) => {
  const [toastClass, setClass] = useState("closed");

  const closeToast = () => {
    setClass("closed");
  };

  useEffect(() => {
    const timer1 = setTimeout(() => setClass("open"), 5000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div className={`timedToast ${toastClass}`}>
      <div className="content">{props.children}</div>
      <button className="icon" onClick={closeToast}>
        <img src="/icons/close.svg" alt="close"></img>
      </button>
    </div>
  );
};

export default TimedToast;
