import React from "react";
import Divider from "../divider";
import CollapseBox from "../../components/collapsebox";
import { parseDate } from "../../utils";
import I18n from "../../i18n";

import "./styles.scss";

export default function QnaQuestionBlock(props) {
  const { question, categoryCode } = props;

  if (question.status === "deleted") {
    return (
      <div>
        <div className="item__question">
          <h4>
            <I18n t="qna.question" />{" "}
            {categoryCode + "." + question.questionCode}
          </h4>
          <em>
            <I18n t="qna.questionDeleted" />
          </em>
        </div>

        <Divider />
      </div>
    );
  }

  return (
    <div>
      <div className="item__question">
        <h4>
          <I18n t="qna.question" /> {categoryCode + "." + question.questionCode}
        </h4>
        <p>{question.question}</p>
      </div>

      <CollapseBox title={<I18n t="qna.answer" />}>
        {question.answer}
      </CollapseBox>

      <small className="question__caption">
        <I18n
          t="qna.questionSubmitted"
          args={{
            dateCreated: parseDate(question.created_at),
            datePublished: parseDate(question.published_date),
          }}
        />
      </small>

      <Divider />
    </div>
  );
}
