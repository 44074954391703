import React from "react";
import "./styles.scss";

const ProjectLabels = ({ theme, categories = [] }) => {
  const themeString = theme?.label;
  const categoryStrings = categories?.reduce((strings, category) => {
    if (!!category?.label) strings = strings.concat([category.label]);
    return strings;
  }, []);

  if (!themeString && !categoryStrings.length) return null;
  return (
    <div className="projectlabels__container">
      {themeString && <div className="projectlabels__theme">{themeString}</div>}
      {categoryStrings.map((category, index) => (
        <div key={index} className="projectlabels__category">
          {category}
        </div>
      ))}
    </div>
  );
};

export default ProjectLabels;
