import React from "react";
import "./styles.scss";

const FinanceBox = ({ title = "", text = "", budget = "" }) => {
  return (
    <div className="financebox">
      <div className="financebox__content">
        <div className="financebox__text">
          <h4>{title}</h4>
          <span className="financebox__date">{text}</span>
        </div>

        <div className="financebox__countdown">
          <span>{budget}</span>
        </div>
      </div>
    </div>
  );
};

export default FinanceBox;
