// Preferably do do not use imports here;
// Cypress will load this file for tests.

const nl = {
  result(number) {
    if (number === 0) {
      return `geen resultaten`;
    }
    if (number === 1) {
      return `1 resultaat`;
    }
    return `${number} resultaten`;
  },
  days(number) {
    if (number === 1) {
      return `dag`;
    }
    return `dagen`;
  },
  timeLeft({ days, hours, minutes }) {
    if (days > 0) {
      const dayText = days === 1 ? "dag" : "dagen";
      return `nog ${days} ${dayText}`;
    }
    if (days === 0) {
      if (hours > 0) {
        const hourText = hours === 1 ? "uur" : "uren";
        return `nog ${hours} ${hourText}`;
      }
      if (hours === 0 && minutes > 0) {
        const minuteText = minutes === 1 ? "minuut" : "minuten";
        return `nog ${minutes} ${minuteText}`;
      }
    }
    return "deadline verstreken";
  },
  questions(number) {
    if (number === 1) {
      return `Eén vraag`;
    }
    return `${number} vragen`;
  },
  passwordComplexityHint:
    "Kies een veilig wachtwoord van ten minste tien tekens waaronder hoofdletters en speciale tekens.",
  timeAt: "om",
  readmore: "Lees meer",
  home: "Home",
  about: "Over Innovatiepartners",
  partners: "Partners",
  inschrijven: "Inschrijven",
  leesweergave: "Leesweergave",
  openInschrijven: "Open de inschrijving",
  terugNaarInfo: "Terug naar de informatie",
  yourProfile: "Mijn account",
  yourApplications: "Mijn inschrijvingen",
  cancel: "Annuleren",
  change: "Wijzig",
  save: "Opslaan",
  applyDeadline: "Deadline Inschrijven",
  downloadNoFile: "Nog geen bestand toegevoegd in Strapi",
  noContent: "no match",
  togo: "nog",
  somethingWrong: "Er is iets mis gegaan, probeer opnieuw.",
  saved: "Je wijzigingen zijn opgeslagen",
  matchmaker: "Matchmaker",
  matchmakerFind: "Vind een match.",
  calendar: {
    add: "voeg toe aan mijn agenda",
    eventTitle: ({ project }) => (project ? `Deadline ${project}` : "Deadline"),
    eventDescription: ({ project }) =>
      `Aan het einde van dit uur sluit de inschrijving voor het project${
        project && ` "${project}"`
      }.`,
  },
  participatePath: "meedoen",
  publicSectorPath: "gemeente",
  aboutUsPath: "over-innovatiepartners",
  aboutBasePath: "over",
  infoBasePath: "info",

  footer: {
    aboutIP:
      "Innovatiepartners betrekt ondernemers bij het oplossen van uitdagingen van publieke en maatschappelijke organisaties.",
    newsletterHeader: "Meld je aan voor onze nieuwsbrief",
    newsletterSubheader:
      "Blijf op de hoogte van nieuwe projecten en de kwartaal nieuwsbrief",
    terms: "Terms of Service",
    termsLink: "/info/over-innovatiepartners",
    linkedInLabel: "/innovatiepartners-nl",
    linkedIn: "https://www.linkedin.com/company/innovatiepartners-nl",
    privacy: "Privacy Policy",
    privacyLink:
      "https://www.amsterdam.nl/privacy/specifieke/privacyverklaringen-ondernemen/privacy-innovatiepartners/",
    cookies: "Cookie Policy",
    cookiesLink: "/info/cookies-op-deze-site-en-privacy/",
    copyright: ({ year }) => `Copyright ${year}`,
    title: "Innovatiepartners",
  },

  newsletter: {
    follow: "Volg dit project",
    submit: "Aanmelden",
    submitSucces:
      "Leuk dat je op de hoogte gehouden wil worden van Innovatiepartners. Je hoort van ons!",
    name: "Naam",
    email: "E-mailadres",
    addGeneral:
      "Ja, ik blijf graag ook op de hoogte van nieuwe projecten en de kwartaal nieuwsbrief van innovatiepartners.nl.",
  },

  logout: "Log uit",
  logoutWarning: "Weet je zeker dat je wil uitloggen?",
  logoutConfirm: "Ja",
  logoutDeny: "Annuleren",
  back: "Terug",

  login: {
    incorrectCredentials: "Inloggegevens onjuist",
    title: "Inloggen",
    text: "Vul je e-mailadres en wachtwoord in. Je ontvangt een sms ter controle.",
    codeIncorrect: "Voer de zescijferige code in.",
    loginAction: "Log in",
    email: "E-mailadres",
    // deepcode ignore NoHardcodedPasswords: Translation
    password: "Wachtwoord",
    createAccount: "Account aanmaken",
    backToLogin: "Terug naar inloggen",
    registerEmailText:
      "Je krijgt een e-mail toegestuurd. Klik op de button in de e-mail om je account te bevestigen.",
    confirmCreate: "Aanmaken",
    confirmEmail: "Bevestig e-mailadres",
    getEmail:
      "Je krijgt een e-mail toegestuurd. Klik op de button in de e-mail om je e-mailadres te bevestigen.",
    noEmailConfirmation:
      "Heb je na 15 minuten nog geen e-mail ontvangen? Neem dan contact op met ",

    phone: "Telefoonnummer",
    phoneText:
      "Om in te loggen op Innovatiepartners moet je behalve je e-mailadres en wachtwoord ook altijd een code invoeren. Deze code ontvang je per sms. Voer je telefoonnummer in.",
    phoneIncorrect: "Vul hier een geldig telefoonnummer in (zonder spaties)",
    confirmSms: "Stuur sms",

    supplyEmail: "Voer e-mailadres in",

    supplySms: "Voer SMS-code in",
    smsReceive: "Voer de code in die je per sms hebt ontvangen.",
    smsIncorrect: "Voer de zescijferige verificatiecode in.",
    smsCheck: "Controleer",

    passwordSafe: "Kies een veilig wachtwoord.",
    passwordRepeat: "Herhaal wachtwoord",
    passwordConfirm: "Stel wachtwoord in",
    passwordRequirements: "Kies een wachtwoord van minimaal 10 tekens.",
    done: "Klaar!",
    loggedIn: "Je bent nu ingelogd.",
    toProfile: "Naar profiel",

    sms: "Sms-controle",
    smsText:
      "Vul je e-mailadres in. Je krijgt ter controle een sms op het telefoonnummer dat bij het e-mailadres hoort.",
    loggedInPasswordChanged:
      "Je nieuwe wachtwoord is ingesteld en je bent nu ingelogd. ",
  },

  account: {
    needAccount:
      "Om je in te schrijven voor een project heb je een account nodig. Nog geen account?",
    companyProfile: "Bedrijfsprofiel",
    companyProfileText: "Vul hieronder je bedrijfsprofiel in.",
    companyProfileExplain:
      "Je kunt hieronder je bedrijfsprofiel aanpassen, aanpassingen die je maakt voor de deadline van een aanbesteding worden automatisch meegestuurd.",
    companyProfileRequired:
      "Om je in te schrijven voor een project heb je een ingevuld bedrijfsprofiel nodig. Vul je gegevens in. Je kunt deze gegevens op elk moment aanpassen.",
    noProjects: "Nog geen projecten",
    noProjectsText:
      "Staat hier niets? Dan ben je nog niet aan een inschrijving begonnen.",
    noProjectsLook: "Bekijk de projecten waar je je nu voor kunt inschrijven.",
    editEmail: "Wijzig e-mailadres",
    emailEdited: "Wijziging E-mailadres",
    emailEditedSucces: "De wijziging is met succes doorgevoerd",
    emailEditedFail: "Probleem",
    edit: {
      profile: "Hier kun je je accountgegevens wijzigen.",
      editPassword: "Wachtwoord wijzigen",
      editPasswordText: "Kies een nieuw wachtwoord",
      newPassword: "Nieuw wachtwoord",
      passwordCharacters: "Voer een nieuw wachtwoord in van minimaal 10 tekens",
      currentPassword: "Huidig wachtwoord",
      currentPasswordError: "Voer je huidige wachtwoord in.",
      editEmail: "E-mailadres wijzigen",
      editEmailText:
        "Vul een nieuw e-mailadres in. Je krijgt een e-mail toegestuurd. Klik op de button in de e-mail om je nieuwe e-mailadres te bevestigen.",
      nowEmailText: "Je bent nu ingelogd als: ",
      newEmail: "Nieuw E-mailadres",
      correctEmailText: "Voer een geldig e-mailadres in",
      editPhone: "Telefoonnummer wijzigen",
      editPhoneText: "Wijzig hier je telefoonnummer",
      newCodeText: "Voer de juiste code in.",
      confirmCode: "Bevestig code",
      newPhone: "Nieuw telefoonnummer",
      newPhoneText: "Voer een nieuw telefoonnummer in",
      deleteAccount: "Account verwijderen",
      deleteAccountText:
        "Als je je account verwijdert, worden alle gegevens die aan je account gekoppeld zijn verwijderd. Als je gegevens gebruikt worden in een lopende inschrijving, kun je je gegevens nog niet verwijderen.",
      deleteAccountSure: "Weet je het zeker?",
      deleteAccountIrreversible:
        "Je account word verwijderd. Deze actie is onomkeerbaar.",

      accountDeleted: "Je account is verwijderd, je wordt nu uitgelogd",

      accountComplete:
        "Je bedrijfsprofiel is compleet, je kunt nu verder gaan met het inschrijven.",
    },

    applications: {
      status: "Bekijk de status van je inschrijvingen.",
    },
  },

  loading: {
    moment: "Momentje...",
    loading: "Gegevens worden geladen.",
  },

  forms: {
    choose: "Kies...",
    placeholderMultiplechoice: "Selecteer één of meerdere opties",
    noOptions: "geen opties",
    no: "Geen formulier beschikbaar.",
    download: "Download vragen",
    submittedPercentage: "100% voltooid en verzonden",
    seeAndEdit: "Bekijk en wijzig",
    see: "Bekijk",
    submitFail: "Er vindt een onbekende fout plaats tijdens het opslaan",
  },

  menu: {
    switchTo: "Switch to",
    initiativeOf: "Een initiatief van",
    close: "Sluiten",
    expand: "Menu",
    nl: "Nederlands",
    eng: "English",
    dashboard: "Mijn inschrijvingen",
    logout: "Uitloggen",
    participate: "Meedoen",
    lokets: "Loketten",
    procedures: "Hoe werkt het?",
    fairness: "Eerlijk inkopen",
    procedure: "Aanbestedingsprocedures",
    glossary: "Van Aanbesteden tot Z",
    checklist: "Checklist",
    form: "UEA formulier",
  },

  projects: {
    type: ({ type }) => type,
    share: ({ type }) => `Deel dit ${type} via`,
    details: "Detailsoverzicht",
    shareMailSubject: ({ type, title }) =>
      `Bekijk dit ${type} op Innovatiepartners.nl: "${title}"`,
    shareMailBody: ({ title, url }) =>
      `Hier is de link naar "${title}": ${url}`,
    shareWA: ({ title, url }) => `Bekijk "${title}": ${url}`,
    procedures: "Wat is aanbesteden",
    the: "Het project",
    projects: "Projecten",
    closed: "Gesloten",
    showPrevious: "Bekijk eerdere projecten",
    unableToApply: "Inschrijven voor deze projecten is niet meer mogelijk.",
    headerSDGs: "Dit project draagt bij aan de Duurzame Ontwikkelingsdoelen",
    headerTriggerSDGs: "SDG's",
    infoHeaderSDGs: "Duurzame Ontwikkelingsdoelen",
    infoSDGs:
      "De Duurzame Ontwikkelingsdoelstellingen zijn in 2015 door de Verenigde Naties vastgesteld als de nieuwe mondiale duurzame ontwikkelingsagenda voor 2030.",
    linkSDGs: "Lees meer",
    urlSDGs: "https://www.sdgnederland.nl/de-17-sdgs/",
  },

  answerQuestion: "Vraag beantwoorden",

  qna: {
    results(results) {
      if (results > 1)
        return `
      Er zijn ${results} resultaten voor deze
      zoekopdracht:`;
      return `Er is een resultaat voor deze zoekopdracht:`;
    },
    questions(number) {
      if (number === 0) {
        return `geen vragen`;
      }

      if (number === 1) {
        return `1 vraag`;
      }

      return `${number} vragen`;
    },
    questionSubmitted({ dateCreated, datePublished }) {
      return `Vraag ingediend op ${dateCreated}, antwoord gepubliceerd op ${datePublished}.`;
    },
    question: "Vraag",
    questionDeleted: "Deze vraag is verwijderd",
    questionsNoCategory: "Vragen zonder categorie",
    noQuestions: "Er zijn nog geen vragen gesteld voor dit project.",
    answer: "Antwoord",
    qna: "Vraag en antwoord",
    portal: "Vraag en antwoord portaal",
    qnaSimpleText:
      "Bekijk de vragen die gesteld zijn over het project of stel je eigen vraag via het vragenformulier onderaan de pagina.",
    qnaText:
      "Bekijk de vragen die gesteld zijn over het project of stel je eigen vraag via het vragenformulier onderaan de pagina. Voor aanbesteding-insiders: deze vraag en antwoord pagina is de nota van inlichtingen.",
    search: "Zoek naar een onderwerp",
    searchresult: "Resultaten zoekopdracht",
    none: "Geen vragen/ antwoorden gevonden.",
    downloadWord: "Download als Word",
    downloadPdf: "Download als PDF",
    downloadPdfText:
      "Alle vragen en antwoorden zijn verzameld in een PDF. Ideaal als je ze graag wilt printen. Dit is de nota van inlichtingen.",
    all: "Alle vragen en antwoorden",
    allFind: "Vind alle vragen & antwoorden",
    closed: "Gesloten",
    closeDate: (date) => `(sluitingsdatum ${date})`,
    open: "Open",
    answerForm: {
      who: "Wie",
      afterPublication: "Volgt na publicatie",
      noNameError: "Geef een naam op!",
      noCategoryError: "Kies of maak een categorie.",
      name: "Naam",
      email: "E-mailadres",
      org: "Organisatie",
      submitTime: "Tijdstip indienen",
      removed: "Verwijderd",
      questionNumber: "Vraagnummer",
      publicationNumber: "Publicatienummer",
      publicationTime: "Tijdstip publicatie",
      category: "Categorie",
      categoryNew: "nieuw",
      categoryNewLabel: "Categorie naam",
      categoryNewSave: "opslaan",
      publish: "Publiceer",
      save: "Bewaar",
      remove: "Verwijder",
      publishWarning:
        "Weet je het zeker? Je kunt de vraag en het antwoord hierna niet meer aanpassen.",
      publishConfirm: "Ja, ik wil nu publiceren",
      removeWarning: "Weet je zeker dat je deze vraag wil verwijderen?",
      confirm: "Ja",
    },
    form: {
      noMore: "Je kunt geen vragen meer stellen over dit project.",
      ask: "Stel je vraag",

      text(deadline) {
        return `Je kunt vragen stellen over de inhoud en de procedure van het project. Alle vragen en antwoorden worden anoniem op deze pagina gepubliceerd. ${
          !!deadline ? `Je kunt vragen stellen tot ${deadline}.` : ""
        }`;
      },
      name: "Mijn naam is...",
      org: "Mijn bedrijf is...",
      tel: "Mijn telefoonnummer is...",
      question: "Mijn vraag is...",
      email: "Mijn e-mailadres is...",
      privacy: "Ja, ik ga akkoord met ",
      privacyDocument: "de privacyverklaring.",
      privacy2:
        "Ja, ik ga ermee akkoord dat mijn naam en e-mailadres gebruikt kunnen worden om contact met mij op te nemen over mijn vraag.",
      anonymous: "Alle vragen worden anoniem gepubliceerd.",
      required: "Dit is een verplicht veld",
      maxLength: "Geef een korter antwoord alsjeblieft",
      correctEmail: "Vul hier een geldig e-mailadres in",
      correctTelephone: "Vul hier een geldig telefoonnummer in",
      privacyRequired: "Om een vraag te kunnen stellen moet je akkoord gaan.",
      close: "Sluit venster",
      thanks:
        "Dank je wel voor je vraag. Je ontvangt een kopie van je vraag op het opgegeven e-mailadres.",
      timeline:
        "Kijk in de tijdlijn van dit project wanneer de vragen uiterlijk worden beantwoord. De antwoorden verschijnen op de Vraag en antwoord-pagina van het project.",
      follow: "houd mij op de hoogte over dit project",
    },
  },

  createAccount: "Maak hier jouw account aan",
  forgotPassword: "Wachtwoord vergeten?",
  error: {
    error: "Error",
    unknown: "Er is een onbekende fout opgetreden",
    missing: "Gegevens niet gevonden",
    serverConnection:
      "Probleem bij het verbinden met de server, probeer het opnieuw.",
    loadingPage: "Probleem met het laden van de pagina",
    notFoundPage: "Pagina niet gevonden",
    notFoundCheck: "Contoleer het adres",
    loading: "Probleem met laden van gegevens",
    contact: "Neem contact met ons op als deze fout aanhoudt.",
  },

  deadline: "Deadline",

  apply: {
    retract: "Wijzig inschrijving",
    review: "Bekijk inschrijving",
    receivedTitle: "Gelukt! We hebben je inschrijving ontvangen.",
    receivedText: "Je krijgt een e-mail met een ontvangstbevestiging.",
    apply: "Inschrijven",
    required: "Dit veld is vereist",
    correctKvK: "vul een KvK-nummer in",
    emailRequired: "Dit veld vereist een correct e-mailadres.",
    numberRequired: "Dit veld vereist een nummer",
    deadlinePassedTitle: "De deadline is voorbij",
    deadlinePassed:
      "Heb je je inschrijving verstuurd? Duimen maar! Als je je inschrijving nog niet hebt verstuurd, kun je dat nu helaas niet meer doen.",
    deadlinePassedReview:
      "De deadline is voorbij. Je kunt je inschrijving niet meer versturen, wijzigen of intrekken.",
    leaveWithoutSaveWarning:
      "Als je dit blok niet opslaat gaan je wijzigingen verloren. Weet je het zeker?",
    saveAndNext: "Bewaar en naar volgende vraag",
    answerQuestions:
      "Geef antwoord op de vragen om je in te schrijven voor dit project.",
    viewCompanyProfile:
      "Bekijk je bedrijfsprofiel om te kijken of alles nog klopt.",
    toCompanyProfile: "Naar bedrijfsprofiel",
    toApplication: "Naar inschrijfformulier",
    readyToSubmit:
      "Je hebt alles ingevuld en kunt je aanmelding versturen! Controleer nog één keer je antwoorden.",
    readyToSubmitBtn: "Controleer antwoorden",
    submit: "Inschrijving versturen",
    applicationClosed: "De deadline is voorbij",
    applicationIncomplete:
      "Je inschrijving is nog niet compleet. Beantwoord eerst alle verplichte vragen voordat je indient.",
    checkAndApply: "Controleren en versturen",
    completedPercent(percent) {
      return `Je hebt ${percent}% ingevuld`;
    },
    checkAnswers: "Bekijk je antwoorden.",
    loketSubmit: "Je kan nu je loket inschrijven.",
    loketSubmitButton: "Verstuur inschrijving",
    finalCheck:
      "Je bent er bijna! Controleer nog één keer je antwoorden en verstuur je inschrijving. Je kunt je inschrijving nog wijzigen of intrekken tot de deadline.",
    allGood:
      "Gelukt! We hebben je inschrijving ontvangen. Je kunt je inschrijving nog wijzigen of intrekken tot de deadline.",
    applied: "Verzonden",
    changeOrRetract:
      "We hebben je inschrijving ontvangen. Ga naar het inschrijfformulier om je inschrijving in te trekken, te wijzigen en opnieuw te versturen.",
    continue: "Ga verder met inschrijven",
    notComplete:
      "Je inschrijving is nog niet compleet. Beantwoord eerst alle verplichte vragen voordat je indient.",
    makeChange: "Wijzig inschrijving",
    sendApplication: "Verstuur inschrijving",
    applicationIsComplete: "Je inschrijving is compleet.",
    confirmTitle: "Wil je je inschrijving wijzigen?",
    confirmText: `Tot het moment van de deadline kun je je inschrijving nog wijzigen. Dit doe je bijvoorbeeld als je nog iets wilt toevoegen of een beter antwoord verzint. 
    Trek je inschrijving in, doe je aanpassing en dien je inschrijving opnieuw in.`,
    confirmText2: `Let op: als je je inschrijving wijzigt, trek je deze terug. Dien je inschrijving opnieuw in om weer mee te doen.
    Als je niet meer wilt meedoen, kun je je inschrijving ook intrekken en niet opnieuw indienen. Dit kan tot de deadline.`,
    loading: "Je inschrijving is aan het versturen.",
    loadingText: "Even geduld, Je inschrijving is aan het versturen.",

    deadline: "De deadline is voorbij",
    deadlineText:
      "Heb je je inschrijving verstuurd? Duimen maar! Als je je inschrijving nog niet hebt verstuurd, kun je dat nu helaas niet meer doen.",
    unacceptableFileType: "Dit bestandstype wordt niet geaccepteerd",
  },

  blogs: {
    path: "ervaringen",
  },

  contact: {
    send: "Versturen",
    question: "Laat ons weten waarmee wij jou kunnen helpen!",
  },
};
export default nl;
