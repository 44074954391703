import React from "react";

const Image = ({ src, alt = "", wrapper, fallback, ...props }) => {
  const [validSrc, setValidSrc] = React.useState(true);

  const wrapperDefault = (image) => image;
  const wrapperHoc = wrapper ?? wrapperDefault;

  if (!validSrc || !src) return fallback || null;
  return wrapperHoc(
    <img
      src={src}
      alt={alt}
      onError={() => {
        setValidSrc(false);
      }}
      {...props}
    />
  );
};

export default Image;
