import React from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import ArrowRight from "../../assets/icons/arrow_right_white.svg";
import Image from "../Image";

import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "./styles.scss";

const Carousel = ({ children }) => {
  if (children.length <= 1) return children;
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      loop
      autoHeight
      navigation={{
        nextEl: ".next",
        prevEl: ".prev",
      }}
      pagination={{ clickable: true, bulletElement: "div" }}
      scrollbar={{ draggable: true }}
    >
      <div className="prev swiper-button-prev">
        <Image src={ArrowRight} />
      </div>
      {children.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
      <div className="next swiper-button-next">
        <Image src={ArrowRight} />
      </div>
    </Swiper>
  );
};

export default Carousel;
