import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { makeSiteBossURL } from "../../utils";
import I18n from "../../i18n";

export default function AccountLogin(props) {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm();
  const onSubmit = (data) => {
    handleSubmitPost(data);
  };

  // handle login
  const handleSubmitPost = (data) => {
    setInvalidCredentials(false);

    // CAREFULL: Duplicate code in pages/login TODO:
    axios
      .post(makeSiteBossURL(`/account/login/`), data)
      .then((response) => {
        if (response.data.status === "code") {
          setShowCode(true);
        } else if (response.data.status === "ok") {
          localStorage.setItem("siteboss_client_token", response.data.token);
          axios.defaults.headers.post[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("siteboss_client_token")}`;
          window.location.reload();
        } else {
          setInvalidCredentials(response.data.message);
        }
      })
      .catch((error) => {
        setInvalidCredentials("De inloggegevens zijn incorrect.");
      });
  };

  React.useEffect(() => {
    setFocus("code");
  }, [setFocus, showCode]);

  return (
    <div className="container">
      <div className="loginContainer account-login">
        {showCode ? (
          <>
            <h2>
              <I18n t="login.sms" />
            </h2>
            <p>
              <I18n t="login.smsReceive" />
            </p>
          </>
        ) : (
          <>
            <h2>
              <I18n t="login.title" />
            </h2>
            <p>
              <I18n t="login.text" />
            </p>
          </>
        )}

        <div className="login">
          <div className="invalidCredentials">{invalidCredentials}</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={showCode === false ? "form-group" : "hidden"}>
              <label htmlFor="email">
                <input
                  // type="email"
                  placeholder={I18n.getTranslation(
                    window.location,
                    "login.email"
                  )}
                  className="form-control"
                  id="emailLogin"
                  aria-describedby="email"
                  name="email"
                  {...register("email", {
                    required: true,
                    type: "email",
                    pattern: /^\S+@\S+$/,
                    maxLength: 100,
                  })}
                />
              </label>

              {errors.email && (
                <span className="invalidCredentials email">
                  <I18n t="qna.form.correctEmail" />
                </span>
              )}
            </div>

            <div className={showCode === false ? "form-group" : "hidden"}>
              <label htmlFor="password">
                <input
                  type="password"
                  placeholder={I18n.getTranslation(
                    window.location,
                    "login.password"
                  )}
                  className="form-control"
                  id="password"
                  aria-describedby="wachtwoord"
                  name="password"
                  {...register("password", {
                    required: true,
                    maxLength: 50,
                  })}
                />
              </label>
              {errors.password && (
                <span className="invalidCredentials password">
                  <I18n t="qna.form.required" />
                </span>
              )}
            </div>

            <div className={showCode ? "form-group" : "hidden"}>
              <label htmlFor="enterCode">
                <input
                  type="text"
                  placeholder="Code"
                  className="form-control"
                  id="enterCode"
                  aria-describedby="wachtwoord"
                  name="code"
                  {...register("code", {
                    required: showCode,
                    maxLength: 6,
                    minLength: 6,
                  })}
                />
              </label>
              {errors.code && (
                <span className="invalidCredentials">
                  <I18n t="login.loginIncorrect" />
                </span>
              )}
            </div>

            <button type="submit" className="login-button">
              <I18n t="login.loginAction" />
            </button>
          </form>
        </div>
      </div>

      <div className={showCode === false ? "form-group" : "hidden"}>
        <button
          className="switch-link"
          onClick={() => props.setState("forgot")}
        >
          <I18n t="forgotPassword" />
        </button>

        <br />

        <div style={{ marginTop: "0.5rem" }}>
          <I18n t="account.needAccount" />{" "}
          <button
            className="switch-link"
            onClick={() => props.setState("register")}
          >
            <I18n t="createAccount" />
          </button>
        </div>
      </div>
    </div>
  );
}
