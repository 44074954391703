import React, { useState } from "react";
import Breadcrumbs from "../breadcrumbs";
import Modal from "../Modal";
import { Link } from "react-router-i18n";
import "./styles.scss";
import I18n from "../../i18n";

const QnaLayout = ({
  children,
  breadcrumbs = [],
  backButtonLink = "",
  downloadBtn = false,
}) => {
  const [showLogout, setShowLogout] = useState(0);

  function showLogoutPopup() {
    setShowLogout(true);
  }

  function logout() {
    setShowLogout(false);
    localStorage.removeItem("token");
    window.location.href = "/";
  }

  return (
    <div className="page">
      <div className="header-login nav-qna-container">
        <div className="container">
          <div className="logo-header">
            <Link to={`/`}>
              <img src="/images/innovatiepartners_logo.svg" alt="logo" />
            </Link>
            <button className="button-qna" onClick={showLogoutPopup}>
              <I18n t="logout" />
            </button>
          </div>

          <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

          <div className="nav-qna">
            {backButtonLink && (
              <Link
                to={backButtonLink}
                className="nav-qna-back"
                aria-hidden="true"
              >
                <I18n t="back" />
              </Link>
            )}
            {downloadBtn && downloadBtn}
          </div>
        </div>
      </div>

      <div className="container projects-overview-container">{children}</div>

      <Modal open={showLogout} onClose={() => setShowLogout(false)}>
        <h1>
          <I18n t="logoutWarning" />
        </h1>

        <button onClick={() => setShowLogout(false)}>
          <I18n t="logoutDeny" />
        </button>
        <button onClick={logout}>
          <I18n t="logoutConfirm" />
        </button>
      </Modal>
    </div>
  );
};

export default QnaLayout;
