import React from "react";
import ReactMarkdown from "react-markdown";
import { useStaleSWR, stringToSlug } from "../../utils";
import Loading from "../../components/loading";
import "./styles.scss";

export default function Glossary() {
  const { data: glossaries, error: glossariesError } = useStaleSWR(
    `/glossaries/?_sort=term_nl`
  );

  if (glossariesError) {
    console.log(glossariesError.message);
    return null;
  }

  if (!glossaries) {
    return <Loading />;
  }

  let currentLetterIndex = 0;
  const letters = [];
  for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
    letters.push(String.fromCharCode(i));
  }

  const letter = (word) => {
    return word.substring(0, 1).toUpperCase();
  };

  const listToNextLetter = (nextWord) => {
    const nextLetter = letter(nextWord);
    const nextIndex = letters.indexOf(nextLetter);
    const list = letters.slice(currentLetterIndex, nextIndex + 1);

    currentLetterIndex = nextIndex;
    return list;
  };

  const lastLetters = () => {
    return listToNextLetter("Z");
  };

  return (
    <>
      {glossaries.length > 0 && (
        <p className="letters">
          {letters.map((value, index) => {
            return (
              <a href={`#${value}`} key={index}>
                {value}
              </a>
            );
          })}
        </p>
      )}

      {glossaries.map((word, index) => {
        const list = listToNextLetter(word.term_nl);

        return (
          <div key={index}>
            {list.map((letter) => {
              return <div id={letter} key={"coolLetter" + letter}></div>;
            })}
            <div className="definition">
              <a
                className="inlinea"
                href={`#${stringToSlug(word.term_nl)}`}
                id={stringToSlug(word.term_nl)}
              >
                <h3>{word.term_nl}</h3>
              </a>
              <ReactMarkdown source={word.definition_nl} />
            </div>
          </div>
        );
      })}

      {lastLetters().map((letter) => {
        return <div id={letter} key={"coolLetter" + letter}></div>;
      })}
    </>
  );
}
