import React from "react";

const ExternalLink = ({ to, children, ...props }) => {
  return to ? (
    <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  ) : (
    <span {...props}>{children}</span>
  );
};

export default ExternalLink;
