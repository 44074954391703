import React from "react";
import useFormValidation from "../../useFormValidation";

export default function Radio(props) {
  const field = props.field;
  const properties = props.field.properties;
  const radios = properties.options.list;
  const validation = useFormValidation(props.active, properties);

  const radioItems = radios.map((radio, index) => {
    if (!radio.deleted) {
      return (
        <label className="radio-btn-container" key={radio.id}>
          <p>{radio.nl}</p>
          <input
            type="radio"
            value={radio.id}
            name={"withIndex." + index}
            {...props.register(field.id + `[]`, validation)}
          ></input>
          <span className="radio-btn-checkmark"></span>
        </label>
      );
    }

    return "";
  });

  return (
    <div
      className={`
        radio-button 
        ${props.error?.message && "show-error"}
        ${props.alertStatus && props.error === undefined && "show-correct"}
        `}
    >
      <label>{properties.label?.nl}</label>

      {radioItems}

      <span
        className="form-error"
        style={{ marginBottom: "20px", position: "relative" }}
      >
        {props.error?.message}
      </span>
    </div>
  );
}
