import React from "react";
import "./styles.scss";
import { Link } from "react-router-i18n";
import { getApiPrefix } from "../../utils";
import I18n from "../../i18n";

export default function Partners({ partners, horizontal }) {
  const prefix = () => {
    const href = window.location.pathname;
    const language = href.substring(1, 3);
    if (language === "en") {
      return "/en/";
    }
    return "/";
  };

  const setLogoLink = (logo) =>
    logo.ext === ".svg"
      ? `${getApiPrefix()}${logo.url}`
      : `${getApiPrefix()}${logo.formats?.thumbnail.url}`;

  const aboutRoute = I18n.getTranslation(window.location, "aboutBasePath");

  return (
    <div className={`logobar ${horizontal ? "horizontal" : ""}`}>
      {partners.map((partner, index) => {
        return (
          <Link
            to={`${prefix()}${aboutRoute}/${partner.slug}`}
            className="partner-link"
            key={partner.id}
          >
            <div key={index} className="logo-item">
              {partner.logo && (
                <div className="logo-image">
                  <img
                    src={setLogoLink(partner.logo)}
                    alt={partner.logo.name}
                  />
                </div>
              )}

              {!horizontal && (
                <div className="logo-text">
                  <h3>Partner:</h3>
                  <p>{partner.name}</p>
                </div>
              )}
            </div>
          </Link>
        );
      })}
    </div>
  );
}
