export const getApiPrefix = () => {
  // The URL of the strapi installation
  return process.env.REACT_APP_STRAPI_URL;
};

export const getCurrentLanguage = () => {
  const href = window.location.pathname;
  const language = href.substring(1, 3);

  if (language === "nl" || language === "en") {
    return language;
  }
  return "nl-NL";
};

export const makeSiteBossURL = (url) => {
  return (
    process.env.REACT_APP_PUBLIC_SITEBOSS_URL +
    "/" +
    (getCurrentLanguage() === "nl-NL" ? "nl" : "en") +
    url
  );
};

export const getFormsApi = () => {
  // The API folder of the siteboss installation /admin/cms/api/
  return process.env.REACT_APP_FORMBUILDER_URL;
};
