import React from "react";
import BaseLayout from "../../components/baselayout";
import { useStaleSWR } from "../../utils";
import AccountModal from "../../components/AccountModal";
import Loading from "../../components/loading";
import Error from "../../components/error";
import UpdatePassword from "../../components/updatepassword";
import UpdateEmail from "../../components/updateemail";
import UpdateMobile from "../../components/updatemobile";
import DeleteAccount from "../../components/deleteaccount";
import I18n from "../../i18n";
import AccountSidebar from "../../components/AccountSidebar";

import "./styles.scss";
import Divider from "../../components/divider";

export default function AccountProfile({ location }) {
  const { data: forms, error } = useStaleSWR("/projects/");

  const loggedIn =
    localStorage.getItem("siteboss_client_token") !== null &&
    localStorage.getItem("siteboss_client_token") !== "";

  const scrollTo = location.state?.scrollTo;
  React.useEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (!!element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollTo]);

  if (error) {
    return <Error message={error.message} />;
  }

  if (!forms) {
    return <Loading />;
  }

  return (
    <BaseLayout
      type="project"
      breadcrumbs={[{ title: <I18n t="yourProfile" /> }]}
      headerContent={
        <>
          <h2>
            <I18n t="yourProfile" />
          </h2>
          <p>
            <I18n t="account.edit.profile" />
          </p>
        </>
      }
    >
      {loggedIn ? (
        <div className="account-content">
          <div className="project_container" id="project_container">
            <AccountSidebar />

            <div className="col2">
              <div id="contentTop" />
              <UpdatePassword />
              <Divider />
              <UpdateEmail />
              <Divider />
              <UpdateMobile />
              <Divider />
              <DeleteAccount />
            </div>
          </div>
        </div>
      ) : (
        <AccountModal> </AccountModal>
      )}
    </BaseLayout>
  );
}
