import { Popup as PackagePopup } from "reactjs-popup";

// given styling from package
import "reactjs-popup/dist/index.css";

// overwrite styling
import "./styles.scss";

const Popup = (props) => {
  return <PackagePopup {...props} />;
};

export default Popup;
