import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { makeSiteBossURL, useStaleSWR } from "../../utils";
import Error from "../error";
import Loading from "../loading";
import I18n from "../../i18n";

export default function UpdateEmail(props) {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const { data: user, error } = useStaleSWR(makeSiteBossURL(`/account/`), true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setInvalidCredentials(false);
    axios.defaults.headers.put[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("siteboss_client_token")}`;

    axios
      .put(makeSiteBossURL(`/account/update/email/`), data)
      .then((response) => {
        //if (response.data.status === "ok") {
        //  setInvalidCredentials(response.data.message);
        //} else {
        setInvalidCredentials(response.data.message);
        //}
      })
      .catch((error) => {
        setInvalidCredentials(
          I18n.getTranslation(window.location, "somethingWrong")
        );
      });
  };

  if (error) {
    return <Error block message={error.message} />;
  }

  if (!user) {
    return <Loading />;
  }

  return (
    <div className="header-login">
      <h3>
        <I18n t="account.edit.editEmail" />
      </h3>

      <p>
        <I18n t="account.edit.editEmailText" />
      </p>

      <p>
        <I18n t="account.edit.nowEmailText" /> {user.email}
      </p>

      <div className="invalidCredentials">{invalidCredentials}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="currentPassword">
            <input
              type="password"
              placeholder={I18n.getTranslation(
                window.location,
                "account.edit.currentPassword"
              )}
              className="form-control"
              id="currentPassword"
              name="password"
              autoComplete="current-password"
              {...register("password", {
                required: true,
                minLength: 8,
                maxLength: 50,
              })}
            />
          </label>

          {errors.password && (
            <span className="invalidCredentials">
              <I18n t="account.edit.currentPasswordError" />
            </span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="newPassword">
            <input
              type="email"
              placeholder={I18n.getTranslation(
                window.location,
                "account.edit.newEmail"
              )}
              className="form-control"
              {...register("newEmail", {
                required: true,
                type: "email",
                pattern: /^\S+@\S+$/,
              })}
            />
          </label>

          {errors.newEmail && (
            <span className="invalidCredentials">
              <I18n t="account.edit.correctEmail" />
            </span>
          )}
        </div>

        <button type="submit" className="login-button">
          <I18n t="account.edit.editEmail" />
        </button>
      </form>
    </div>
  );
}
