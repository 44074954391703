import { DateTime } from "luxon";

export const currentLocale = () => {
  if (window.location.pathname.startsWith("/en")) {
    return "en";
  }
  return "nl";
};

export const parseDate = (date) => {
  if (!date && date !== "") return "";
  const newDate = DateTime.fromISO(date).setZone("Europe/Amsterdam");
  const dateString = newDate
    .setLocale(currentLocale())
    .toLocaleString(DateTime.DATE_HUGE);

  return dateString;
};

export const parseDateTime = (date) => {
  if (!date && date !== "") return "";
  const newDate = DateTime.fromISO(date).setZone("Europe/Amsterdam");

  return newDate
    .setLocale(currentLocale())
    .toLocaleString(DateTime.DATETIME_MED);
};

export const numberOfDaysToDeadline = (
  deadline,
  fullInfo = false,
  unixTimestamp = false
) => {
  const timezone = "Europe/Amsterdam";
  const currentDate = DateTime.now().setZone(timezone);

  let deadlineDate = null;
  if (unixTimestamp) {
    // why doesn't this need a timezone?
    deadlineDate = DateTime.fromSeconds(parseInt(deadline));
  } else {
    deadlineDate = DateTime.fromISO(deadline).setZone(timezone);
  }

  // detailed difference from luxon
  const difference = deadlineDate
    .diff(currentDate, ["days", "hours", "minutes", "seconds"])
    .toObject();

  // extra logic that needs luxon (render string etc.)
  const renderInfo = fullInfo
    ? {
        difference: difference,
        renderDate: deadlineDate
          .setLocale(currentLocale())
          .toLocaleString(DateTime.DATE_FULL),
        deadlineDate: deadlineDate,
      }
    : {};

  // return info needed
  return {
    closed: difference.seconds <= 0,
    ...renderInfo,
  };
};
