import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { makeSiteBossURL } from "../../utils";
import AccountPassword from "../AccountPassword";
import I18n from "../../i18n";

export default function AccountForgot(props) {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [allOK, setAllOK] = useState(false);
  const [token, setToken] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm();
  const onSubmit = (data) => {
    handleSubmitPost(data);
  };

  const handleSubmitPost = (data) => {
    setInvalidCredentials(false);

    // CAREFUL: Duplicate code in pages/login TODO:
    axios
      .post(makeSiteBossURL(`/account/forgot/`), data)
      .then((response) => {
        if (response.data.status === "code") {
          setShowCode(true);
        } else if (response.data.status === "ok") {
          setToken(response.data.token);
          setAllOK(true);
        } else {
          if (!response.data.message) {
            setInvalidCredentials(
              I18n.getTranslation(window.location, "somethingWrong")
            );
          } else {
            setInvalidCredentials(response.data.message);
          }
        }
      })
      .catch((error) => {
        setInvalidCredentials(
          I18n.getTranslation(window.location, "somethingWrong")
        );
        console.log(error);
      });
  };

  React.useEffect(() => {
    setFocus("code");
  }, [setFocus, showCode]);

  return allOK ? (
    <>
      <h1>
        <I18n t="forgotPassword" />
      </h1>

      <AccountPassword token={token} mailKey="forgot" />
    </>
  ) : (
    <div className="container">
      <div className="loginContainer">
        <h1>
          <I18n t="forgotPassword" />
        </h1>

        <h2>
          <I18n t="login.supplyEmail" />
        </h2>

        {showCode ? (
          <p>
            <I18n t="login.smsReceive" />
          </p>
        ) : (
          <p>
            <I18n t="login.smsText" />
          </p>
        )}

        <div className="login">
          <div className="invalidCredentials">{invalidCredentials}</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={showCode === false ? "form-group" : "hidden"}>
              <label htmlFor="email">
                <input
                  type="email"
                  placeholder={I18n.getTranslation(
                    window.location,
                    "login.email"
                  )}
                  className="form-control"
                  id="email"
                  aria-describedby="email"
                  name="email"
                  {...register("email", {
                    required: true,
                    type: "email",
                    pattern: /^\S+@\S+$/,
                    maxLength: 255,
                  })}
                />
              </label>

              {errors.email && (
                <span className="invalidCredentials">
                  <I18n t="account.edit.correctEmailText" />
                </span>
              )}
            </div>

            <div className={showCode ? "form-group" : "hidden"}>
              <label htmlFor="enterCode">
                <input
                  type="text"
                  placeholder="Code"
                  className="form-control"
                  id="enterCode"
                  aria-describedby="wachtwoord"
                  name="code"
                  {...register("code", {
                    required: showCode,
                    maxLength: 6,
                    minLength: 6,
                  })}
                />
              </label>

              {errors.code && (
                <span className="invalidCredentials">
                  <I18n t="login.smsIncorrect" />
                </span>
              )}
            </div>

            <button type="submit" className="login-button">
              <I18n t="login.smsCheck" />
            </button>
          </form>
        </div>
      </div>

      <button className="switch-link" onClick={() => props.setState("login")}>
        <I18n t="login.backToLogin" />
      </button>
    </div>
  );
}
