import React, { useState } from "react";
import axios from "axios";
import AccountPassword from "../AccountPassword";
import { useForm } from "react-hook-form";
import "./styles.scss";
import I18n from "../../i18n";
import { useLocation } from "react-router-dom";
import { makeSiteBossURL } from "../../utils";

export default function AccountSmsVerify({ mailKey }) {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(false);
  const [allOK, setAllOK] = useState(false);
  const [token, setToken] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm();

  const onSubmit = (data) => {
    handleSubmitPost(data);
  };

  const handleSubmitPost = (data) => {
    setErrorMessage(false);
    axios
      .post(makeSiteBossURL(`/account/register/${mailKey}/mobile/code/`), data)
      .then((response) => {
        if (response.data.status !== "ok") {
          if (response.data.message) {
            setErrorMessage(response.data.message);
          } else {
            setErrorMessage(I18n.getTranslation(location, "error.unknown"));
          }
        } else {
          setToken(response.data.token);
          setErrorMessage(false);
          setAllOK(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(
          I18n.getTranslation(location, "error.serverConnection")
        );
      });
  };

  React.useEffect(() => {
    setFocus("code");
  }, [setFocus, allOK]);

  return (
    <>
      {allOK ? (
        <AccountPassword token={token} mailKey={mailKey} />
      ) : (
        <>
          <h2>
            <I18n t="login.supplySms" />
          </h2>

          <p>
            <I18n t="login.smsReceive" />
          </p>

          <div className="login">
            <div className="invalidCredentials">{errorMessage}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="codeInput">
                  <input
                    type="text"
                    className="form-control"
                    id="codeInput"
                    placeholder="Code"
                    name="code"
                    {...register("code", {
                      required: true,
                      type: "text",
                      pattern: /^[0-9]{6}$/,
                      maxLength: 6,
                    })}
                  />
                </label>

                {errors.code && (
                  <span className="invalidCredentials">
                    <I18n t="login.smsIncorrect" />
                  </span>
                )}
              </div>

              <button type="submit" className="login-button">
                <I18n t="login.smsCheck" />
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
}
