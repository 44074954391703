import React from "react";
import { Link } from "react-router-i18n";
import I18n from "../../i18n";
import "./styles.scss";

const ApplyButton = ({ link = {} }) => {
  if (!link || link?.missing) return "";
  if (!link.active) {
    return (
      <h3 className="closed">
        <I18n t="apply.applicationClosed" />
      </h3>
    );
  }

  return link.localizeLink ? (
    <Link to={link.target}>
      <button type="button" className="button__apply">
        {link.text} &gt;
      </button>
    </Link>
  ) : (
    <a href={link.target}>
      <button type="button" className="button__apply">
        {link.text} &gt;
      </button>
    </a>
  );
};

export default ApplyButton;
