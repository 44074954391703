import React from "react";
import useFormValidation from "../../useFormValidation";

export default function Toggle(props) {
  const field = props.field;
  const properties = props.field.properties;
  const validation = useFormValidation(props.active, properties);

  return (
    <>
      <div
        className={`
          checkbox__wrapper 
          ${props.error?.message ? "show-error" : "show-correct"}
        `}
      >
        <label className="checkbox__container">
          <input
            type="checkbox"
            value={field.id}
            {...props.register(field.id + "", validation)}
            disabled
            readOnly
          />
          <span className="checkmark" />
          <p>{properties.label.nl}</p>
        </label>
      </div>
      {props.error?.message && (
        <span className="form-error">{props.error?.message}</span>
      )}
    </>
  );
}
