import React from "react";
import ReactMarkdown from "react-markdown";
import Image from "../Image";
import { getApiPrefix } from "../../utils";
import PhoneIcon from "../../assets/icons/phone.png";

import "./styles.scss";

const HomeHeader = ({
  headerTitleContent,
  headerCallToAction: cta,
  headerContent,
  headerImage,
  headerList,
}) => {
  return (
    <div className="header__home__wrapper">
      <header className="header__home">
        <section className="header__home__title">
          <ReactMarkdown>{headerTitleContent}</ReactMarkdown>
          {cta && (
            <div className="header__home_cta">
              {cta.description && (
                <ReactMarkdown className="cta_description">
                  {cta.description}
                </ReactMarkdown>
              )}
              {cta.contact && (
                <div className="cta__contact">
                  <Image
                    wrapper={(img) => <div className="img__block">{img}</div>}
                    src={
                      getApiPrefix() +
                      (cta.avatar?.formats?.thumbnail?.url || "")
                    }
                    alt={cta.avatar?.alternativeText}
                  />
                  <div>
                    {(cta.name || cta.function) && (
                      <p>
                        <strong>{cta.name}</strong> <span>{cta.function}</span>
                      </p>
                    )}
                    {cta.link && cta.button && (
                      <a className="button__cta" href={cta.link}>
                        <Image src={PhoneIcon} alt="phone" className="icon" />
                        {cta.button}
                      </a>
                    )}
                  </div>
                </div>
              )}
              {cta.buttons && (
                <div className="cta__buttons">
                  {cta.link_primary && cta.button_primary && (
                    <a className="button__cta" href={cta.link_primary}>
                      {cta.button_primary}
                    </a>
                  )}
                  {cta.link_secondary && cta.button_secondary && (
                    <a
                      className="button__cta secondary"
                      href={cta.link_secondary}
                    >
                      {cta.button_secondary}
                    </a>
                  )}
                </div>
              )}

              {cta.memo && (
                <ReactMarkdown className="cta__memo">{cta.memo}</ReactMarkdown>
              )}
            </div>
          )}
        </section>
        <section className="header__home__image">
          <Image src={headerImage} />
        </section>
      </header>
      {(!!headerContent || !!headerList) && (
        <section className="header__home__content small-width">
          <div className="header__home__content__inner">
            <div>{headerContent}</div>
            {headerList?.length > 0 && (
              <ul id="homelist">
                {headerList.map((item) => {
                  return (
                    <li
                      key={item.name}
                      style={{
                        backgroundImage: `url(${item.image})`,
                      }}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default HomeHeader;
