import React from "react";
import { Link } from "react-router-i18n";
import NanoClamp from "nanoclamp";
import NewsletterForm from "../NewsletterForm";
import { getApiPrefix, numberOfDaysToDeadline } from "../../utils";
import I18n from "../../i18n";
import ProjectLabels from "../projectlabels";

import "./styles.scss";
import ProcessBar from "../ProcessBar";
import PartnerLogo from "../PartnerLogo";
import DeadlineBox from "../deadlinebox";
import Modal from "../Modal";
import union from "../../assets/icons/Union.svg";
import Image from "../Image";

const ProjectCard = ({
  id,
  title,
  text,
  deadline,
  deadline_text,
  url = "/project",
  thumbnail,
  partner = [],
  thema,
  progress,
  showApplicationButton,
  formSubmitted,
  overlay = null,
  loket,
  vertical,
  newsletterLijstId,
}) => {
  const { closed } = numberOfDaysToDeadline(deadline);

  let reviewLink = url + (url.slice(-1) === "/" ? "review" : "/review");

  return (
    <article
      className={`projectcardWrapper ${vertical ? "vertical" : ""} ${
        loket ? "loket" : ""
      } proj_${id}`}
    >
      <div className="projectcard">
        <div className="projectcard__content">
          <div>
            <ProjectLabels theme={thema} />
            <h3 className="projectcard__content_title">{title}</h3>
            <NanoClamp className="custom-class" is="p" lines={4} text={text} />
          </div>
          <div className="projectcard__content_footer">
            <div className="ctas">
              {showApplicationButton ? (
                !closed ? (
                  <Link to={`${url}`} className="cta arrow__link">
                    <I18n t="forms.seeAndEdit" />
                  </Link>
                ) : (
                  <Link to={reviewLink} className="cta arrow__link">
                    <I18n t="forms.see" />
                  </Link>
                )
              ) : (
                <Link to={`${url}#top`} className="cta arrow__link">
                  <I18n t="readmore" />
                </Link>
              )}
              {!!newsletterLijstId && (
                <Modal
                  trigger={
                    <>
                      <Image src={union} />
                      <I18n t="newsletter.follow" />
                    </>
                  }
                >
                  <h2 className="left">
                    <I18n t="newsletter.follow" />
                  </h2>
                  <NewsletterForm lijstId={newsletterLijstId} />
                </Modal>
              )}
            </div>

            {!loket && (
              <DeadlineBox
                project={{
                  deadline: deadline,
                  title: title,
                  name: title,
                  url: url,
                }}
                title={<I18n t="applyDeadline" />}
                overwrite={deadline_text}
              />
            )}
          </div>
        </div>

        <div
          className="projectcard__thumbnail"
          style={{
            backgroundImage: `url(${getApiPrefix()}${thumbnail}`,
          }}
        >
          {partner !== null && <PartnerLogo partner={partner} />}
          {overlay !== null && overlay.formats?.medium?.url !== null && (
            <img
              src={`/strapi${overlay.formats.medium.url}`}
              alt=""
              className="overlayimage"
            />
          )}
        </div>
      </div>
      {progress && <ProcessBar progress={progress} submitted={formSubmitted} />}
    </article>
  );
};

export default ProjectCard;
