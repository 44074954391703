import React from "react";
import AnswerQuestionBlock from "../../components/answerquestionblock";
import { useStaleSWR } from "../../utils";
import Loading from "../../components/loading";
import Error from "../../components/error";
import I18n from "../../i18n";
import QnaLayout from "../../components/qnalayout";

export default function AnswerQuestion(props) {
  const { data: question, error } = useStaleSWR(
    `/questions/auth/${props.match.params.slug}`,
    false,
    false
  );

  if (error) {
    return <Error message={error.message} />;
  }

  if (!question) {
    return <Loading />;
  }

  return (
    <QnaLayout
      breadcrumbs={[
        { title: <I18n t="qna.portal" />, path: "/answer/" },
        {
          title: question.project.name,
          path: "/categories/" + question.project.id,
        },
        { title: I18n.getTranslation(props.location, "answerQuestion") },
      ]}
      backButtonLink={"/categories/" + question.project.id}
    >
      <div className="about-content">
        <AnswerQuestionBlock
          key={question.id}
          question={question}
          projectId={question.project.id}
          location={props.location}
        />
      </div>
    </QnaLayout>
  );
}
