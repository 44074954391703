import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { makeSiteBossURL } from "../../utils";
import Modal from "../Modal";
import I18n from "../../i18n";

export default function DeleteAccount(props) {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function toggleModal() {
    setIsOpen(!modalIsOpen);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const handleSubmitPost = () => {
    setInvalidCredentials(false);
    axios.defaults.headers.put[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("siteboss_client_token")}`;

    axios
      .put(makeSiteBossURL(`/account/delete/`), {
        password: getValues("password"),
      })
      .then((response) => {
        if (response.data.status === "ok") {
          setInvalidCredentials(response.data.message);
          localStorage.removeItem("siteboss_client_token");
          alert(response.data.message);
          window.location.href = "/";
        } else {
          closeModal();
          setInvalidCredentials(response.data.message);
        }
      })
      .catch((error) => {
        closeModal();
        setInvalidCredentials(
          I18n.getTranslation(window.location, "somethingWrong")
        );
      });
  };

  return (
    <div className="header-login">
      <div>
        <h3>
          <I18n t="account.edit.deleteAccount" />
        </h3>
        <p>
          <I18n t="account.edit.deleteAccountText" />
        </p>
        <div className="invalidCredentials">{invalidCredentials}</div>

        <form onSubmit={handleSubmit(toggleModal)}>
          <div className="form-group">
            <label htmlFor="currentPassword">
              <input
                type="password"
                placeholder={I18n.getTranslation(
                  window.location,
                  "account.edit.currentPassword"
                )}
                className="form-control"
                id="currentPassword"
                name="password"
                autoComplete="current-password"
                {...register("password", {
                  required: true,
                  minLength: 8,
                  maxLength: 50,
                })}
              />
            </label>

            {errors.password && (
              <span className="invalidCredentials">
                <I18n t="account.edit.currentPasswordError" />
              </span>
            )}
          </div>
          <button type="submit" className="login-button">
            <I18n t="account.edit.deleteAccount" />
          </button>
        </form>
      </div>

      <Modal open={modalIsOpen} onClose={closeModal}>
        <p>
          <strong>
            <I18n t="account.edit.deleteAccountIrreversible" />
          </strong>
        </p>
        <p>
          <I18n t="account.edit.deleteAccountSure" />
        </p>

        <div className="delete__modal_buttons">
          <button
            onClick={closeModal}
            type="button"
            className="button__apply back"
          >
            <I18n t="cancel" />
          </button>
          <button
            onClick={handleSubmitPost}
            type="button"
            className="button__apply button__red"
          >
            <I18n t="account.edit.deleteAccount" />
          </button>
        </div>
      </Modal>
    </div>
  );
}
