import React from "react";

export default function Textarea(props) {
  const properties = props.field.properties;

  return (
    <div className="item-text">
      <h3>{properties.label.nl}</h3>
      <p>{props.value}</p>
    </div>
  );
}
