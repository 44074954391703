import React from "react";
import BaseLayout from "../../components/baselayout";
import I18n from "../../i18n";

import "./styles.scss";

export default function NotFound() {
  return (
    <BaseLayout type="404" contentHome="NotFound">
      <div className="block">
        <h1>
          <I18n t="error.notFoundPage" />
        </h1>
        <p>
          <I18n t="error.notFoundCheck" />
        </p>
      </div>
    </BaseLayout>
  );
}
