import React, { useState } from "react";
import useStaleSWR from "swr";
import axios from "axios";
import AccountSmsVerify from "../../components/AccountSmsVerify";
import { useForm, Controller } from "react-hook-form";
import Loading from "../../components/loading";
import Error from "../../components/error";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./styles.scss";
import I18n from "../../i18n";
import { makeSiteBossURL } from "../../utils";

export default function AccountRegisterSMS(props) {
  const location = props.location;
  const [errorMessage, setErrorMessage] = useState(false);
  const [allOK, setAllOK] = useState(false);
  const { data: userData, error } = useStaleSWR(
    makeSiteBossURL(`/account/register/${props.match.params.emailkey}/`),
    true
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    handleSubmitPost(data);
  };

  if (error) {
    return <Error message={error.message} />;
  }

  if (!userData) {
    return <Loading />;
  }

  const handleSubmitPost = (data) => {
    setErrorMessage(false);

    axios
      .post(
        makeSiteBossURL(
          `/account/register/${props.match.params.emailkey}/mobile/`
        ),
        data
      )
      .then((response) => {
        if (response.data.status !== "ok") {
          if (response.data.message) {
            setErrorMessage(response.data.message);
          } else {
            setErrorMessage(I18n.getTranslation(location, "error.unknown"));
            setAllOK(true);
          }
        } else {
          setErrorMessage(false);
          setAllOK(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(
          I18n.getTranslation(location, "error.serverConnection")
        );
      });
  };

  return (
    <div className="header-login">
      <div className="container">
        <a href="/">
          <img src="/images/innovatiepartners_logo.svg" alt="logo" />
        </a>

        <div className="about-content small-width">
          <h1>
            <I18n t="login.createAccount" />
          </h1>

          {allOK ? (
            <AccountSmsVerify mailKey={props.match.params.emailkey} />
          ) : (
            <>
              {userData.status !== "ok" ? (
                <h2>{userData.message}</h2>
              ) : (
                <>
                  <h2>
                    <I18n t="login.phone" />
                  </h2>

                  <p>
                    <I18n t="login.phoneText" />
                  </p>

                  <div className="login">
                    <div className="invalidCredentials">{errorMessage}</div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label htmlFor="mobileInput">
                          <Controller
                            control={control}
                            name="mobile"
                            rules={{ required: true }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                                validate,
                              },
                              formState,
                            }) => (
                              <PhoneInput
                                country="nl"
                                value={value}
                                onChange={(e) => onChange(e)}
                                enableSearch
                                containerClass="phoneClass"
                                inputClass="inpurClass"
                                regions={["europe"]}
                                isValid={(inputNumber, country) => {
                                  return inputNumber.startsWith(
                                    country.dialCode
                                  );
                                }}
                              />
                            )}
                          />
                        </label>

                        {errors.mobile && (
                          <span className="invalidCredentials">
                            <I18n t="login.phoneIncorrect" />
                          </span>
                        )}
                      </div>

                      <button type="submit" className="login-button">
                        <I18n t="login.confirmSms" />
                      </button>
                    </form>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
