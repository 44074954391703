import { useLocation } from "react-router-dom";
import I18n from "../../i18n";

const useFormValidation = (
  combinationActive,
  properties,
  type = "default",
  fieldId
) => {
  const location = useLocation();

  const emailVal = (val) => {
    if (val === "") {
      return true;
    }
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailGood = re.test(String(val).toLowerCase());
    if (!emailGood) {
      return I18n.getTranslation(location, "apply.emailRequired");
    }
    return true;
  };

  const numberVal = (val) => {
    if (Number.isNaN(val)) {
      return I18n.getTranslation(location, "apply.numberRequired");
    }
    return true;
  };

  const phoneVal = (val) => {
    const dutchPhoneRegex =
      /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
    const phoneGood = dutchPhoneRegex.test(String(val).toLowerCase());
    if (!phoneGood) {
      return I18n.getTranslation(location, "qna.form.correctTelephone");
    }
  };

  const kvkVal = (val) => {
    const simpleKvKRegex = /^[0-9 ]{8,15}$/;
    const justNumbersVal = val.replace(/\s*/g, "");
    const kvkGood =
      String(justNumbersVal).toLowerCase() === "" ||
      simpleKvKRegex.test(String(justNumbersVal).toLowerCase());
    if (!kvkGood) {
      return I18n.getTranslation(location, "apply.correctKvK");
    }
  };

  if (!combinationActive) {
    return {};
  }

  let validations = {};

  if (properties.required) {
    validations.required = I18n.getTranslation(location, "apply.required");
  }

  if (type !== "default") {
    if (type === "email") validations.validate = emailVal;
    if (type === "number") validations.validate = numberVal;
  }

  if (fieldId) {
    // validate some (text) fields on field id
    // (quick fix on customer request, should be field types or custom validation request from cms)
    if (fieldId === "phone") {
      validations.validate = phoneVal;
    }
    if (fieldId === "kvk_number") {
      validations.validate = kvkVal;
    }
  }

  return validations;
};

export default useFormValidation;
