import React, { useState } from "react";
import { Link } from "react-router-i18n";
import axios from "axios";
import { useForm } from "react-hook-form";
import { makeSiteBossURL } from "../../utils";
import "./styles.scss";
import I18n from "../../i18n";
import { useLocation } from "react-router-dom";

export default function AccountPassword({ token, mailKey }) {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(false);
  const [allOK, setAllOK] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    handleSubmitPost(data);
  };

  const handleSubmitPost = (data) => {
    setErrorMessage(false);

    var url = `/account/register/${mailKey}/password/`;
    if (mailKey === "forgot") {
      url = "/account/forgot/";
    }

    axios
      .post(makeSiteBossURL(url), data)
      .then((response) => {
        if (response.data.status !== "ok") {
          if (response.data.message) {
            setErrorMessage(response.data.message);
          } else {
            setErrorMessage(I18n.getTranslation(location, "error.unknown"));
          }
        } else {
          setErrorMessage(false);
          localStorage.setItem("siteboss_client_token", response.data.token);
          setAllOK(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(
          I18n.getTranslation(location, "error.serverConnection")
        );
      });
  };

  return (
    <>
      {allOK ? (
        <>
          {mailKey === "forgot" ? (
            <>
              <h2>
                <I18n t="login.done" />
              </h2>
              <p>
                <I18n t="login.loggedInPasswordChanged" />
              </p>
            </>
          ) : (
            <>
              <h2>
                <I18n t="login.done" />
              </h2>
              <p>
                <I18n t="login.loggedIn" />
              </p>
            </>
          )}

          <Link to="/account">
            <I18n t="login.toProfile" />
          </Link>
        </>
      ) : (
        <>
          <h2>
            <I18n t="login.password" />
          </h2>

          <p>
            <I18n t="login.passwordSafe" />
          </p>

          <div className="login">
            <div className="invalidCredentials">{errorMessage}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="hidden"
                name="token"
                value={token}
                {...register("token", { required: true, type: "text" })}
              />

              <div className="form-group">
                <label htmlFor="pwInput">
                  <input
                    type="password"
                    className="form-control"
                    id="pwInput"
                    placeholder={I18n.getTranslation(
                      window.location,
                      "login.password"
                    )}
                    name="password"
                    {...register("password", {
                      required: true,
                      type: "text",
                      minLength: 1,
                      maxLength: 50,
                    })}
                  />
                </label>

                {errors.code && (
                  <span className="invalidCredentials">
                    <I18n t="login.passwordRequirements" />
                  </span>
                )}

                <label htmlFor="pw2Input">
                  <input
                    type="password"
                    className="form-control"
                    id="pw2Input"
                    placeholder={I18n.getTranslation(
                      window.location,
                      "login.passwordRepeat"
                    )}
                    name="password2"
                    {...register("password2", {
                      required: true,
                      type: "text",
                      minLength: 1,
                      maxLength: 50,
                    })}
                  />
                </label>

                {errors.code && (
                  <span className="invalidCredentials">
                    <I18n t="login.passwordRepeat" />
                  </span>
                )}
              </div>

              <button type="submit" className="login-button">
                <I18n t="login.passwordConfirm" />
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
}
