import React from "react";
import useFormValidation from "../../useFormValidation";

export default function Checkbox(props) {
  const field = props.field;
  const properties = props.field.properties;
  const checkboxes = properties.options.list;
  const validation = useFormValidation(props.active, properties);

  const checkboxItems = checkboxes.map((checkbox, index) => {
    if (!checkbox.deleted) {
      return (
        <div className="checkbox__wrapper" key={checkbox.id}>
          <label className="checkbox__container">
            <input
              type="checkbox"
              value={checkbox.id}
              name={"withIndex." + index}
              {...props.register(field.id + `[]`, validation)}
            />
            <span className="checkmark" />
            <p>{checkbox.nl}</p>
          </label>
        </div>
      );
    }

    return null;
  });

  return (
    <>
      <div
        className={`
          checkbox-button 
          ${props.error?.message && "show-error"}
          ${props.alertStatus && props.error === undefined && "show-correct"}
          `}
      >
        <label>{properties.label?.nl}</label>
        {checkboxItems}
        <span
          className="form-error"
          style={{ marginBottom: "20px", position: "relative" }}
        >
          {props.error?.message}
        </span>
      </div>
    </>
  );
}
