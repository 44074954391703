import React from "react";
import Divider from "../divider";
import QnaQuestionForm from "../qnaquestionform";
import { useLocation } from "react-router-dom";
import { makeSiteBossURL, useStaleSWR } from "../../utils";
import I18n from "../../i18n";

import "./styles.scss";

export default function QnaLoket({ loket }) {
  const location = useLocation();
  const [search, setSearch] = React.useState("");
  const globalLoketId =
    loket.localizations[0]?.locale === "nl-NL"
      ? loket.localizations[0].id
      : loket.id;

  const { data: questions, error } = useStaleSWR(
    makeSiteBossURL(`/loket/${loket.id}/question/`),
    true,
    false
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);
    setSearch(formProps.search);
  };

  const activeQuestions = search
    ? questions?.filter((question) => {
        const foundTerms = search
          .split(" ")
          .map((searchterm) => {
            return [
              question.question?.toLowerCase(),
              question.answer?.toLowerCase(),
            ]
              .filter((text) => !!text)
              .join(" ")
              .includes(searchterm.toLowerCase());
          })
          .filter((result) => !!result);
        return foundTerms.length === search.split(" ").length;
      })
    : questions;

  if (error) console.log(error);
  return (
    <div className="qna__loket">
      <div className="header">
        <div className="intro__title">
          <h3>
            <I18n t="qna.qna" />
          </h3>
          <p>
            <I18n t="qna.qnaSimpleText" />
          </p>
        </div>

        <form className="search__kennisbank" onSubmit={handleSubmit}>
          <label htmlFor="site-search">
            <input
              placeholder={I18n.getTranslation(location, "qna.search")}
              defaultValue={search}
              type="search"
              name="search"
              aria-label="Search questions"
            />
          </label>
          <button type="submit">
            <img
              src="/images/vraag-antwoord/search-icon.svg"
              alt="search icon"
            />
          </button>
        </form>
      </div>
      {search && (
        <div className="search-results">
          <h3>
            <I18n t="qna.searchresult" /> '{search}'
          </h3>
          {activeQuestions?.length ? (
            <I18n t="qna.results" args={activeQuestions.length} />
          ) : (
            <I18n t="qna.none" />
          )}
        </div>
      )}

      {activeQuestions?.map((question) => {
        return (
          <div className="item__question" key={question.id}>
            <h4>
              <I18n t="qna.question" />
            </h4>
            <p>{question.question}</p>
            {question.answer && (
              <div className="answer">
                <h4>
                  <I18n t="qna.answer" />
                </h4>
                <p>{question.answer}</p>
              </div>
            )}
          </div>
        );
      })}
      <Divider />
      <div className="item__title">
        <h4>
          <I18n t="qna.form.ask" />
        </h4>
        <p>
          <I18n t="qna.form.text" />
        </p>
      </div>
      <div className="qna__form__wrapper">
        <QnaQuestionForm
          newsletterListId={loket.newsletterLijstId}
          location={location}
          submitUrl={makeSiteBossURL(`/loket/${globalLoketId}/question/`)}
          submitSuccesCondition={(res) => res.data.result === "ok"}
        />
      </div>
    </div>
  );
}
