import React from "react";
import ReactMarkdown from "react-markdown";
import { Switch, Route, Redirect } from "react-router-dom";
import { NavLink } from "react-router-i18n";

import I18n from "../../i18n";
import Loading from "../../components/loading";
import Error from "../../components/error";
import BaseLayout from "../../components/baselayout";
import StickyHeader from "../../components/stickyheader";
import OldSchoolMenuLink from "../../components/oldschoolmenulink";
import QnaLoket from "../../components/qnaloket";

import ProjectLabels from "../../components/projectlabels";
import ContentList from "../../components/contentlist";

import ApplyButton from "../../components/applybutton";
import Apply from "../../components/apply/form";
import ApplyReview from "../../components/apply/review";

import { useStaleSWR, getApiPrefix, makeSiteBossURL } from "../../utils";

import "./styles.scss";

const Loket = ({ history, location, match }) => {
  const [elRefs, setElRefs] = React.useState([]);
  const [scrolled, setScrolled] = React.useState(false);

  const glue = process.env.REACT_APP_BACKEND === "site" ? "?slug=" : "";
  const postfix = process.env.REACT_APP_BACKEND === "site" ? "" : "/";
  const { data, error } = useStaleSWR(
    `/lokets/${glue}${match.params.loketUrl}${postfix}&_locale=all`,
    false,
    false
  );

  const { data: loketSiteboss } = useStaleSWR(
    makeSiteBossURL(`/loket/${match.params.loketUrl}/`),
    true
  );

  // divide content into blocks for each NavigationTitle
  const setContentBlocks = function (allContent) {
    const tree = [{ id: 0, list: [] }];
    let index = 0;
    allContent.forEach((element) => {
      if (element.__component === "contentblocks.navigation-title") {
        tree.push({ title: element.title_nl, id: element.id, list: [] });
        index += 1;
      } else {
        tree[index].list.push(element);
      }
    });
    return tree;
  };
  const contentBlocks = setContentBlocks(
    data && data[0] ? data[0]?.content : []
  );
  const contentBlocksLength = contentBlocks.length;

  // create array of refs for all blocks (used at OldSchoolMenuLink > Scrollspy)
  React.useEffect(() => {
    setElRefs((elRefs) =>
      Array(contentBlocksLength)
        .fill()
        .map((_, i) => elRefs[i] || React.createRef())
    );
  }, [contentBlocksLength]);

  // scroll into view once if path has hash && element is present on page
  const hash = location.hash;
  const scrollTo = location.state?.scrollTo;
  React.useEffect(() => {
    if (!scrolled && hash) {
      const element = document.getElementById(hash.replace("#", ""));

      if (!!element) {
        element.scrollIntoView({ behavior: "smooth" });
        setScrolled(true);
      }
    } else if (!hash) {
      setScrolled(true);
    }

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (!!element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrolled, hash, scrollTo, contentBlocks]);

  if (error) {
    return <Error message={error.message} />;
  }

  if (!data) {
    return <Loading />;
  }

  const loket = data[0];

  if (
    loket &&
    loket.locale !== "nl-NL" &&
    !location.pathname.includes(`/${loket.locale}/`)
  ) {
    return <Redirect to={`/${loket.locale}${location.pathname}`} />;
  }

  if (!loket) {
    return <Error message={I18n.getTranslation(location, "error.missing")} />;
  }

  const globalLoketId =
    loket.localizations[0]?.locale === "nl-NL"
      ? loket.localizations[0].id
      : loket.id;

  const formAvailable = (loket) => !!loket?.form_id;

  const localizeLink = () => {
    if (loket.form_url !== null && loket.form_url.trim() !== "") {
      if (loket.form_url.startsWith("/") && !loket.form_url.startsWith("/en")) {
        return true;
      }

      return false;
    }

    return true;
  };

  const externalApplyForm =
    loket.form_url !== null && loket.form_url.trim() !== "";
  const applyLink = {
    missing: !formAvailable(loketSiteboss) && !loket.form_url?.trim(),
    active: formAvailable(loketSiteboss) || externalApplyForm,
    text: I18n.getTranslation(location, "inschrijven"),
    type: externalApplyForm ? "external" : "internal",
    localizeLink: localizeLink(),
    target: externalApplyForm
      ? loket.form_url
      : `/loket/${match.params.loketUrl}/apply`,
  };

  const goToReview = () => {
    history.push({
      pathname: match.url + "/apply/review",
    });
  };

  const showApplyButton = () => {
    return (
      !location.pathname.endsWith("apply") &&
      !location.pathname.endsWith("review")
    );
  };

  return (
    <StickyHeader
      noForm={!formAvailable(loketSiteboss)}
      activeForm={!showApplyButton()}
      name={loket.name}
      stickyHeaderText=""
      applyLink={applyLink}
    >
      <BaseLayout
        type="loket"
        headerImage={
          loket.thumbnail?.formats === null
            ? ""
            : `${getApiPrefix()}/${
                loket.thumbnail.formats.medium?.url ??
                loket.thumbnail.formats.small?.url ??
                loket.thumbnail.url
              }`
        }
        headerPartner={loket.partner}
        headerContent={
          <div>
            <ProjectLabels
              theme={loket.thema}
              categories={loket.project_categories}
            />
            <h2>{loket.name}</h2>
            <ReactMarkdown source={loket.summary} />
            {showApplyButton() && (
              <div className="project__header_apply">
                <ApplyButton link={applyLink} />
              </div>
            )}
          </div>
        }
        headerSDGs={loket.SDGs}
        breadcrumbs={[{ title: loket.name }]}
        headerShare={loket}
      >
        <div
          className="project_container loket_container"
          id="project_container"
        >
          <div className="col1">
            <div className="stickyMenu">
              <div className="index-item">
                <OldSchoolMenuLink
                  ignoreLocale
                  activeOnlyWhenExact
                  to={{
                    pathname: `/loket/${match.params.loketUrl}`,
                    state: { scrollTo: "contentTop" },
                  }}
                  title="Loket"
                  list={contentBlocks}
                  refs={elRefs}
                />
                {loket.showQuestions && (
                  <NavLink
                    to={{
                      pathname: `/loket/${match.params.loketUrl}/qna`,
                      state: { scrollTo: "contentTop" },
                    }}
                    className="item-link"
                    activeClassName="active"
                  >
                    <I18n t="qna.qna" />
                  </NavLink>
                )}
                {/* Duplicate code in ApplyButton */}
                {applyLink.active === false ? (
                  <></>
                ) : applyLink.localizeLink ? (
                  <NavLink
                    to={{
                      pathname: "" + applyLink.target,
                      state: { scrollTo: "contentTop" },
                    }}
                    className="item-link"
                    activeClassName="active"
                  >
                    {applyLink.text}
                  </NavLink>
                ) : (
                  <a className="item-link" href={applyLink.target}>
                    {applyLink.text}
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="col2">
            <div id="contentTop" />
            <Switch>
              <Route exact path="/:language?/loket/:loket/">
                {contentBlocks.map((block, index) => (
                  <div key={index} id={`anchor${index}`} ref={elRefs[index]}>
                    <ContentList
                      projectInfo={loket}
                      contentitems={block.list}
                      partners={loket.partners}
                    />
                  </div>
                ))}
              </Route>

              <Route path="/:language?/loket/:loket/qna">
                <QnaLoket loket={loket} />
              </Route>

              <Route exact path="/:language?/loket/:loket/apply/review">
                <ApplyReview
                  slug={match.params.loketUrl}
                  type="loket"
                  queryId={globalLoketId}
                />
              </Route>

              <Route exact path="/:language?/loket/:loket/apply">
                <Apply
                  slug={match.params.loketUrl}
                  type="loket"
                  queryId={globalLoketId}
                />
              </Route>
            </Switch>
          </div>

          <aside className="col3">
            <Route exact path="/:language?/loket/:loket/apply">
              <div className="buttons-right-side">
                {loket.Formulier_document !== null &&
                  loket.Formulier_document.url !== null && (
                    <a
                      href={`/strapi${loket.Formulier_document.url}`}
                      className="button__apply"
                    >
                      <I18n t="forms.download" />
                    </a>
                  )}

                <button className="button__apply" onClick={() => goToReview()}>
                  <I18n t="leesweergave" />
                </button>
              </div>
            </Route>
          </aside>
        </div>
      </BaseLayout>
    </StickyHeader>
  );
};

export default Loket;
