import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import I18n from "../../i18n";
import Modal from "../Modal";
import ExternalLink from "../externallink";

import "./styles.scss";
import { makeSiteBossURL } from "../../utils";

const QnaQuestionForm = ({
  newsletterListId,
  location,
  submitUrl,
  submitSuccesCondition = () => true,
  submitData = {},
  requireAll,
  succesMessage = null,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [newsletterSubmitError, setNewsletterSubmitError] = useState("");

  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      checkboxFollow: true,
    },
  });
  const onSubmit = (data) => handleSubmitPost(data);

  const handleSubmitPost = (formData) => {
    setSubmitting(true);
    setSubmitError("");

    axios.defaults.headers.post["Authorization"] = "";

    if (newsletterListId && formData.checkboxFollow) {
      axios
        .post(
          makeSiteBossURL(
            `/user/newsletter/?list=${newsletterListId}&email=${formData.email}&name=${formData.name}`
          )
        )
        .catch((err) => {
          setNewsletterSubmitError(
            err?.message ?? I18n.getTranslation(location, "somethingWrong")
          );
        });
    }

    axios
      .post(submitUrl, {
        question: formData.question,
        name: formData.name,
        organization: formData.organization,
        email: formData.email,
        ...submitData,
      })
      .then((response) => {
        if (submitSuccesCondition(response)) {
          reset();
          setShowPopup(true);
        } else {
          setSubmitError(I18n.getTranslation(location, "somethingWrong"));
        }
      })
      .catch(() => {
        setSubmitError(I18n.getTranslation(location, "somethingWrong"));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="qna__question__form"
        noValidate
      >
        <div className="field">
          <input
            className="short__text"
            type="text"
            id="name"
            name="name"
            placeholder={I18n.getTranslation(location, "qna.form.name")}
            {...register("name", { required: true, maxLength: 50 })}
          />
          {errors.name?.type === "required" && (
            <div className="form-error">
              <I18n t="qna.form.required" />
            </div>
          )}
          {errors.name?.type === "maxLength" && (
            <div className="form-error">
              <I18n t="qna.form.maxLength" />
            </div>
          )}
        </div>
        <div className="field">
          <input
            type="text"
            placeholder={I18n.getTranslation(location, "qna.form.org")}
            {...register("organization", {
              required: !!requireAll,
              maxLength: 50,
            })}
          />
          {errors.organization?.type === "required" && (
            <div className="form-error">
              <I18n t="qna.form.required" />
            </div>
          )}
          {errors.organization?.type === "maxLength" && (
            <div className="form-error">
              <I18n t="qna.form.maxLength" />
            </div>
          )}
        </div>
        <div className="field">
          <textarea
            type="textarea"
            placeholder={I18n.getTranslation(location, "qna.form.question")}
            {...register("question", { required: true })}
          />
          {errors.question && (
            <div className="form-error">
              <I18n t="qna.form.required" />
            </div>
          )}
        </div>
        <div className="field">
          <input
            type="email"
            placeholder={I18n.getTranslation(location, "qna.form.email")}
            {...register("email", {
              required: true,
              pattern: emailRegex,
            })}
          />
          {errors.email && (
            <div className="form-error">
              <I18n t="qna.form.correctEmail" />
            </div>
          )}
        </div>
        <div className="field">
          {newsletterListId && (
            <div className="checkbox__wrapper">
              <label className="checkbox__container">
                <input type="checkbox" {...register("checkboxFollow")} />
                <span className="checkmark" />
                <p>
                  <I18n t="qna.form.follow" />
                </p>
              </label>
            </div>
          )}
        </div>
        <div className="field">
          <div className="checkbox__wrapper">
            <label className="checkbox__container">
              <input
                type="checkbox"
                {...register("checkbox1", { required: true })}
              />
              <span className="checkmark" />
              <p>
                <I18n t="qna.form.privacy" />
              </p>
            </label>
            <ExternalLink
              to={I18n.getTranslation(location, "footer.privacyLink")}
            >
              <p>
                <I18n t="qna.form.privacyDocument" />
              </p>
            </ExternalLink>
          </div>
          {errors.checkbox1 && (
            <div className="form-error">
              <I18n t="qna.form.privacyRequired" />
            </div>
          )}
        </div>
        <div className="field">
          <div className="checkbox__wrapper">
            <label className="checkbox__container">
              <input
                type="checkbox"
                {...register("checkbox2", { required: true })}
              />
              <span className="checkmark" />
              <p>
                <I18n t="qna.form.privacy2" />
              </p>
            </label>
          </div>
          {errors.checkbox2 && (
            <div className="form-error">
              <I18n t="qna.form.privacyRequired" />
            </div>
          )}
        </div>
        <button type="submit" className="submit__form" disabled={submitting}>
          <I18n t="qna.form.ask" />
          <img src="/icons/question-icon.svg" alt="Vraagteken" />
        </button>
        {submitError && <div className="form-error">{submitError}</div>}
        {newsletterSubmitError && (
          <div className="form-error">{newsletterSubmitError}</div>
        )}
        <div className="subscript">
          <p>
            <I18n t="qna.form.anonymous" />
          </p>
        </div>
      </form>
      <Modal open={showPopup} onClose={() => setShowPopup(false)}>
        <p>
          <I18n t="qna.form.thanks" />
        </p>
        {succesMessage}
      </Modal>
    </>
  );
};

export default QnaQuestionForm;
