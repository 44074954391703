import React from "react";
import jwt from "jsonwebtoken";
import { Redirect, Route } from "react-router-dom";

export default function AuthenticatedAnswerRoute({
  component: Component,
  ...rest
}) {
  const token = localStorage.getItem("token");
  const decodedToken = !!token && jwt.decode(token, { complete: true });

  if (
    !decodedToken?.payload?.exp ||
    decodedToken.payload.exp * 1000 < new Date().getTime()
  ) {
    localStorage.removeItem("token");
  }

  if (!token) return <Redirect to="/login" />;
  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
}
