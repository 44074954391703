import React, { useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down_blue.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow_up_blue.svg";
import { ReactComponent as Close } from "../../assets/icons/close-purple.svg";
import "./styles.scss";

const Select = ({ options, placeholder, onSelect, onRemove }) => {
  const defaultSelect = placeholder ? null : 0;
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelect);

  const toggleOptions = (e) => {
    if (e.target.className?.baseVal === "remove") return;
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleSelect = (newIndex) => {
    setSelectedOption(newIndex);
    setIsOptionsOpen(false);
    onSelect(newIndex);
  };

  const handleRemove = () => {
    setSelectedOption(null);
    setIsOptionsOpen(false);
    if (onRemove) onRemove();
  };

  // Event handler for keydowns
  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        handleSelect(index);
        break;
      default:
        break;
    }
  };

  return (
    <div className="select_wrapper">
      <div className="select_container">
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          className={`${isOptionsOpen ? "expanded" : ""} ${
            selectedOption === null ? "placeholder" : ""
          }`}
          onClick={toggleOptions}
          onKeyDown={(e) => {
            if (e.key === "Delete") handleRemove();
          }}
        >
          {selectedOption === null ? placeholder : options[selectedOption]}
          <div className="select_icons">
            {selectedOption !== null && (
              <div
                role="button"
                tabIndex={0}
                className="remove"
                onClick={() => handleRemove()}
                onKeyDown={(e) => {
                  if (e.key === "Delete") handleRemove();
                }}
              >
                <Close width={10} className="remove" />
              </div>
            )}
            {isOptionsOpen ? <ArrowUp /> : <ArrowDown />}
          </div>
        </button>
        <ul
          className={`options ${isOptionsOpen ? "show" : ""}`}
          role="listbox"
          aria-activedescendant={options[selectedOption]}
          tabIndex={-1}
        >
          <div className="options_inner">
            {options.map((option, index) => (
              <li
                key={index}
                tabIndex={0}
                id={option}
                role="option"
                aria-selected={selectedOption === index}
                onKeyDown={handleKeyDown(index)}
                onClick={() => handleSelect(index)}
              >
                {option}
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Select;
