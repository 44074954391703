import React from "react";
import "./styles.scss";

const ImageBox = ({ url, label = "" }) => {
  return (
    <div className="imagebox">
      <img src={url} alt={label} className="imagebox__image" />
      {label.length > 0 ? <span>{label}</span> : null}
    </div>
  );
};

export default ImageBox;
