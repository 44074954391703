import React from "react";
import useFormValidation from "../../useFormValidation";
import "./style.scss";

export default function Textarea({
  field,
  active,
  error,
  register,
  alertStatus,
}) {
  const properties = field.properties;
  const validation = useFormValidation(active, properties);

  return (
    <div
      className={`
        textarea-input 
        ${error?.message && "show-error"}
        ${alertStatus && error === undefined && "show-correct"}
      `}
    >
      <label htmlFor="visie">{properties.label?.nl}</label>
      <textarea
        name={field.id + ""}
        rows="6"
        cols="20"
        placeholder={properties.placeholder?.nl}
        {...register(field.id + "", validation)}
      ></textarea>
      <span className="form-error type-textarea">{error?.message}</span>
    </div>
  );
}
