import React from "react";
import range from "lodash/range";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow_right_white.svg";

import "./styles.scss";

const Pagination = ({ total, page, setPage }) => {
  const pages = range(1, total + 1);

  const handlePrevious = () => {
    const previous = page - 1;
    setPage(previous);
  };

  const handleNext = () => {
    const next = page + 1;
    setPage(next);
  };

  const handlePage = (page) => {
    setPage(page);
  };

  if (!total) return null;
  return (
    <div className="pagination__container">
      <button
        className="prev"
        disabled={page === 1}
        onClick={() => handlePrevious()}
      >
        <ArrowRight width={20} />
      </button>
      {pages.map((number) => (
        <button
          key={number}
          className={`page ${page === number ? "active" : ""}`}
          onClick={() => handlePage(number)}
        >
          {number}
        </button>
      ))}
      <button
        className="next"
        disabled={page === total}
        onClick={() => handleNext()}
      >
        <ArrowRight width={20} />
      </button>
    </div>
  );
};

export default Pagination;
