import React from "react";
import { NavLink } from "react-router-i18n";
import { DateTime } from "luxon";
import I18n from "../../i18n";
import { getApiPrefix } from "../../utils";
import Image from "../Image";
import ReactMarkdown from "react-markdown";

import "./styles.scss";

const BlogCard = ({ blog, blogsPath }) => {
  const api = getApiPrefix();

  return (
    <NavLink to={`/${blogsPath}/${blog.slug}`} className="blogcard__wrapper">
      <div className="blogcard">
        <div className="blogcard__image">
          <Image src={api + blog.image?.url} alt="" />
        </div>
        <div className="blogcard__content">
          <div className="blogcard__content_meta">
            <div>{blog.author}</div>
            <div>
              {DateTime.fromISO(blog.created_at).toFormat("dd LLL yyyy")}
            </div>
          </div>
          <h3 className="blogcard__content_title">{blog.title}</h3>
          <ReactMarkdown>{blog.introduction}</ReactMarkdown>
          <div className="blogcard__content_footer">
            <div className="arrow__link">
              <I18n t="readmore" />
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default BlogCard;
