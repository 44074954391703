import I18n from "../i18n";
import { createGlobalState } from "react-hooks-global-state";

const initialGlobalState = {
  percentage: 0,
  formSubmitted: false,
};
export const { useGlobalState, getGlobalState } =
  createGlobalState(initialGlobalState);

export const percentageText = (percentage, formSubmitted) => {
  if (percentage < 100) {
    return percentage + "%";
  }

  if (!formSubmitted) {
    return "95%";
  }

  if (formSubmitted) {
    return <I18n t="forms.submittedPercentage" />;
  }
};
