import React from "react";
import { DateTime } from "luxon";
import { currentLocale } from "../../../../utils";

export default function Date(props) {
  const properties = props.field.properties;

  const dateString = () => {
    return DateTime.fromISO(props.value)
      .setZone("Europe/Amsterdam")
      .setLocale(currentLocale())
      .toLocaleString({ month: "long", day: "numeric", year: "numeric" });
  };

  return (
    <div className="item-text">
      <h3>{properties.label.nl}</h3>
      <p>{dateString()}</p>
    </div>
  );
}
