import React from "react";
import BaseLayout from "../../components/baselayout";
import { useStaleSWR } from "../../utils";
import Loading from "../../components/loading";
import Error from "../../components/error";
import ContentItem from "../../components/contentitem";
import { DateTime } from "luxon";
import I18n from "../../i18n";
import Image from "../../components/Image";
import { getApiPrefix } from "../../utils";

import "./styles.scss";
import NotFound from "../notfound";

const Blog = ({ location, match }) => {
  const { data: blogs } = useStaleSWR("/blog-overview");
  const { data, error: blogError } = useStaleSWR(
    `/blogs?slug=${match.params.slug}`
  );

  const blog = !!data && data[0];
  const blogsPath = I18n.getTranslation(location, "blogs.path");
  const api = getApiPrefix();

  if (blogError) return <Error message={blogError.message} />;
  if (!data) return <Loading />;
  if (data && !blog) return <NotFound />;
  return (
    <BaseLayout
      type="blog"
      breadcrumbs={[
        {
          title: blogs?.title ?? "",
          path: `/${blogsPath}`,
        },
        {
          title: blog?.title ?? "",
        },
      ]}
    >
      <Image
        src={api + blog.image?.url}
        wrapper={(image) => <div className="blog_cover">{image}</div>}
        fallback={<div className="blog_cover_fallback" />}
      />
      <div className="container blog_header">
        <div className="blog_meta">
          <div>
            {blogs.authorLabel} {blog.author}
          </div>
          <div>
            {blogs.createdAtLabel}{" "}
            {DateTime.fromISO(blog.created_at).toFormat("dd LLL yyyy")}
          </div>
        </div>
        <h1 className="blog_title">{blog?.title}</h1>
      </div>
      <div className="container blog_text">
        {blog?.content.map((block) => {
          return (
            <ContentItem
              key={block.id}
              content={block}
              partners={[]}
              events={[]}
            />
          );
        })}
      </div>
    </BaseLayout>
  );
};

export default Blog;
