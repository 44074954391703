import React from "react";
import QnAQuestionBlock from "../../components/qnaquestionblock";
import { useStaleSWR } from "../../utils";
import { useParams } from "react-router-dom";
import Loading from "../../components/loading";
import Error from "../../components/error";

export default function QnaQuestion() {
  const { cat } = useParams();
  const { data: category, error } = useStaleSWR(
    `/categories/published/${cat}`,
    false,
    false
  );

  if (!cat) {
    return <> </>;
  }

  if (!category) {
    return <Loading />;
  }

  if (error) {
    return <Error block message={error.message} />;
  }

  return (
    <>
      <h1>
        {category.categoryCode}. {category.label}
        <br />
        &nbsp;
      </h1>
      {category.questions.map((question) => {
        return (
          <QnAQuestionBlock
            key={question.id}
            question={question}
            categoryCode={category.categoryCode}
          />
        );
      })}
    </>
  );
}
