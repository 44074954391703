import React from "react";

export default function Header(props) {
  return (
    <div className="header-timeline-signin">
      {props.field.properties.subtitle ? (
        <h3> {props.field.properties.label.nl} </h3>
      ) : (
        <h1> {props.field.properties.label.nl} </h1>
      )}
    </div>
  );
}
