import React from "react";
import { DateTime } from "luxon";
import ReactMarkdown from "react-markdown";
import { getApiPrefix, currentLocale } from "../../utils";
import FileIcon from "../../components/fileicon";
import "./styles.scss";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import I18n from "../../i18n";

const TimeLine = ({ kop_nl, introductie_nl, steps, location }) => {
  const timezone = "Europe/Amsterdam";
  const [activeIndex, setIndex] = React.useState([]);
  const currentDate = DateTime.now().setZone(timezone);
  let nextIsCurrentTrigger = false;

  const omName = () => {
    return I18n.getTranslation(location, "timeAt");
  };

  const onChange = (ids) => {
    setIndex(ids);
  };

  const shiftTime = (time) => {
    const newTime = DateTime.fromISO(time).setZone(timezone);
    if (newTime == null || !newTime.isValid) return false;
    return newTime.toFormat("H:mm");
  };

  return (
    <>
      <div className="timeline__title">
        <h1 className="kop">{kop_nl}</h1>
        <ReactMarkdown>{introductie_nl}</ReactMarkdown>
      </div>

      <Accordion
        allowMultipleExpanded={false}
        allowZeroExpanded
        onChange={onChange}
      >
        <div className="timeline">
          {steps.map((event, i) => {
            let dateString = "";
            let itemClass = "timeline__item";
            let startDate = false;
            let startTime = false;
            let endDate = false;
            let endTime = false;
            let sameDay = false;

            if (event.nodate === false) {
              // We need to display some date or time
              startDate = DateTime.fromISO(event.startdate).setZone(timezone);
              startTime = shiftTime(event.startdate);
              if (event.Meerdaags) {
                // We need to display multiple dates or a timeslot
                endDate = DateTime.fromISO(event.enddate).setZone(timezone);
                endTime = shiftTime(event.enddate);
                sameDay = startDate.hasSame(endDate, "day");
              }

              if (event.Meerdaags && sameDay) {
                // Show timeslot on a day
                dateString = startDate
                  .setLocale(currentLocale())
                  .toLocaleString(DateTime.DATE_FULL);
                dateString = `${dateString} (van ${startTime} tot ${endTime})`;

                itemClass +=
                  endDate.diff(currentDate, "days") < 0 ? " past" : "";
              } else if (event.Meerdaags && !sameDay) {
                // Show multiple dates
                const startDateString = startDate
                  .setLocale(currentLocale())
                  .toLocaleString(DateTime.DATE_FULL);

                const endDateString = endDate
                  .setLocale(currentLocale())
                  .toLocaleString(DateTime.DATE_FULL);

                dateString = `${startDateString} - ${endDateString}`;
                itemClass = "timeline__item_range";

                itemClass +=
                  endDate.diff(currentDate, "days") < 0 ? " past" : "";
              } else {
                // Show single day
                dateString = startDate
                  .setLocale(currentLocale())
                  .toLocaleString(DateTime.DATE_FULL);

                if (startTime !== false && startTime !== "0:00") {
                  // Also show time on the day
                  dateString += ` - ${omName()} ${startTime}`;
                }

                itemClass +=
                  startDate.diff(currentDate, "days") < 0 ? " past" : "";
              }

              if (
                startDate.diff(currentDate, "days") >= 0 &&
                !nextIsCurrentTrigger
              ) {
                itemClass += " current";
                nextIsCurrentTrigger = true;
              }

              if (event.Deadline) {
                itemClass += " deadline";
              }
            }

            if (activeIndex.includes(i + "-open")) {
              itemClass += " open";
            }

            return (
              <AccordionItem uuid={i + "-open"} className={itemClass} key={i}>
                <AccordionItemHeading>
                  <AccordionItemButton className="timeline__item_button">
                    <div className="timeline__item_bullet">{i + 1}</div>
                    <div
                      className={`
                        timeline__item_title 
                        ${dateString === "" && "on_line_title"} `}
                    >
                      {dateString !== "" && <span>{dateString}</span>}
                      <h3>{event.name_nl}</h3>
                      {event.enddate && event.subtitle && (
                        <div className="timeline_item_subtitle">
                          {event.subtitle}
                        </div>
                      )}
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel className="timeline__item_content">
                  <ReactMarkdown source={event.details_nl} />

                  {event.files_nl.map((file, index) => {
                    return (
                      <a
                        href={`${getApiPrefix()}/${file.url}`}
                        className="file"
                        key={index}
                      >
                        <div className="sign__and__text">
                          <FileIcon fileName={file.name} />
                          <p>{file.name}</p>
                        </div>
                        <img src="/icons/download.svg" alt="Download" />
                      </a>
                    );
                  })}
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </div>
      </Accordion>
    </>
  );
};

export default TimeLine;
