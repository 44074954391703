import React from "react";
import { NavLink } from "react-router-i18n";
import BaseLayout from "../../components/baselayout";
import Error from "../../components/error";
import ProjectCard from "../../components/projectcard";
import Loading from "../../components/loading";
import AccountSidebar from "../../components/AccountSidebar";

import { makeSiteBossURL, useStaleSWR } from "../../utils";
import AccountModal from "../../components/AccountModal";
import I18n from "../../i18n";
import Divider from "../../components/divider";

export default function AccountDashboard({ location }) {
  const { data: lokets, error: loketsError } = useStaleSWR("/lokets/");
  const { data: projects, error: formsError } = useStaleSWR("/projects/");

  const { data: applications, error: applicationsError } = useStaleSWR(
    makeSiteBossURL("/user/company-profile/applications/"),
    true
  );

  const loggedIn =
    localStorage.getItem("siteboss_client_token") !== null &&
    localStorage.getItem("siteboss_client_token") !== "";

  const scrollTo = location.state?.scrollTo;
  React.useEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (!!element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollTo]);

  if (formsError) {
    return <Error message={formsError.message} />;
  }
  if (loketsError) {
    return <Error message={loketsError.message} />;
  }

  if (loggedIn && applicationsError) {
    return <Error message={applicationsError.message} />;
  }

  if (loggedIn && (!projects || !applications)) {
    return <Loading />;
  }

  const progressBarPercentage = (data) => {
    let jsonData = data.data;
    let percentage = jsonData["siteboss-progress"];

    return percentage;
  };

  let actualList = [];

  if (loggedIn) {
    applications.projects.forEach((application) => {
      projects.forEach((project) => {
        // strapi_id is weird here, but ok
        const formDataProjectId = parseInt(application.strapi_id);
        const projectId =
          project.localizations[0]?.locale === "nl-NL"
            ? project.localizations[0].id
            : project.id;

        if (formDataProjectId === parseInt(projectId)) {
          let newFormData = { ...project, loket: false };
          newFormData.userData = application;
          actualList.push(newFormData);
        }
      });
    });

    applications.loketten.forEach((application) => {
      lokets.forEach((loket) => {
        const formDataLoketId = parseInt(application.strapi_id);
        const loketId =
          loket.localizations[0]?.locale === "nl-NL"
            ? loket.localizations[0].id
            : loket.id;

        if (formDataLoketId === parseInt(loketId)) {
          let newFormData = { ...loket, loket: true };
          newFormData.userData = application;
          actualList.push(newFormData);
        }
      });
    });
  }

  return (
    <BaseLayout
      type="project"
      breadcrumbs={[{ title: <I18n t="yourProfile" /> }]}
      headerContent={
        <>
          <h2>
            <I18n t="yourApplications" />
          </h2>
          <p>
            <I18n t="account.applications.status" />
          </p>
        </>
      }
    >
      {loggedIn ? (
        <div className="account-content">
          <div className="project_container" id="project_container">
            <div id="contentTop" />
            <AccountSidebar />

            {actualList.length === 0 && (
              <div className="col2">
                <h3>
                  <I18n t="account.noProjects" />
                </h3>
                <p>
                  <I18n t="account.noProjectsText" />
                </p>
                <p>
                  <NavLink
                    to={"/" + I18n.getTranslation(location, "participatePath")}
                  >
                    <I18n t="account.noProjectsLook" />
                  </NavLink>
                </p>
                <Divider />
                <h3>
                  <I18n t="account.companyProfile" />
                </h3>
                <p>
                  <I18n t="account.companyProfileRequired" />
                </p>
                <p>
                  <NavLink to={"/account/company"}>
                    <I18n t="account.companyProfile" />
                  </NavLink>
                </p>
              </div>
            )}

            <div className="gridrow__small dashboard__projects">
              {actualList.map((subject) => (
                <ProjectCard
                  id={subject.id}
                  title={subject.Titel}
                  thumbnail={
                    subject.thumbnail?.formats == null
                      ? ""
                      : subject.thumbnail.formats.small.url
                  }
                  text={subject.summary}
                  deadline={subject.deadline}
                  deadline_text={subject.deadline_text}
                  url={`/${subject.loket ? "loket" : "project"}/${
                    subject.slug
                  }/apply`}
                  key={subject.slug}
                  thema={subject.thema}
                  partner={subject.partner}
                  progress={progressBarPercentage(subject.userData)}
                  formSubmitted={subject.userData.submitted}
                  overlay={subject.Overlaypng}
                  showApplicationButton
                  loket={!!subject.loket}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <AccountModal />
      )}
    </BaseLayout>
  );
}
