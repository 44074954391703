import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-i18n";

import "./styles.scss";
import ApplyButton from "../../components/applybutton";

import { percentageText, useGlobalState } from "../../utils";

const StickyHeader = ({
  name,
  stickyHeaderText,
  noForm,
  activeForm,
  applyLink,
  children,
}) => {
  const [header, setHeader] = useState(false);

  useEffect(() => {
    const getOffsetOfHtmlElement = (el) => {
      const rect = el.getBoundingClientRect();
      return rect.top + window.pageYOffset;
    };
    const el = document.getElementById("project_container");

    const showHeader = () => {
      if (el !== null) {
        setHeader(window.pageYOffset > getOffsetOfHtmlElement(el));
      }
    };

    window.addEventListener("scroll", showHeader);

    return () => {
      window.removeEventListener("scroll", showHeader);
    };
  }, []);

  const progressBarPercentage = useGlobalState("percentage")[0];
  const formSubmitted = useGlobalState("formSubmitted")[0];

  return (
    <div id="#stickyContainer" className="fullpage">
      <div className={`header_sticky ${header ? "" : "header-hidden"}`}>
        <div className="container">
          <div className="header_container">
            <div>
              <a href="/">
                <img
                  src="/icons/innovatiepartners_logo_icon.svg"
                  alt="logo-icon"
                ></img>
              </a>
              <NavLink to="/" className="sticky_breadcrumbs">
                Home
              </NavLink>
            </div>
            <div className="sticky-middle-content">
              <p>{name}</p>
            </div>
            <div className="sticky-right-content">
              <div className="text-right-side">{stickyHeaderText}</div>
              <div className="percentage">
                {activeForm && (
                  <p>{percentageText(progressBarPercentage, formSubmitted)}</p>
                )}
                {!activeForm && !noForm && <ApplyButton link={applyLink} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="top" />
      {children}
    </div>
  );
};

export default StickyHeader;
