import React from "react";
import BaseLayout from "../../components/baselayout";
import { useStaleSWR } from "../../utils";
import BlogCard from "../../components/BlogCard";
import Select from "../../components/Select";
import Pagination from "../../components/Pagination";
import I18n from "../../i18n";

import "./styles.scss";
import Loader from "../../components/Loader";

const Blogs = ({ location }) => {
  const pageLimit = 6;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentThemaId, setCurrentThemaId] = React.useState("");
  const { data: page, error: pageError } = useStaleSWR("/blog-overview");
  const { data: themas } = useStaleSWR("/blog-themas", false, false);

  const themeFilter = currentThemaId ? `&project.thema=${currentThemaId}` : "";
  const start = currentPage === 1 ? 0 : (currentPage - 1) * pageLimit;

  const startFilter = !!start
    ? `&_limit=${pageLimit}&_start=${start}`
    : `&_limit=${pageLimit}`;

  const { data: blogsCount } = useStaleSWR(`/blogs/count?${themeFilter}`);
  const { data: blogs, error: blogsError } = useStaleSWR(
    `/blogs?_sort=created_at:DESC${themeFilter}${startFilter}`
  );

  const blogsPath = I18n.getTranslation(location, "blogs.path");

  return (
    <BaseLayout
      type="blogs"
      headerContent={
        <div className="blogs__header">
          <div>
            <h1>{page?.title}</h1>
            <p>{page?.introduction}</p>
          </div>
          {themas && (
            <div className="blogs__theme_selector">
              <div>{page.selectThemeLabel}</div>
              <div>
                <Select
                  placeholder={page.selectThemePlaceholder}
                  options={themas.map((thema) => thema.label)}
                  onSelect={(index) => {
                    setCurrentThemaId(themas[index]?.id);
                    setCurrentPage(1);
                  }}
                  onRemove={() => {
                    setCurrentThemaId("");
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      }
      breadcrumbs={[
        {
          title: page?.title ?? "",
        },
      ]}
    >
      <div className="blogs__page_content">
        {pageError && (
          <div className="blogs__no_results">
            <h3>
              <I18n t="error.loadingPage" />
            </h3>
            <p>{pageError.message}</p>
          </div>
        )}
        {blogsError && (
          <div className="blogs__no_results">
            <h3>
              <I18n t="error.loading" />
            </h3>
            <p>{blogsError.message}</p>
          </div>
        )}
        {!blogs && !pageError && !blogsError && (
          <Loader className="blogs__no_results" />
        )}
        {currentThemaId && !!blogs && !blogs.length && (
          <div className="blogs__no_results">
            <h3>{page.selectThemeNoResults}</h3>
          </div>
        )}
        <div className="cards__grid">
          {!!blogs &&
            blogs.map((blog) => (
              <BlogCard key={blog.id} blogsPath={blogsPath} blog={blog} />
            ))}
        </div>
        {blogs && (
          <div className="blogs__pagination">
            <Pagination
              total={Math.ceil(blogsCount / pageLimit)}
              page={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default Blogs;
