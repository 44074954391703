import React, { useState, useEffect, useRef, useCallback } from "react";
import { ReactComponent as Close } from "../../assets/icons/close-purple.svg";

import "./styles.scss";

const Modal = ({ children, open, onClose, trigger, ...props }) => {
  const ref = useRef();
  const [showModal, setShowModal] = useState(open);

  const closeModal = useCallback(
    (e) => {
      // reset add-to-calendar-hoc
      if (props.onRequestClose) props.onRequestClose(e);
      setShowModal(false);
      if (onClose) onClose(e);
    },
    [props, onClose]
  );

  const closeModalKey = (e) => {
    if (e.keyCode === 27) {
      closeModal(e);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;
      closeModal(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, closeModal]);

  useEffect(() => {
    setShowModal(open);
  }, [open]);

  return (
    <>
      {trigger && (
        <button
          className="button__cta"
          onClick={() => setShowModal(!open)}
          disabled={open}
        >
          {trigger}
        </button>
      )}
      <div className={`modal-wrapper ${showModal ? "show-modal" : ""}`}>
        <div className="modal-container" ref={ref}>
          <div className="about-content small-width">
            <button
              className="modal-close"
              onClick={closeModal}
              onKeyDown={closeModalKey}
            >
              <Close />
            </button>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
