import { createI18n } from "react-router-i18n";
import en from "./en.js";
import nl from "./nl.js";

// The first in the array is treated as the default locale
const locales = ["nl", "en"];

const translations = {
  nl: nl,
  en: en,
};

const I18n = createI18n(locales, translations);

export default I18n;
